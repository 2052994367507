<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title">{{ $i18n('LABEL_MAINTENANCE_BREAK') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div id="modalBody" class="modal-body">
            
        <Text id="TEXT_MAINTENANCE_BREAK_START" :replaces="maintenanceStartReplaces" class="mb-3" />
        <Text v-if="maintenanceMode.end" id="TEXT_MAINTENANCE_BREAK_END" :replaces="maintenanceEndReplaces" class="mb-3" />

    </div> <!-- /.modal-body -->
    
    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-primary">{{ $i18n('LABEL_CLOSE') }}</button>
    </div>

</template>

<script>
import store from '../store/store.js';
import Text from '../components/Text.vue';

export default {
    name: 'MaintenanceModeModal',
    components: {
        Text
    },
    props: {
        maintenanceMode: Object
    },
    computed: {
        maintenanceStartReplaces() {
            return {'[MAINTENANCE_BREAK_START]': this.$formatDateTime(this.maintenanceMode.start)};
        },
        maintenanceEndReplaces() {
            return {'[MAINTENANCE_BREAK_END]': this.$formatDateTime(this.maintenanceMode.end)};
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        cancel() {
            store.commit('setModal', null);
        }
    }
}
</script>

<style scoped>

</style>

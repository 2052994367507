<template>

    <div class="position-relative bg-white p-3 my-3 shadow-sm">
        
        <OverlaySpinner 
            v-if="$loading()"
            :showStatusText="true" 
            :statusText="$loadingStatus()"
        />

        <h1>{{ $i18n('LABEL_LINKED_SITES') }}</h1>

        <Text id="HTML_LINKED_SITES" />

        <Alerts />

        <form 
            action="api/site" 
            method="post" 
            class="row needs-validation" 
            :class="{'was-validated': wasValidated}"
            @submit="postAddSite($event)" 
            novalidate>

            <div class="col-lg-6 my-3">
                <label for="newSiteUrl" class="form-label">{{ $i18n('LABEL_ADD_LINKED_SITE') }}</label>
                <div class="input-group has-validation">
                    <input v-model="newSiteUrl" type="url" id="newSiteUrl" name="newSiteUrl" class="form-control" placeholder="https://your.site.url" required />
                    <input type="hidden" name="workspaceId" :value="activeWorkspaceId" />
                    <button type="submit" class="btn btn-primary" :disabled="addNewSiteBtnDisabled">{{ $i18n('LABEL_ADD') }}</button>
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_TYPE_VALID_URL') }}
                    </div>
                </div>
            </div>

        </form>

        <table v-if="sites.length" class="table align-middle my-4">
            <thead class="bg-dark text-white">
                <tr>
                    <th scope="col">{{ $i18n('LABEL_SITE_URL') }}</th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_DELETE') }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="site in sites"
                    :key="site.id">
                    <td class="expand">{{ site.url }}</td>
                    <td class="text-end shrink">
                        <DeleteConfirmButton deleteEventName ="deleteSite" :deleteEventPayload="site.id" />
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import Alerts from '../components/Alerts.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import Text from '../components/Text.vue';

export default {
    name: 'Sites',
    components: {
        Alerts,
        DeleteConfirmButton,
        OverlaySpinner,
        Text
    },
    data() {
        return {
            newSiteUrl: '',
            wasValidated: false,
            sites: []
        }
    },
    computed: {
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        addNewSiteBtnDisabled() {
            return ! this.newSiteUrl;
        }
    },
    watch: {
        activeWorkspaceId() {
            this.getSites();
        }
    },
    created() {
        store.commit('setLoading', true);

        this.$emitter.on('deleteSite', (id) => {
            this.deleteSite(id);
        });

        this.getSites();
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('deleteSite');
    },
    methods: {
        /**
         * Gets all user's sites from the db and sets them to this.sites.
         */
        getSites() {
            axios.get('api/sites', {
                params: {
                    workspaceId: this.activeWorkspaceId
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setMessages', response.data.messages);
                    }
                    if (response.data.sites.length) {
                        this.sites = response.data.sites;
                    } else {
                        this.sites = [];
                        store.commit('addMessage', 'You don\'t have any linked sites yet.');
                    }
                } else if (response.data.errors) {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Validates and posts add new site form.
         */
        postAddSite(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Adding new site');
            store.commit('clearErrors');
            store.commit('clearMessages');
            
            let formData = new FormData();

            formData.append('workspaceId', this.activeWorkspaceId);
            formData.append('url', this.newSiteUrl);

            axios({
                method: 'post',
                url: '/api/site',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getSites();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
            });
        },
        /**
         * Deletes a site from the DB permanently.
         */
        deleteSite(siteId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting site');

            axios({
                method: 'delete',
                url: '/api/site/' + siteId,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getSites();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="spinner-overlay d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ statusText }}</span>
        </div>
        <div class="spinner-text" :class="{'visually-hidden': !showStatusText }">{{ statusText }}</div>
    </div>
</template>

<script>
export default {
    name: 'OverlaySpinner',
    props: {
        showStatusText: {
            type: Boolean,
            default: false
        },
        statusText: {
            type: String,
            default: 'Loading'
        }
    }
}
</script>

<style scoped>
.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .75);
    z-index: 9999;
}
.spinner-border {
    min-width: 2rem;
    min-height: 2rem;
}
.spinner-text {
    font-size: .75rem;
    margin-top: .5rem;
}
</style>

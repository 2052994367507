<template>

    <form 
        v-if="showForm"
        id="registrationForm"
        action="api/registration" 
        method="post" 
        class="needs-validation mb-3" 
        :class="{'was-validated': wasValidated}"
        @submit="postRegistration($event)" 
        novalidate>

        <p>{{ $i18n('TEXT_SIGN_UP_FOR_FREE') }}</p>

        <div class="mb-3">
            <label for="firstName" class="form-label visually-hidden">{{ $i18n('LABEL_FIRST_NAME') }}</label>
            <input v-model="firstName" type="text" id="firstName" name="firstName" class="form-control" :placeholder="$i18n('LABEL_FIRST_NAME')" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_FIELD_REQUIRED') }}
            </div>
        </div>

        <div class="mb-3">
            <label for="lastName" class="form-label visually-hidden">{{ $i18n('LABEL_LAST_NAME') }}</label>
            <input v-model="lastName" type="text" id="lastName" name="lastName" class="form-control" :placeholder="$i18n('LABEL_LAST_NAME')" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_FIELD_REQUIRED') }}
            </div>
        </div>

        <div class="mb-3">
            <label for="organization" class="form-label visually-hidden">{{ $i18n('LABEL_COMPANY_OR_ORGANIZATION') }}</label>
            <input v-model="organization" type="text" id="organization" name="organization" class="form-control" :placeholder="$i18n('LABEL_COMPANY_OR_ORGANIZATION')" />
        </div>

        <div class="mb-3">
            <label for="email" class="form-label visually-hidden">{{ $i18n('LABEL_EMAIL') }}</label>
            <input v-model="email" type="email" id="email" name="email" class="form-control" :placeholder="$i18n('LABEL_EMAIL')" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_TYPE_VALID_EMAIL') }}
            </div>
        </div>

        <div class="mb-3">
            <label for="password" class="form-label visually-hidden">{{ $i18n('LABEL_PASSWORD') }}</label>
            <input v-model="password" type="password" id="password" name="password" class="form-control" minlength="10" autocomplete="off" :placeholder="$i18n('LABEL_PASSWORD')" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_FIELD_REQUIRED') }} ({{ $i18n('LABEL_MIN_LENGTH_10') }})
            </div>
        </div>

        <div class="mb-3">
            <label for="passwordAgain" class="form-label visually-hidden">{{ $i18n('LABEL_PASSWORD_AGAIN') }}</label>
            <input v-model="passwordAgain" type="password" id="passwordAgain" name="passwordAgain" class="form-control" autocomplete="off" :placeholder="$i18n('LABEL_PASSWORD_AGAIN')" :pattern="password" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_FIELD_REQUIRED') }} ({{ $i18n('LABEL_MUST_MATCH_PASSWORD') }})
            </div>
        </div>

        <div class="form-check mb-3">
            <input type="checkbox" id="serviceTerms" class="form-check-input" required>
            <label for="serviceTerms" class="form-check-label">
                {{ $i18n('LABEL_I_HAVE_READ') }} <a href="#" @click="$emitter.emit('openHelpText', 'HTML_TERMS_AND_CONDITIONS')">{{ $i18n('LABEL_TERMS_AND_CONDITIONS') }}</a>
            </label>
        </div>

        <div class="d-grid gap-2 text-center">
            <button type="submit" class="btn btn-primary">{{ $i18n('LABEL_SIGN_UP') }}</button>
        </div>

        <ul class="list-unstyled mt-3">
            <li>{{ $i18n('LABEL_GOT_AN_ACCOUNT_ALREADY') }} <router-link to="/" >{{ $i18n('LABEL_LOG_IN_HERE') }}</router-link></li>
        </ul>

    </form>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'RegistrationForm',
    components: {

    },
    data() {
        return {
            showForm: true,
            wasValidated: false,
            firstName: null,
            lastName: null,
            organization: null,
            email: null,
            password: null,
            passwordAgain: null
        }
    },
    methods: {
        /**
         * @param   {object}    e   Native event object. 
         */
        postRegistration(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Signing up');
            store.commit('clearErrors');
            store.commit('clearMessages');

            let formData = new FormData(document.getElementById('registrationForm'));

            axios({
                method: 'post',
                url: '/api/registration',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') 
                {
                    if (response.data.messages) 
                    {
                        store.commit('setMessages', response.data.messages);
                    }
                    // Hide form
                    this.showForm = false;
                } 
                else if (response.data.errors) 
                {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_CONFIRM_DELETE') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <Text id="TEXT_EMPTY_CART_CONFIRM" />

    </div> <!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="emptyCart()"  class="btn btn-danger">{{ $i18n('LABEL_EMPTY_CART') }}</button>
    </div>

</template>

<script>
import store from '../store/store.js';
import Text from '../components/Text.vue';

export default {
    name: 'ShopCartEmptyConfirm',
    components: {
        Text
    },
    data() {
        return {
            
        }
    },
    methods: {
        cancel() {
            store.commit('setModal', null);
        },
        emptyCart() {
            store.commit('emptyCart');
            store.commit('setModal', null);
            this.$addToastMessage(this.$i18n('TEXT_CART_EMPTIED'));
        }
    }
}
</script>

<style scoped>

</style>

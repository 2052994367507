<template>

    <ul 
        v-if="categories && categories.length"
        class="list-group categories-list"
        aria-role="listbox">
        <li 
            v-for="(category, index) in categories" 
            @click="selectItem(index)"
            :key="'item-'+index"
            :class="{selected: isSelected(category.id)}"
            class="list-group-item categories-list-item">
            <img v-if="category.icon" :src="category.icon" class="list-category-img" :alt="category.name" />
            {{ category.name }}
        </li>
    </ul>

</template>

<script>
export default {
    name: 'CategorySelect',
    props: {
        categories: {
            type: Array,
            default: function() {
                return [];
            }
        },
        selectedCategories: {
            type: Array,
            default: function() {
                return [];
            }
        },
        placeholder: String
    },
    data() {
        return {

        }
    },
    methods: {
        /**
         * Checks if a category is selected already.
         * 
         * @param   {number}    categoryId
         * @returns {boolean}
         */
        isSelected(categoryId) {
            return this.selectedCategories.find(category => category.id == categoryId);
        },
        selectItem(index) {
            // Already selected, do nothing
            if (this.isSelected(this.categories[index].id)) {
                return;
            }
            // Emit addCategory event
            this.$emitter.emit('addCategory', this.categories[index].id);
        }
    }
}
</script>

<style scoped>
.categories-list {
    height: 300px;
    padding: 0;
    margin: 0;
    overflow: auto;
}
.categories-list-item {
    font-size: .85rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}
.categories-list-item:hover {
    background-color: var(--bs-primary);
}
.categories-list-item.selected,
.categories-list-item.selected:hover {
    background-color: #fff;
    color: #999;
    cursor: not-allowed;
}
.categories-list-item.selected img,
.categories-list-item.selected:hover img {
    opacity: .25;
}
</style>

<template>

    <div class="row flex-grow-1">
        <div class="col">

            <label v-if="categories && categories.length" class="form-label">{{ $i18n('LABEL_SELECTED_CATEGORIES') }}</label>
            
            <draggable 
                :list="categories" 
                class="draggable draggable-categories list-group">
                <div
                    class="list-group-item bg-light d-flex align-items-center"
                    v-for="(category, index) in categories"
                    :key="category.name">
                    <img v-if="category.icon" :src="category.icon" class="list-category-img" :alt="category.name" />
                    <div class="flex-grow-1">
                        {{ category.name }}
                    </div>

                    <DeleteConfirmButton deleteEventName ="removeCategory" :deleteEventPayload="category.id" />

                    <input type="hidden" :id="'place-category-' + category.id" :name="'categories[' + index + ']'" :value="category.id" />
                </div>
            </draggable>
            
        </div>

        <div class="col position-relative flex-grow-1 d-flex flex-column">

            <OverlaySpinner v-if="! locationsDbCategories" />

            <label>{{ $i18n('LABEL_ADD_CATEGORY') }}</label>

            <div class="mb-2">
                <label for="categorySelect" class="form-label visually-hidden">{{ $i18n('LABEL_DEFAULT_CATEGORIES') }}</label>
                <select v-model="listedCategories" id="categorySelect" name="categorySelect" class="form-select">
                    <option value="locationsDbCategories">{{ $i18n('LABEL_DEFAULT_CATEGORIES') }}</option>
                    <option value="customCategories">{{ $i18n('LABEL_CUSTOM_CATEGORIES') }}</option>
                </select>
            </div>

            <CategorySelect  
                :categories="categorySelectCategories" 
                :selectedCategories="categories" 
                class="flex-grow-1" />

        </div>

    </div> <!-- /.row -->

</template>

<script>
import store from '../store/store.js';
import { VueDraggableNext } from 'vue-draggable-next';
import CategorySelect from '../components/CategorySelect.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'ItemEditCategories',
    components: {
        draggable: VueDraggableNext,
        CategorySelect,
        DeleteConfirmButton,
        OverlaySpinner
    },
    props: {
        categories: {
            type: Array,
            default: function() {
                return [];
            }
        },
        itemLan: String
    },
    data() {
        return {
            listedCategories: 'locationsDbCategories',
            confirmCategoryDelete: null
        }
    },
    computed: {
        locationsDbCategories() {
            return store.state.locationsDbCategories;
        },
        customCategories() {
            if (store.state.customCategories) {
                return store.state.customCategories.filter(item => item.lan == this.itemLan);
            }
            return null;
        },
        categorySelectCategories() {
            return this[this.listedCategories];
        }
    },
    created() {
        // Add category event emitted by CategorySelect
        this.$emitter.on('addCategory', (id) => {
            this.addCategory(id);
        });

        // Remove category event emitted by CategorySelect
        this.$emitter.on('removeCategory', (id) => {
            this.removeCategory(id);
        });

        // Make sure locationsDbCategories are loaded
        if (! this.locationsDbCategories) {
            this.$emitter.emit('getLocationsDbCategories');
        }

        // Make sure customCategories are loaded
        if (! this.customCategories) {
            this.$emitter.emit('getCustomCategories');
        }
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('addCategory');
        this.$emitter.all.delete('removeCategory');
    },
    methods: {
        /**
         * Checks if item has a category already.
         * 
         * @param   {number}    categoryId
         * @returns {boolean}
         */
        hasCategory(categoryId) {
            if (this.categories) {
                return this.categories.find(category => category.id == categoryId);
            }
            return false;
        },
        /**
         * Adds a category selected from Locations DB or Custom categories select menu.
         * 
         * @param   {event} e
         */
        addCategory(categoryId) {
            if (categoryId) {
                let cat = this.locationsDbCategories.find(category => category.id == categoryId);
                if (! cat) {
                    // Not found in locationsDbCategories, try customCategories
                    cat = this.customCategories.find(category => category.id == categoryId);
                }
                this.$emitter.emit('addItemCategory', cat);
            }
        },
        /**
         * @param   {number}    categoryId
         */
        removeCategory(categoryId) {
            this.$emitter.emit('removeItemCategory', categoryId);
        }
    }
}
</script>

<style scoped>

</style>

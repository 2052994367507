<template>

    <OverlaySpinner v-if="! ready" :showStatusText="true" />

    <header v-if="ready && !maintenanceModeStarted" class="d-flex justify-content-between align-items-center bg-dark">
        
        <button v-if="$user()" @click="toggleMenu()" type="button" class="menu-btn d-md-none">
            <BIconList /><span class="visually-hidden">{{ $i18n('LABEL_MENU') }}</span>
        </button>

        <img src="./assets/images/matkailukartta-white.svg" class="logo" alt="Matkailukartta" />

        <!-- SHOPPING CART DROPDOWN MENU -->
        <ShopCartMenu />

        <div class="d-flex align-items-center">
            <!-- MY ACCOUNT DROPDOWN MENU -->
            <div v-if="$user()" class="dropdown">
                <a id="myAccountMenuBtn" class="dropdown-toggle d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <WorkspaceIcon :image="activeWorkspace.image" :size="32" />
                    <small class="ms-1 d-none d-md-inline">{{ getWorkspaceName(activeWorkspace.name) }}</small>
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="myAccountMenuBtn">
                    <li v-for="workspace in userWorkspacesSorted" :key="workspace.id+'-'+workspace.name">
                        <button @click="changeWorkspace(workspace.id)" type="button" class="btn btn-link dropdown-item">
                            <WorkspaceIcon :image="workspace.image" />
                            {{ getWorkspaceName(workspace.name) }}
                        </button>
                    </li>
                    <li>
                        <button @click="openCreateNewWorkspace()" type="button" class="btn btn-link dropdown-item">
                            <WorkspaceIcon image="new" /> {{ $i18n('LABEL_CREATE_WORKSPACE') }}
                        </button>
                    </li>
                    <li><hr class="dropdown-divider"></li>
                    <li>
                        <router-link to="/my-account/account-info" class="dropdown-item">
                            <BIconPersonFill /> {{ $i18n('LABEL_MY_ACCOUNT') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/logout" class="dropdown-item">
                            <BIconPower /> {{ $i18n('LABEL_LOG_OUT') }}
                        </router-link>
                    </li>
                </ul>
            </div>

            <!-- LANGUAGE DROPDOWN MENU -->
            <div class="dropdown mx-3">
                <a class="dropdown-toggle" id="lanMenuBtn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ lan.toUpperCase() }}
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="lanMenuBtn">
                    <li>
                        <button @click="changeLan('fi')" class="dropdown-item">FI</button>
                        <button @click="changeLan('en')" class="dropdown-item">EN</button>
                    </li>
                </ul>
            </div>
        </div>

    </header>

    <aside v-if="ready && showNavPanel" :class="{open: menuOpen, condensed: menuCondensed}" class="nav-panel d-flex flex-column justify-content-between bg-dark text-white">
        <nav>
            
            <ul class="nav nav-level-1 flex-column">
                <li class="nav-item">
                    <router-link to="/dashboard" class="nav-link">
                        <BIconHouseFill /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_DASHBOARD') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/maps" class="nav-link">
                        <BIconGlobe2 /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_MAPS') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/places" class="nav-link">
                        <BIconGeoAltFill /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_PLACES') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/routes" class="nav-link">
                        <BIconSignpostFill /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_ROUTES') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/categories" class="nav-link">
                        <BIconDiagram3Fill /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_CATEGORIES') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/sites" class="nav-link">
                        <BIconLink45deg /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_LINKED_SITES') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/shop" class="nav-link">
                        <BIconCart4 /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_SHOP') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <button @click="toggleSubNav('/my-account/')" class="nav-link">
                        <BIconPersonFill />
                        <span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_MY_ACCOUNT') }}</span>
                        <BIconChevronRight v-if="!showSubnav('/my-account/')" :class="{'visually-hidden': menuCondensed}" />
                        <BIconChevronDown v-if="showSubnav('/my-account/')" :class="{'visually-hidden': menuCondensed}" />
                    </button>
                    <ul v-show="showSubnav('/my-account/')" class="nav nav-level-2 flex-column">
                        <router-link to="/my-account/account-info" class="nav-link">
                            {{ $i18n('LABEL_ACCOUNT_INFO') }}
                        </router-link>
                        <router-link to="/my-account/change-password" class="nav-link">
                            {{ $i18n('LABEL_CHANGE_PASSWORD') }}
                        </router-link>
                    </ul>
                </li>
                <li class="nav-item">
                    <button @click="toggleSubNav('/workspace/')" class="nav-link">
                        <BIconBuilding />
                        <span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_WORKSPACE_SETTINGS') }}</span>
                        <BIconChevronRight v-if="!showSubnav('/workspace/')" :class="{'visually-hidden': menuCondensed}" />
                        <BIconChevronDown v-if="showSubnav('/workspace/')" :class="{'visually-hidden': menuCondensed}" />
                    </button>
                    <ul v-show="showSubnav('/workspace/')" class="nav nav-level-2 flex-column">
                        <router-link to="/workspace/workspace-info" class="nav-link">
                            {{ $i18n('LABEL_WORKSPACE_INFO') }}
                        </router-link>
                        <router-link to="/workspace/billing-info" class="nav-link">
                            {{ $i18n('LABEL_BILLING_INFO') }}
                        </router-link>
                        <router-link to="/workspace/subscriptions" class="nav-link">
                            {{ $i18n('LABEL_SUBSCRIPTIONS') }}
                        </router-link>
                    </ul>
                </li>
                <li v-if="$isAdmin()" class="nav-item">
                    <button @click="toggleSubNav('/admin/')" class="nav-link">
                        <BIconGearFill />
                        <span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_ADMIN') }}</span>
                        <BIconChevronRight v-if="!showSubnav('/admin/')" :class="{'visually-hidden': menuCondensed}" />
                        <BIconChevronDown v-if="showSubnav('/admin/')" :class="{'visually-hidden': menuCondensed}" />
                    </button>
                    <ul v-show="showSubnav('/admin/')" class="nav nav-level-2 flex-column">
                        <li 
                        v-for="route in getRouteChildren('Admin')" 
                        :key="route.name">
                            <router-link  
                                :to="route.path"
                                class="nav-link">
                                {{ getRouteLabel(route) }}
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <router-link to="/help" class="nav-link">
                        <BIconQuestionCircle /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_HELP_AND_SUPPORT') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/logout" class="nav-link">
                        <BIconPower /><span :class="{'visually-hidden': menuCondensed}">{{ $i18n('LABEL_LOG_OUT') }}</span>
                    </router-link>
                </li>
            </ul>
        </nav>

        <button @click="toggleMenuCondensed($event)" type="button" class="menu-condense-btn text-start d-none d-md-block">
            <BIconChevronRight v-if="menuCondensed" />
            <BIconChevronLeft v-if="! menuCondensed" />
        </button>

    </aside>

    <main v-if="ready" :class="{'logged-in': $user(), 'menu-open': showNavPanel, 'menu-condensed': menuCondensed}">

        <div class="container-fluid main-container" :class="{'not-logged-in': ! $user()}">
            
            <div v-if="maintenanceModeStarted" class="alert alert-danger alert-maintenance-mode">
                <div class="mt-1">
                    <strong>{{ $i18n('LABEL_MAINTENANCE_BREAK') }}</strong>
                </div>
                <Text v-if="maintenanceMode.end" id="TEXT_MAINTENANCE_BREAK_END" :replaces="{'[MAINTENANCE_BREAK_END]': $formatDateTime(maintenanceMode.end)}" />
            </div>

            <router-view v-if="!maintenanceModeStarted" />
        </div>

        <!-- Modal -->
        <div class="modal fade" :class="{show: modal}" id="modal" tabindex="-1" aria-labelledby="modalLabel" :aria-hidden="!modal">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="modalSize">
                <div class="modal-content" :class="{'min-h-100': modal && modal.fullHeight}">
                    <OverlaySpinner 
                        v-if="$loading()"
                        :showStatusText="true" 
                        :statusText="$loadingStatus()"
                    />
                    <component :is="modalComponent" v-bind="modalComponentProps" />
                </div>
            </div>
        </div>

        <!-- Modal errors -->
        <div v-if="modalErrors.length" class="modal-errors d-flex flex-column justify-content-center align-items-center">
            <div 
                v-for="(errorMsg, index) in modalErrors" 
                :key="'modal-error-'+index" 
                class="alert alert-danger alert-dismissible mb-3"
            >
                {{ errorMsg }}
                <button type="button" @click="dismissModalError(index)" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
            </div>
        </div>

        <!-- Modal messages -->
        <div v-if="modalMessages.length" class="modal-messages d-flex flex-column justify-content-center align-items-center">
            <div 
                v-for="(msg, index) in modalMessages" 
                :key="'modal-message-'+index" 
                class="alert alert-info alert-dismissible mb-3"
            >
            {{ msg }}
                <button type="button" @click="dismissModalMessage(index)" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
            </div>
        </div>

        <Toasts />

    </main>

</template>

<script>
import axios from 'axios';
import store from './store/store.js';
import { BIconBuilding, BIconCart4, BIconChevronDown, BIconChevronLeft, BIconChevronRight, BIconDiagram3Fill, BIconGearFill, BIconGeoAltFill, BIconGlobe2, BIconHouseFill, BIconLink45deg, BIconList, BIconPersonCircle, BIconPersonFill, BIconPower, BIconQuestionCircle, BIconSignpostFill } from 'bootstrap-icons-vue';
import AdminTextsEdit from './components/AdminTextsEdit.vue';
import AdminUsersEdit from './components/AdminUsersEdit.vue';
import BillingInfoFormModal from './components/BillingInfoFormModal.vue';
import CustomCategoryEdit from './components/CustomCategoryEdit.vue';
import DeleteBillingAddress from './components/DeleteBillingAddress.vue';
import HelpText from './components/HelpText.vue';
import ItemEdit from './components/ItemEdit.vue';
import LocationsDbCategoriesListModal from './components/LocationsDbCategoriesListModal.vue';
import MaintenanceModeModal from './components/MaintenanceModeModal.vue';
import MapEditBounds from './components/MapEditBounds.vue';
import MapEmbedCode from './components/MapEmbedCode.vue';
import OverlaySpinner from './components/OverlaySpinner.vue';
import ShopCartEmptyConfirm from './components/ShopCartEmptyConfirm.vue';
import ShopCartMenu from './components/ShopCartMenu.vue';
import SubscriptionContinueConfirm from './components/SubscriptionContinueConfirm.vue';
import SubscriptionEndConfirm from './components/SubscriptionEndConfirm.vue';
import Text from './components/Text.vue';
import Toasts from './components/Toasts.vue';
import WorkspaceCreateNew from './components/WorkspaceCreateNew.vue';
import WorkspaceDeleteConfirm from './components/WorkspaceDeleteConfirm.vue';
import WorkspaceEditMember from './components/WorkspaceEditMember.vue';
import WorkspaceIcon from './components/WorkspaceIcon.vue';

export default {
    name: 'App',
    components: {
        BIconBuilding,
        BIconCart4,
        BIconChevronDown,
        BIconChevronLeft,
        BIconChevronRight,
        BIconDiagram3Fill,
        BIconGearFill,
        BIconGeoAltFill,
        BIconGlobe2,
        BIconHouseFill, 
        BIconLink45deg, 
        BIconList,
        BIconPersonCircle,
        BIconPersonFill,
        BIconPower,
        BIconQuestionCircle,
        BIconSignpostFill,
        AdminTextsEdit,
        AdminUsersEdit,
        BillingInfoFormModal,
        CustomCategoryEdit,
        DeleteBillingAddress,
        HelpText,
        ItemEdit,
        LocationsDbCategoriesListModal,
        MaintenanceModeModal,
        MapEditBounds,
        MapEmbedCode,
        OverlaySpinner,
        ShopCartEmptyConfirm,
        ShopCartMenu,
        SubscriptionContinueConfirm,
        SubscriptionEndConfirm,
        Text,
        Toasts,
        WorkspaceCreateNew,
        WorkspaceDeleteConfirm,
        WorkspaceEditMember,
        WorkspaceIcon
    },
    data() {
        return {
            publicViews: ['Login', 'ResetPassword', 'Registration', 'RegistrationActivation'],
            menuOpen: false,
            menuCondensed: false,
            openSubmenus: [],
            titleBase: 'Matkailukartta',
            getEditingsTimeout: null,
            postMapEditingTimeout: null,
            postItemEditingTimeout: null,
            postCategoryEditingTimeout: null,
            checkSessionTimeout: null,
            oneTimeModals: []
        }
    },
    computed: {
        ready() {
            if (this.publicViews.includes(this.$route.name)) {
                // The view is public, only texts need to be loaded
                return store.state.texts.length;
            } else {
                // The view is not public, user must be logged in and all needed data loaded
                return store.state.user && store.state.texts.length && store.state.subscriptions;
            }
        },
        /**
         * NavPanel is not shown in public views like Login.
         * 
         * @returns {boolean}
         */
        showNavPanel() {
            return ! this.publicViews.includes(this.$route.name);
        },
        lan() {
            return store.state.lan;
        },
        texts() {
            return store.state.texts;
        },
        userWorkspacesSorted() {
            if (store.state.user && store.state.user.workspaces) {
                // Sort alphabetically by name first
                let workspaces = store.state.user.workspaces.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1);
                // Then private workspace first
                return workspaces.sort((a, b) => {
                    if (a.type == 'PRIVATE' && a.userId == store.state.user.id) {
                        return -1;
                    } else if (b.type == 'PRIVATE' && b.userId == store.state.user.id) {
                        return 1;
                    }
                    return 0;
                });
            }
            return null;
        },
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        modalMessages() {
            return store.state.modalMessages;
        },
        modalErrors() {
            return store.state.modalErrors;
        },
        modal() {
            return store.state.modal;
        },
        modalSize() {
            if (this.modal) {
                return this.modal.size;
            }
            return null;
        },
        modalTitle() {
            if (this.modal) {
                return this.modal.title;
            }
            return null;
        },
        modalComponent() {
            if (this.modal) {
                return this.modal.component;
            }
            return null;
        },
        modalComponentProps() {
            if (this.modal) {
                return this.modal.componentProps;
            }
            return null;
        },
        windowWidth() {
            return document.documentElement.clientWidth;
        },
        maintenanceMode() {
            return store.state.maintenanceMode;
        },
        maintenanceModeStarted() {
            if (this.maintenanceMode) {
                let now = new Date();
                let maintenanceStart = new Date(this.maintenanceMode.start);
                return now >= maintenanceStart;
            }
            return false;
        },
        timezoneOffset() {
            let date = new Date();
            return date.getTimezoneOffset();
        }
    },
    watch:{
        $route: {
            immediate: false,
            handler() {
                store.commit('clearErrors');
                store.commit('clearMessages');
                this.menuOpen = false;
                this.openSubmenus = [];
                this.setTitle();

                // Always close modal when route changes
                store.commit('setModal', null);

                // Check session silently every time route changes and throw out if session expired
                axios({
                    method: 'get',
                    url: '/api/session',
                    params: {
                        timezoneOffset: this.timezoneOffset
                    }
                })
                .then((response) => {
                    if ((! response.data.user || ! response.data.user.workspaces) && ! this.publicViews.includes(this.$route.name)) {
                        this.$router.push('/logout');
                    } else {
                        // Session ok, check if new route has level set
                        if (this.$route.meta && this.$route.meta.level && this.$route.meta.level > store.state.user.level) {
                            // No rights, show modal error and throw to dashboard main
                            store.commit('addModalError', 'Insufficient privileges to perform action');
                            this.$router.push('/dashboard');
                        }
                    }
                });
            }
        }
    },
    beforeCreate() {
		store.commit('initStore');
	},
    created() {
        // Event listener for init load
        this.$emitter.on('initLoad', () => {
            this.initLoad();
        });

        // Event listener for opening modal help texts
        this.$emitter.on('openHelpText', (key) => {
            this.openHelpModal(key);
        });

        // Event listener for loading Locations DB categories
        this.$emitter.on('getLocationsDbCategories', () => {
            this.getLocationsDbCategories();
        });

        // Event listener for loading Locations DB categories
        this.$emitter.on('getCustomCategories', () => {
            this.getCustomCategories();
        });

        // Event listener for loading user workspaces
        this.$emitter.on('getWorkspaces', () => {
            this.getWorkspaces();
        });

        // Event listener for loading user billing addresses
        this.$emitter.on('getBillingAddresses', () => {
            this.getBillingAddresses();
        });

        // Event listener for loading user subscriptions
        this.$emitter.on('getSubscriptions', () => {
            this.getSubscriptions();
        });

        // Event listener for changeWorkspace event
        this.$emitter.on('changeWorkspace', (workspaceId) => {
            this.changeWorkspace(workspaceId);
        });

        // Event listener for postEditing event
        this.$emitter.on('postEditing', (editingData) => {
            this.postEditing(editingData);
        });

        // Event listener for endMapEditing event
        this.$emitter.on('endMapEditing', (editingData) => {
            window.clearTimeout(this.postMapEditingTimeout);
            this.postEditing(editingData);
        });

        // Event listener for endItemEditing event
        this.$emitter.on('endItemEditing', (editingData) => {
            window.clearTimeout(this.postItemEditingTimeout);
            this.postEditing(editingData);
        });

        // Event listener for endCategoryEditing event
        this.$emitter.on('endCategoryEditing', (editingData) => {
            window.clearTimeout(this.postCategoryEditingTimeout);
            this.postEditing(editingData);
        });

        // Event listener for logout event
        this.$emitter.on('logout', () => {
            // Clear get editings and check session timeouts when logging out
            window.clearTimeout(this.getEditingsTimeout);
            window.clearTimeout(this.checkSessionTimeout);
            store.commit('resetState');
            store.commit('addMessage', 'Logged out');
            this.$router.push('/');
            this.checkActiveSession();
        });

        // Check for active session if user not set
        if (! store.state.user) {
            this.checkActiveSession();
        } else {
            // User ok, load stuff...
            this.initLoad();
        }
        
        // Check if cart is in localStorage
        if (window.localStorage.getItem('km_cart')) {
            store.commit('setCart', JSON.parse(window.localStorage.getItem('km_cart')));
        }

        // Get init lat & lng from localStorage
        if (window.localStorage.getItem('lat') && window.localStorage.getItem('lng')) {
            store.commit('setDefaultLatitude', window.localStorage.getItem('lat'));
            store.commit('setDefaultLongitude', window.localStorage.getItem('lng'));
        }
    },
    mounted() {
        // Condense menu automatically on mount if needed
        this.setMenuCondensed();
    },
    /**
     * The errorCaptured hook is called when an error from any descendent component is captured.
     * Can be used to do something when for example a known but unresolved bug occurs.
     */
    errorCaptured(err, vm, info) {
        console.error('Captured error: ' + err.toString());
        console.log(vm);
        console.log('Captured error info: ' + info);

        if (err.toString() == "TypeError: Cannot read property 'Marker' of undefined") {
            // This is probably the mystical Leaflet Draw bug
            console.log('Leaflet Draw error captured, trying to reinit map');
            this.$emitter.emit('initMap');
        }
        else if (err.toString() == "TypeError: Cannot read property '_zoom' of undefined") {
            // This is probably the Leaflet map zoom/location going back and forth bug
            console.log('Map error captured, trying to reset map config');
            this.$emitter.emit('setMapConfig');
        }
    },
    methods: {
        checkReady() {
            if (this.ready) {
                this.$emitter.emit('ready');
                store.commit('setLoading', false);
            }
        },
        initLoad() {
            this.getInitTexts();
            this.getProducts();
            this.getBillingAddresses();
            this.getSubscriptions();
            this.getLocationsDbCategories();
            this.getCustomCategories();
            this.getEditings();
            // Call checkSession after init to check for maintenance mode also 
            // and start timeout to check it regularly
            this.checkSession();
        },
        /**
         * Checks for an active session. This is used only for checking the session on page load.
         */
        checkActiveSession() {
            axios({
                method: 'get',
                url: '/api/session',
                params: {
                    timezoneOffset: this.timezoneOffset
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.user) {
                    // Session found, set user data
                    store.commit('setUser', response.data.user);
                    this.initLoad();
                } else if (this.publicViews.includes(this.$route.name)) {
                    // No session but the view is public, load texts
                    this.getInitTexts();
                    // Check if maintenance mode is on
                    if (response.data.maintenance) {
                        //console.log(response.data.maintenance);
                        store.commit('setMaintenanceMode', response.data.maintenance);
                    }
                } else {
                    // No session, go to login if not a public view
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
                this.$router.push('/');
            });
        },
        /**
         * Checks for session and maintenance mode. Uses the same endpoint as checkActiveSession!
         */
        checkSession() {
            axios({
                method: 'get',
                url: '/api/session',
                params: {
                    timezoneOffset: this.timezoneOffset
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.user) {
                    // Session ok, set timeout to check again in a while
                    this.checkSessionTimeout = window.setTimeout(this.checkSession, 5000);
                    // Check if maintenance mode is on
                    if (response.data.maintenance) {
                        //console.log(response.data.maintenance);
                        store.commit('setMaintenanceMode', response.data.maintenance);
                        // Show modal if needed
                        if (this.showMaintenanceModal(response.data.maintenance.start)) {
                            this.oneTimeModals.push('maintenance');
                            store.commit('setModal', {
                                component: 'MaintenanceModeModal',
                                componentProps: {
                                    maintenanceMode: response.data.maintenance
                                }
                            });
                        }
                    }
                } else {
                    // Session expired, throw out
                    store.commit('addModalError', 'Session expired.');
                    this.$router.push('/logout');
                } 
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
                this.$router.push('/logout');
            });
        },
        /**
         * Gets all initLoad texts with current lan.
         */
        getInitTexts() {
            axios({
                method: 'get',
                url: '/api/texts',
                params: {
                    lan: this.lan,
                    initLoad: true
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.texts) {
                    // Put current lan texts in store texts
                    store.commit('setTexts', response.data.texts[this.lan]);
                    this.setTitle();
                    this.checkReady();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * Gets all active workspace's subscriptions from the db and sets them to store.
         */
        getSubscriptions() {
            store.commit('setSubscriptions', null);
            axios({
                method: 'get',
                url: '/api/subscriptions',
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    store.commit('setSubscriptions', response.data.subscriptions);
                    this.$emitter.emit('subscriptionsLoaded');
                    this.checkReady();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * Loads default categories from Locations DB and sets them to store locationsDbCategories.
         */
        getLocationsDbCategories() {
            store.commit('setLocationsDbCategories', null);
            axios({
                method: 'get',
                url: process.env.VUE_APP_LOCATIONS_PATH + 'api/categories',
                params: {
                    lan: store.state.lan,
                    order: 'name ASC'
                }
            })
            .then((response) => {
                store.commit('setLocationsDbCategories', response.data);
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading categories failed');
                console.error(error);
            });
        },
        /**
         * Loads custom categories from Locations DB and sets them to store customCategories.
         */
        getCustomCategories() {
            store.commit('setCustomCategories', null);
            axios({
                method: 'get',
                url: process.env.VUE_APP_LOCATIONS_PATH + 'api/categories',
                params: {
                    workspaceId: store.state.user.activeWorkspaceId,
                    order: 'name ASC'
                }
            })
            .then((response) => {
                store.commit('setCustomCategories', response.data);
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading custom categories failed');
                console.error(error);
            });
        },
        /**
         * Loads products with curretn lan from DB and sets them to store products.
         */
        getProducts() {
            store.commit('setProducts', null);
            axios({
                method: 'get',
                url: process.env.VUE_APP_BACKEND_PATH + 'api/products',
                params: {
                    lan: store.state.lan
                }
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.products) {
                    // Put current lan texts in store texts
                    store.commit('setProducts', response.data.products);
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading products failed');
                console.error(error);
            });
        },
        /**
         * Gets user's billing addresses.
         */
        getBillingAddresses() {
            store.commit('setBillingAddresses', null);
            axios({
                method: 'get',
                url: '/api/billing-address',
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    store.commit('setBillingAddresses', response.data.billingAddresses);
                    this.$emitter.emit('billingAddressesLoaded');
                } 
                else if (response.data.errors) {
                    this.$addToastError(response.data.errors);
                }
            })
            .catch((error) => {
                this.$addToastError(this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * Gets all user's workspaces from the db and sets them to store userWorkspaces.
         */
        getWorkspaces() {
            axios.get('/api/workspaces')
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    // Update user workspaces to store
                    store.commit('setUserWorkspaces', response.data.workspaces);
                    this.$emitter.emit('workspacesLoaded');
                } else if (response.data.errors) {
                    store.commit('addModalError', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * Adds "(workspace)" to workspace name if it is the same as user name.
         * 
         * @param   {string}    workspaceName
         * @returns {string}
         */
        getWorkspaceName(workspaceName) {
            if (workspaceName == store.state.user.firstName + ' ' + store.state.user.lastName) {
                return workspaceName + ' (' + this.$i18n('LABEL_WORKSPACE').toLowerCase() + ')';
            }
            return workspaceName;
        },
        /**
         * Change active workspace.
         * 
         * @param   {int}   workspaceId
         */
        changeWorkspace(workspaceId) {
            // Cant' change active workspace when editing a map
            if (this.$route.path.indexOf('/maps/edit/') !== -1) {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_CANT_CHANGE_WORKSPACE_WHEN_EDITING_MAP'));
                return;
            }

            store.commit('emptyCart');
            store.commit('setActiveWorkspace', workspaceId);
            this.$emitter.emit('getWorkspaces');
            this.$emitter.emit('getSubscriptions');
            this.$emitter.emit('getBillingAddresses');
            this.$emitter.emit('getCustomCategories');
            store.commit('clearErrors');
            store.commit('clearMessages');
        },
        /**
         * Opens modal for creating new workspace.
         * 
         * @param   {object}    member
         */
        openCreateNewWorkspace() {
            store.commit('setModal', {
                component: 'WorkspaceCreateNew'
            });
        },
        changeLan(newLan) {
            store.commit('setLan', newLan);
            this.getInitTexts();
            this.$emitter.emit('lanChange');
        },
        toggleMenu() {
            this.menuOpen = ! this.menuOpen;
            this.menuCondensed = false;
        },
        toggleMenuCondensed(e) {
            this.menuCondensed = ! this.menuCondensed;
            e.target.blur();
        },
        /**
         * Toggles submenu existence in openSubmenus.
         * Called when clicking a menu parent link.
         * 
         * @param   {string}    parentPath
         */
        toggleSubNav(parentPath) {
            let index = this.openSubmenus.indexOf(parentPath);
            if (index > -1) {
                // It exists in openSubmenus, remove it
                this.openSubmenus.splice(index, 1);
            } else {
                // Doesn't exist in openSubmenus, add it
                this.openSubmenus.push(parentPath);
                this.menuCondensed = false;
            }
        },
        /**
         * Shows/hides submenu according to status quo.
         * 
         * @param   {string}    parentPath
         * @returns {boolean}
         */
        showSubnav(parentPath) {
            // Don't show submenus in condensed mode
            if (this.menuCondensed) {
                return false;
            }
            // Show if manually opened by user
            if (this.openSubmenus.includes(parentPath)) {
                return true;
            }
            // Finally show/hide according to current path
            return this.$route.path.search(parentPath) != -1;
        },
        setMenuCondensed() {
            this.menuCondensed = (this.windowWidth >= 768 && this.windowWidth <= 920);
        },
        /**
         * Sets the title based on current route meta.title and this.titleBase.
         */
        setTitle() {
            if (this.$route.meta.title && store.state.texts.length) {
                document.title = this.$i18n(this.$route.meta.title) + ' - ' + this.titleBase;
            } else {
                document.title = this.titleBase;
            }
        },
        getRouteChildren(routeName) {
            for (let route of this.$router.options.routes) {
                if (route.name === routeName) {
                    return route.children;
                }
            }
        },
        getRouteLabel(route) {
            if (route.meta && route.meta.label) {
                return this.$i18n(route.meta.label);
            }
            return route.name;
        },
        dismissModalMessage(index) {
            store.commit('dismissModalMessage', index);
        },
        dismissModalError(index) {
            store.commit('dismissModalError', index);
        },
        closeModal() {
            store.commit('setModal', null);
        },
        /**
         * Opens help text in modal. Called from emitted event openHelpText.
         * 
         * @param   {string}    helpTextKey
         */
        openHelpModal(helpTextKey) {
            store.commit('setModal', {
                component: 'HelpText',
                componentProps: {
                    helpTextKey: helpTextKey
                },
                size: 'modal-lg'
            });
        },
        /**
         * @param   {string}    maintenanceStart
         * @returns {boolean}
         */
        showMaintenanceModal(maintenanceStart) {
            let now = new Date();
            let hBeforeStart = new Date(maintenanceStart);
            hBeforeStart.setHours(hBeforeStart.getHours() - 1);
            return now >= hBeforeStart && this.oneTimeModals.indexOf('maintenance') === -1;
        },
        /**
         * Gets all active workspace's editings from the db and sets them to store.
         */
        getEditings() {
            axios({
                method: 'get',
                url: '/api/editings',
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    store.commit('setEditings', response.data.editings);
                    // Set timeout to reload editings
                    this.getEditingsTimeout = window.setTimeout(this.getEditings, 2000);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        /**
         * @param   {object}    editingData
         */
        postEditing(editingData) {
            let formData = new FormData();

            for (let key in editingData ) {
                formData.append(key, editingData[key]);
            }

            axios({
                method: 'post',
                url: '/api/editing',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.errors) {
                    for (let i in response.data.errors ) {
                        console.error(response.data.errors[i]);
                    }
                } else {
                    // Post editing ok, set timout if id returned
                    if (response.data.editing) {
                        let editingTimeout;
                        if (response.data.editing.mapId) {
                            editingTimeout = 'postMapEditingTimeout';
                        } else if (response.data.editing.placeId || response.data.editing.routeId) {
                            editingTimeout = 'postItemEditingTimeout';
                        } else if (response.data.editing.categoryId) {
                            editingTimeout = 'postCategoryEditingTimeout';
                        } else {
                            console.error('Editing has no resource id');
                        }
                        if (editingTimeout) {
                            this[editingTimeout] = window.setTimeout(() => {
                                this.postEditing({
                                    workspaceId: store.state.user.activeWorkspaceId,
                                    id: response.data.editing.id
                                });
                            }, 5000);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }
}
</script>

<style scoped>
header .logo {
    max-width: 200px;
    margin: 0 auto 0 .75rem;
}
header .dropdown-toggle {
    color: #fff;
}
header .dropdown-toggle::after {
    vertical-align: .125em;
}
.menu-btn,
.menu-condense-btn {
    display: block;
    background-color: transparent;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 2.75rem;
    line-height: 2.5rem;
    padding: 0;
    border: none;
}
.menu-btn:active,
.menu-btn:hover,
.menu-btn:focus,
.menu-condense-btn:active,
.menu-condense-btn:hover,
.menu-condense-btn:focus {
    background-color: var(--bs-primary);
    color: var(--bs-dark);
    outline: none;
}
.menu-condense-btn {
    width: auto;
    height: auto;
    font-size: 2rem;
    padding: 0 0 .35rem .5rem;
}
main {
    transition: margin-left .5s;
}
main.logged-in {
    padding-top: 50px;
}
.container-fluid.not-logged-in {
    --bs-gutter-x: 0;
}
.nav-panel {
    position: fixed;
    top: 50px;
    left: -100%;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 10100;
    transition: left .5s, width .5s;
}
.nav-panel.open {
    left: 0;
}
.nav-panel .nav-link {
    position: relative;
    width: 100%;
    display: block;
    background-color: transparent;
    color: #fff;
    text-align: left;
    border: none;
    opacity: .5;
}
.nav-panel .nav-link.router-link-active{
    background-color: rgba(0, 0, 0, .25);
    opacity: 1;
}
.nav-panel .nav-link:active,
.nav-panel .nav-link:hover,
.nav-panel .nav-link:focus {
    text-decoration: none;
    background-color: var(--bs-primary);
    color: var(--bs-dark);
    opacity: 1;
    outline: none;
}
.nav-panel .nav-link svg {
    position: relative;
    top: -2px;
    margin-right: .5rem;
}
.nav-panel .nav-item > button.nav-link {
    display: flex;
    align-items: center;
}
.nav-panel .nav-item > button.nav-link > svg {
    margin-top: 3px;
}
.nav-panel .nav-item > button.nav-link > svg:last-child {
    margin-left: auto;
    margin-right: 0;
}
.nav-panel .nav-level-2 .nav-link {
    font-size: .85rem;
    padding: .25rem 1rem .25rem 2.5rem;
}
.nav-panel .nav-level-2 .nav-link.router-link-active::after {
    top: .125rem;
}
.modal-errors,
.modal-messages  {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .75);
    z-index: 999999;
}
.modal-errors > .alert,
.modal-messages > .alert {
    width: 720px;
    max-width: 720px;
    margin: 0 1rem;
}
.modal {
    z-index: 99999;
    display: block;
    visibility: hidden;
    background-color: rgba(0,0,0,.5);
}
.modal.show {
    visibility: visible;
}
.alert-maintenance-mode {
    max-width: 720px;
    margin: 3rem auto;
}
.dropdown-item > svg {
    position: relative;
    top: -2px;
}
#lanMenuBtn::after {
    position: relative;
    top: -1px;
}

/* Exceptions for desktop view */
@media (min-width: 768px) {
    main.menu-open {
        margin-left: 320px;
    }
    main.menu-condensed {
        margin-left: 48px;
    }
    .nav-panel {
        left: 0;
        width: 320px;
    }
    .nav-panel.condensed {
        width: 48px;
    }
    .nav-panel.condensed .nav-link svg {
        margin-right: 0;
    }
    /* Pseudo elemento to create the triangle for active section */
    .nav-panel .nav-link.router-link-active::after {
        content: '';
        display: block;
        position: absolute;
        top: .5rem;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .75rem .75rem .75rem 0;
        border-color: transparent #dee2e6 transparent transparent;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Exceptions for mobile view */
@media (max-width: 767px) {
    .nav-panel .nav-link svg {
        top: -4px;
    }
    .nav-panel .nav-link {
        font-size: 1.5rem;
    }
    .nav-panel .nav-level-2 .nav-link {
        font-size: 1rem;
        padding-left: 3rem;
    }
    .modal-errors > .alert,
    .modal-messages > .alert {
        width: auto;
    }
}

/* Exceptions for small mobile views */
@media (max-width: 575px) {
    .main-container {
        padding: 0;
    }
}

@media (max-width: 480px) {
    header .logo {
        max-width: 150px;
        margin-left: .5rem;
    }
    header .dropdown-toggle[data-v-7ba5bd90]::after {
        display: none;
    }
}

@media (max-width: 339px) {
    header .logo {
        display: none;
    }
}

</style>

<template>

    <div class="border mb-3">
        <div id="ecm" :data-ecm-dir="mapDirPath" :data-map-key="mapKey" data-boxed="true" data-preview="true"></div>
    </div>

</template>

<script>

export default {
    name: 'MapEditMapPreview',
    props: {
        mapKey: String
    },
    data() {
        return {
            ecmLink: null,
            ecmScript: null
        }
    },
    computed: {
        /**
         * Computed property for env var VUE_APP_MAP_PATH
         * so it can be used in the template as well.
         * 
         * @returns {string}
         */
        mapDirPath() {
            return process.env.VUE_APP_MAP_PATH;
        }
    },
    mounted() {
        this.ecmLink = document.createElement('link');
        this.ecmLink.setAttribute('rel', 'stylesheet');
        this.ecmLink.setAttribute('href', this.mapDirPath + 'css/');
        document.body.appendChild(this.ecmLink);

        this.ecmScript = document.createElement('script');
        this.ecmScript.setAttribute('src', this.mapDirPath + 'js/');
        document.body.appendChild(this.ecmScript);
    },
    unmounted() {
        // Remove manually added elements from DOM on unmount
        document.body.removeChild(this.ecmLink);
        document.body.removeChild(this.ecmScript);
        this.ecmLink = null;
        this.ecmScript = null;
    }
}
</script>

<style scoped>
.map-preview {
    position: static !important;
    width: 100%;
    height: 450px;
}
</style>

import store from './store/store.js';
import mitt from 'mitt';
import * as bootstrap from 'bootstrap';

/**
 * globals.js
 * 
 * Globals plugin containing global methods accessible by all components.
 * This is intended for methods like $i18n for string translations and
 * other helper functions needed in all or several components.
 * 
 * All global method names should start with prefix $ to indicate that
 * the method is defined somewhere outside the component.
 */
export default { 
    install: (app) => {

        // Global internal $emitter to communicate between components.
        app.config.globalProperties.$emitter = mitt();

        // Just in case we need to reference Bootstrap directly.
        app.config.globalProperties.$bootstrap = bootstrap;

        /**
         * Are we loading or not.
         * @returns {boolean}
         */
        app.config.globalProperties.$loading = () => { 
            return store.state.loading;
        };
        
        /**
         * Additional status text for loading.
         * @returns {string}
         */
        app.config.globalProperties.$loadingStatus = () => {
            return store.state.loadingStatus;
        };

        /**
         * Adds a new toast message.
         * @param   {string|array}    messages 
         */
        app.config.globalProperties.$addToastMessage = (messages) => {
            // Make messages an array if needed
            if (! Array.isArray(messages)) {
                messages = [messages];
            }
            // Loop through messages and add to toasts
            for (let i in messages) {
                store.commit('addToast', {
                    type: 'info',
                    message: messages[i]
                });
            }
        };

        /**
         * Adds a new toast alert.
         * @param   {string|array}    messages 
         */
        app.config.globalProperties.$addToastAlert = (messages) => {
            // Make messages an array if needed
            if (! Array.isArray(messages)) {
                messages = [messages];
            }
            // Loop through messages and add to toasts
            for (let i in messages) {
                store.commit('addToast', {
                    type: 'warning',
                    message: messages[i]
                });
            }
        };

        /**
         * Adds a new toast error.
         * @param   {string|array}    messages
         */
        app.config.globalProperties.$addToastError = (messages) => {
            // Make messages an array if needed
            if (! Array.isArray(messages)) {
                messages = [messages];
            }
            // Loop through messages and add to toasts
            for (let i in messages) {
                store.commit('addToast', {
                    type: 'danger',
                    message: messages[i]
                });
            }
        };

        /**
         * Currently logged in user.
         * @returns {object}
         */
        app.config.globalProperties.$user = () => {
            return store.state.user;
        };

        /**
         * Is the logged in user admin or not.
         * @returns {boolean}
         */
        app.config.globalProperties.$isAdmin = () => {
            return store.state.user && store.state.user.level >= 1000;
        };

        /**
         * Is there an active basic subscription for current workspace.
         * @returns {boolean}
         */
        app.config.globalProperties.$hasBasicSubscription = () => {
            if (store.state.subscriptions) {
                return store.state.subscriptions.find(item => item.productId == 1 && item.ended == null);
            }
            return false;
        };

        /**
         * Returns a text with current lan from store.
         * @param   {string}    id
         * @returns {string}
         */
        app.config.globalProperties.$i18n = (id) => {
            return store.getters.getTextById(id);
        };

        /**
         * Returns a text with current lan from store.
         * @param   {string}    id
         * @returns {boolean}
         */
        app.config.globalProperties.$i18nExists = (id) => {
            return !!store.state.texts.find(text => text.id === id);
        };

        /**
         * Calculates new price for product based on domain count.
         * @param   {number}    price
         * @param   {number}    domains
         * @returns {number}
         */
         app.config.globalProperties.$calculatePrice = (price, domains) => {
            let multiplier = (domains - 1) * .25 + 1;
            return (price * multiplier).toFixed(2);
        };

        /**
         * Formats date string with lan locale.
         * @param   {string}    dateStr
         * @returns {string}
         */
        app.config.globalProperties.$formatDate = (dateStr) => {
            if (dateStr === null) {
                return dateStr;
            }
            // The replace thingy is for Safari compatibility
            let date = new Date(dateStr.replace(/-/g, '/'));
            return date.toLocaleDateString(store.state.lan);
        };

        /**
         * Formats datetime string with lan locale.
         * @param   {string}    dateStr
         * @returns {string}
         */
        app.config.globalProperties.$formatDateTime = (dateStr) => {
            // The replace thingy is for Safari compatibility
            let date = new Date(dateStr.replace(/-/g, '/'));
            return date.toLocaleString(store.state.lan);
        };

        /**
         * @param   {string}    pricePerMonth
         * @returns {string}
         */
        app.config.globalProperties.$truncateString = (str, length) => {
            if (str.length > length) {
                return str.slice(0, length) + '...';
            }
            return str;
        }

        /**
         * @param   {string}    str
         * @returns {string}
         */
        app.config.globalProperties.$slugify = (str) => {
            return str.toString().toLowerCase()
                .replace(/\s+/g, '-')       // Replace spaces with -
                .replace(/[^\w-]+/g, '')    // Remove all non-word chars
                .replace(/--+/g, '-')       // Replace multiple - with single -
                .replace(/^-+/, '')         // Trim - from start of text
                .replace(/-+$/, '');        // Trim - from end of text
        }

        /**
         * Checks if the current browser supports an input type.
         * @param   {string}    inputType
         * @returns {boolean}
         */
        app.config.globalProperties.$supportsInputType = (inputType) => {
            // Input type text is the default, all browsers support it
            if (inputType === 'text') {
                return true;
            }
            let el = document.createElement('input');
            el.setAttribute('type', inputType);
            let isSupported = el.type !== 'text';
            el = null;
            return isSupported;
        };

    }
};
<template>

    <editor
        v-if="editorHeight"
        api-key="xw3j6ukphzxnee0qiafvv7wplmpb4d2oku9fatn4ztv3xsks"
        :init="tinyOptions()"
        id="text"
        name="text"
        v-model="item.text"
    />

</template>

<script>
import tinyStylesString from '!!raw-loader!../assets/css/tinymce-content.css';
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'ItemEditArticle',
    components: {
        Editor
    },
    props: {
        modelValue: {
            type: Object
        }
    },
    computed: {
        /*editItemFormHeight() {
            //console.log(document.getElementById('editItemForm').offsetHeight);
            if (document.getElementById('editItemForm')) {
                return document.getElementById('editItemForm').offsetHeight;
            }
            return 350;
        }*/
    },
    data() {
        return {
            item: this.modelValue,
            editorHeight: null
        }
    },
    mounted() {
        this.setEditorHeight();
    },
    methods: {
        /**
         * Returns TinyMCE init options with desired language selector.
         * 
         * @param   {string}    lan
         * @returns {object}
         */
        tinyOptions() {
            return {
                selector: '#text',
                menubar: false,
                height: this.editorHeight,
                plugins: 'lists link fullscreen',
                style_formats : [
                    {title: 'Paragraph', block: 'p'},
                    {title: 'Heading 2', block: 'h2'},
                    {title: 'Heading 3', block: 'h3'}
                ],
                toolbar: 'styleselect | bold italic | bullist numlist | link | fullscreen',
                entity_encoding : 'raw',
                content_style: tinyStylesString
            }
        },
        setEditorHeight() {
            this.editorHeight = document.getElementById('editItemForm').offsetHeight;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="bg-white p-3 my-sm-3 shadow-sm">

        <h1>{{ $i18n('LABEL_MY_ACCOUNT') }}</h1>
        
        <h2 class="d-md-none">{{ $i18n(tabs[activeTab]) }}</h2>
        
        <!-- TAB NAV -->
        <ul class="nav nav-tabs mb-3 d-none d-md-flex">
            <li 
                v-for="(tabLabel, tabKey) in tabs" 
                :key="tabKey"
                class="nav-item">
                <router-link :to="'/my-account/' + tabKey" class="nav-link" :class="{active: activeTab == tabKey}">{{ $i18n(tabLabel) }}</router-link>
            </li>
        </ul>

        <Alerts />

        <div class="tab-content">

            <!-- ACCOUNT INFO TAB CONTENT -->
            <div class="tab-pane fade" :class="{show: activeTab == 'account-info', active: activeTab == 'account-info'}">
                
                <div class="row">
                
                    <div class="col-xl-8 col-xxl-6">

                        <table v-if="$user()" class="table border-top">
                            <tbody>
                                <tr>
                                    <th scope="row">{{ $i18n('LABEL_FIRST_NAME') }}</th>
                                    <td class="text-end">{{ $user().firstName }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{{ $i18n('LABEL_LAST_NAME') }}</th>
                                    <td class="text-end">{{ $user().lastName }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{{ $i18n('LABEL_EMAIL') }}</th>
                                    <td class="text-end">{{ $user().email }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{{ $i18n('LABEL_ACCOUNT_CREATED') }}</th>
                                    <td class="text-end">{{ $user().created }}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div> <!-- /.row -->

            </div>

            <!-- CHANGE PASSWORD TAB CONTENT -->
            <div class="tab-pane fade" :class="{show: activeTab == 'change-password', active: activeTab == 'change-password'}">

                <ChangePasswordForm />

            </div>

        </div> <!-- /.tab-content -->

    </div>
</template>

<script>
import Alerts from '../components/Alerts.vue';
import ChangePasswordForm from '../components/ChangePasswordForm.vue';

export default {
    name: 'MyAccount',
    components: {
        Alerts,
        ChangePasswordForm
    },
    data() {
        return {
            tabs: {
                'account-info': 'LABEL_ACCOUNT_INFO',
                'change-password': 'LABEL_CHANGE_PASSWORD'
            },
            activeTab: 'account-info'
        }
    },
    watch: {
        $route() {
            if (this.$route.params.tab) {
                this.activeTab = this.$route.params.tab;
            }
        }
    },
    mounted() {
        if (this.$route.params.tab) {
            this.activeTab = this.$route.params.tab;
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
<template>

    <OverlaySpinner v-if="!billingAddresses" />

    <form 
        v-if="isWorkspaceAdmin"
        action="api/billing-info" 
        method="post" 
        id="billingInfoForm"
        class="needs-validation" 
        :class="{'was-validated': wasValidated}"
        @submit="postBillingAddress($event)" 
        novalidate>

        <div class="row">
    
            <div :class="{'col-xl-8 col-xxl-6': !modal, 'col': modal}">
                
                <div class="mb-3">
                    <label for="type" class="form-label">{{ $i18n('LABEL_CUSTOMER_TYPE') }}</label>
                        <select v-model="workspaceType" @change="workspaceTypeChange()" id="workspaceType" name="workspaceType" class="form-select" required>
                            <option :value="null">{{ $i18n('LABEL_SELECT_ORGANIZATION_TYPE') }}</option>
                            <option value="PRIVATE">{{ $i18n('LABEL_PRIVATE_CUSTOMER') }}</option>
                            <option value="COMPANY">{{ $i18n('LABEL_COMPANY') }}</option>
                            <option value="MUNICIPAL_OR_GOVERNMENTAL_ORGANIZATION">{{ $i18n('LABEL_MUNICIPAL_OR_GOVERNMENTAL_ORGANIZATION') }}</option>
                            <option value="ASSOCIATION_SOCIETY_OR_CLUB">{{ $i18n('LABEL_ASSOCIATION_SOCIETY_OR_CLUB') }}</option>
                            <option value="OTHER_ORGANIZATION">{{ $i18n('LABEL_OTHER_ORGANIZATION') }}</option>
                        </select>
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_FIELD_REQUIRED') }}
                    </div>
                </div>

                <div v-if="workspaceType">

                    <fieldset>
                        <legend>{{ $i18n('LABEL_BILLING_ADDRESS') }}</legend>

                        <div v-if="isPrivateWorkspace" class="row">

                            <div class="col-md-6 mb-3">
                                <label for="firstName" class="form-label">* {{ $i18n('LABEL_FIRST_NAME') }}</label>
                                <input v-model="firstName" type="text" id="firstName" name="firstName" class="form-control" required />
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>
                        
                            <div class="col-md-6 mb-3">
                                <label for="lastName" class="form-label">* {{ $i18n('LABEL_LAST_NAME') }}</label>
                                <input v-model="lastName" type="text" id="lastName" name="lastName" class="form-control" required />
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>

                        </div> <!-- /.row -->

                        <div v-if="isPrivateWorkspace">
                            <label class="form-label">* {{ $i18n('LABEL_DOB') }}</label>
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="input-group has-validation mb-3">
                                        <label for="dobDay" class="input-group-text font-monospace">Day&nbsp;&nbsp;</label>
                                        <select @change="setDob()" v-model="dobDay" id="dobDay" class="form-select rounded-right" required>
                                            <option :value="null">{{ $i18n('LABEL_SELECT') }}</option>
                                            <option 
                                            v-for="day in days" 
                                            :key="day"
                                            :value="day">
                                                {{ day }}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="input-group has-validation mb-3">
                                        <label for="dobMonth" class="input-group-text font-monospace">Month</label>
                                        <select @change="setDob()" v-model="dobMonth" id="dobMonth" class="form-select rounded-right" required>
                                            <option :value="null">{{ $i18n('LABEL_SELECT') }}</option>
                                            <option 
                                            v-for="month in months" 
                                            :key="month"
                                            :value="month">
                                                {{ month }}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="input-group has-validation mb-3">
                                        <label for="dobYear" class="input-group-text font-monospace">Year&nbsp;</label>
                                        <select @change="setDob()" v-model="dobYear" id="dobYear" class="form-select rounded-right" required>
                                            <option :value="null">{{ $i18n('LABEL_SELECT') }}</option>
                                            <option 
                                            v-for="year in years" 
                                            :key="year"
                                            :value="year">
                                                {{ year }}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                        </div>
                                    </div>
                                </div>

                            </div> <!-- /.row -->
                        </div>

                        <div v-if="isOrganizationWorkspace" class="mb-3">
                            <label for="company" class="form-label">* {{ $i18n('LABEL_COMPANY_OR_ORGANIZATION') }}</label>
                            <input v-model="company" type="text" id="company" name="company" class="form-control" required />
                            <div class="invalid-feedback">
                                {{ $i18n('LABEL_FIELD_REQUIRED') }}
                            </div>
                        </div>

                        <div v-if="isOrganizationWorkspace" class="mb-3">
                            <label for="bic" class="form-label">* {{ $i18n('LABEL_BIC') }}</label>
                            <input v-model="bic" type="text" id="bic" name="bic" class="form-control" required />
                            <div class="invalid-feedback">
                                {{ $i18n('LABEL_FIELD_REQUIRED') }}
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="address" class="form-label">* {{ $i18n('LABEL_ADDRESS') }}</label>
                            <input v-model="address" type="text" id="address" name="address" class="form-control" required />
                            <div class="invalid-feedback">
                                {{ $i18n('LABEL_FIELD_REQUIRED') }}
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="postalCode" class="form-label">* {{ $i18n('LABEL_POSTAL_CODE') }}</label>
                                <input v-model="postalCode" type="text" id="postalCode" name="postalCode" class="form-control" required />
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="postOffice" class="form-label">* {{ $i18n('LABEL_POST_OFFICE') }}</label>
                                <input v-model="postOffice" type="text" id="postOffice" name="postOffice" class="form-control" required />
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>
                        </div> <!-- /.row -->

                        <div class="mb-3">
                            <label for="country" class="form-label">* {{ $i18n('LABEL_COUNTRY') }}</label>
                            <select v-model="country" id="country" name="country" class="form-select" required>
                                <option :value="null">{{ $i18n('LABEL_SELECT') }}</option>
                                <option disabled>──────────</option>
                                <option value="Finland">Finland</option>
                                <option disabled>──────────</option>
                                <option 
                                    v-for="countryItem in countries"
                                    :key="countryItem.code.toLowerCase()"
                                    :value="countryItem.name">
                                        {{ countryItem.name }}
                                </option>
                            </select>
                            <div class="invalid-feedback">
                                {{ $i18n('LABEL_FIELD_REQUIRED') }}
                            </div>
                        </div>

                    </fieldset>

                    <fieldset>
                        <legend>Invoicing details</legend>

                        <div class="row">

                            <div class="col-md-6 mb-3">
                                <label for="invoiceType" class="form-label">* {{ $i18n('LABEL_INVOICE_TYPE') }}</label>
                                <select v-model="invoiceType" @change="invoiceTypeChange()" id="invoiceType" name="invoiceType" class="form-select" required>
                                    <option :value="null">{{ $i18n('LABEL_SELECT') }}</option>
                                    <option value="email">{{ $i18n('LABEL_EMAIL') }}</option>
                                    <option value="einvoice">{{ $i18n('LABEL_E_INVOICE') }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>

                            <div v-if="eInvoice" class="col-md-6 mb-3">
                                <label for="eInvoiceAddress" class="form-label">* {{ $i18n('LABEL_E_INVOICE_ADDRESS') }}</label>
                                <input v-model="eInvoiceAddress" type="text" id="eInvoiceAddress" name="eInvoiceAddress" class="form-control" required />
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>

                            <div v-if="emailInvoice" class="col-md-6 mb-3">
                                <label for="email" class="form-label">* {{ $i18n('LABEL_EMAIL') }}</label>
                                <input v-model="email" type="email" id="email" name="email" class="form-control" required />
                                <div class="invalid-feedback">
                                    This field is required and has to be a valid email address.
                                </div>
                            </div>
                        
                        </div> <!-- /.row -->

                        <div v-if="invoiceType" class="mb-3">
                            <label for="reference" class="form-label">Additional workspace reference</label>
                            <input v-model="reference" type="text" id="reference" name="reference" class="form-control" />
                        </div>

                    </fieldset>

                    <input v-if="isPrivateWorkspace" v-model="dob" type="hidden" id="dob" name="dob" class="form-control" />
                    <input type="hidden" id="id" name="id" v-model="id" />
                    <input type="hidden" id="userId" name="userId" :value="userId" />
                    <input type="hidden" id="workspaceId" name="workspaceId" :value="activeWorkspace.id" />

                    <div class="text-center d-grid gap-2 d-sm-block my-5">
                        <button v-if="id" @click="openConfirmDeleteModal()" type="button" class="btn btn-danger me-sm-2">
                            {{ $i18n('LABEL_DELETE_BILLING_ADDRESS') }}
                        </button>
                        <button type="submit" class="btn btn-primary">
                            {{ $i18n('LABEL_SAVE_BILLING_ADDRESS') }}
                        </button>
                    </div>

                </div>

            </div>

        </div> <!-- /.row -->

    </form>

    <Text v-if="!isWorkspaceAdmin" :id="'TEXT_ONLY_ADMINS_CAN_EDIT_BILLING_ADDRESSES'" class="form-text" />

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import countries from '../assets/data/countries.json';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import Text from '../components/Text.vue';

export default {
    name: 'BillingInfoForm',
    components: {
        OverlaySpinner,
        Text
    },
    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return this.initData();
    },
    computed: {
        billingAddresses() {
            return store.state.billingAddresses;
        },
        isPrivateWorkspace() {
            return this.workspaceType == 'PRIVATE';
        },
        isOrganizationWorkspace() {
            return this.workspaceType != 'PRIVATE';
        },
        days() {
            return Array.from({length: 31}, (value, index) => {
                return (index + 1).toString().padStart(2, '0');
            });
        },
        months() {
            return Array.from({length: 12}, (value, index) => {
                return (index + 1).toString().padStart(2, '0');
            });
        },
        years() {
            const year = new Date().getFullYear();
            return Array.from({length: year - 1900}, (value, index) => year - index);
        },
        eInvoice() {
            return this.invoiceType == 'einvoice';
        },
        emailInvoice() {
            return this.invoiceType == 'email';
        },
        userId() {
            return store.state.user.id;
        },
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        isWorkspaceAdmin() {
            // Check if user is the owner of this workspace
            if (this.activeWorkspace.userId == store.state.user.id) {
                return true;
            }
            // Check if user has admin rights for this workspace
            if (this.activeWorkspace.userLevel >= 1000) {
                return true;
            }
            return false;
        }
    },
    created() {
        store.commit('setLoading', true);

        // Event emitted from getBillingAddresses after complete
        this.$emitter.on('billingAddressesLoaded', () => {
            this.getDefaultBillingAddress();
        });

        // Event emitted from getBillingAddresses after complete
        this.$emitter.on('workspacesLoaded', () => {
            this.getDefaultBillingAddress();
        });
    
        this.getDefaultBillingAddress();
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('billingAddressesLoaded');
        this.$emitter.all.delete('workspacesLoaded');
    },
    methods: {
        /**
         * Returns component initial data so we can reset it later if needed.
         * 
         * @returns {object}
         */
        initData() {
            return {
                wasValidated: false,
                id: null,
                workspaceType: null,
                firstName: null,
                lastName: null,
                dobDay: null,
                dobMonth: null,
                dobYear: null,
                dob: null,
                company: null,
                bic: null,
                address: null,
                postalCode: null,
                postOffice: null,
                country: null,
                countries: countries,
                invoiceType: null,
                eInvoiceAddress: null,
                email: null,
                reference: null
            };
        },
        /**
         * Resets component data to initial values.
         */
        resetData() {
            Object.assign(this.$data, this.initData());
        },
        /**
         * Sets workspace type specific data null if not used with current type.
         */
        workspaceTypeChange() {
            if (this.isPrivateWorkspace) {
                this.company = null;
                this.bic = null;
            } else {
                this.firstName = null;
                this.lastName = null;
                this.dobDay = null;
                this.dobMonth = null;
                this.dobYear = null;
                this.dob = null;
            }
        },
        /**
         * Sets invoice type specific data null if not used with current type.
         */
        invoiceTypeChange() {
             if (this.invoiceType == 'email') {
                this.eInvoiceAddress = null;
            } else if (this.invoiceType == 'einvoice') {
                this.email = null;
            }
        },
        /**
         * Sets dob value from dobDay, dobMonth and dobYear.
         */
        setDob() {
            this.dob = null;
            if (this.dobDay && this.dobMonth && this.dobYear) {
                this.dob = this.dobYear + '-' + this.dobMonth + '-' + this.dobDay;
            }
        },
        /**
         * Gets billing address for current user.
         */
        getDefaultBillingAddress() {
            store.commit('setLoading', true);
            if (this.billingAddresses && this.billingAddresses.length) {
                // Get the first billing address in array
                for (const [key, value] of Object.entries(this.billingAddresses[0])) {
                    // Skip userId since it's a computed property
                    if (key == 'userId' || key == 'workspaceType') {
                        continue;
                    } else if (key == 'dob' && value) {
                        // Set dob pieces
                        let date = new Date(value);
                        this.dobYear = date.getFullYear();
                        this.dobMonth = (date.getMonth() + 1).toString().padStart(2, '0');
                        this.dobDay = date.getDate().toString().padStart(2, '0');
                    }
                    // Populate this data from billing address object
                    this[key] = value;
                }
                // Get workspaceType from active workspace
                this.workspaceType = this.activeWorkspace.type;
            } else if (this.billingAddresses && this.billingAddresses.length == 0) {
                // No billing address, reset all data
                this.resetData();
            } else {
                // Billing addresses not loaded
                // Emit event to App to load billing addresses
                this.$emitter.emit('getBillingAddresses');
            }
            store.commit('setLoading', false);
        },
        /**
         * Checks billing info form validity and posts data to API if everything ok.
         * 
         * @param   {object}    e   Native MouseEvent object.
         */
        postBillingAddress(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            // Validate form
            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving billing info');

            let formData = new FormData(document.getElementById('billingInfoForm'));

            axios({
                method: 'post',
                url: '/api/billing-address',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    this.id = response.data.billingAddress.id;
                    this.$emitter.emit('getBillingAddresses');
                    this.$emitter.emit('getWorkspaces');
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
                if (this.modal) {
                    this.$emitter.emit('getBillingAddresses');
                    store.commit('setModal', null);
                }
            });
        },
        openConfirmDeleteModal() {
            store.commit('setModal', {
                component: 'DeleteBillingAddress',
                componentProps: {
                    id: this.id,
                    userId: this.userId
                }
            });
        }
    }
}
</script>

<style scoped>
.input-group-text.font-monospace {
    font-size: .85rem;
}
</style>
<template>
    <div class="bg-white p-3 my-sm-3 shadow-sm">
            
        <h1>{{ $i18n('LABEL_HELP_AND_SUPPORT') }}</h1>

        <Text id="HTML_HELP_AND_SUPPORT" />
        
    </div>

</template>

<script>
import Text from '../components/Text.vue';

export default {
    name: 'Help',
    components: {
        Text
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="bg-white p-3 my-sm-3 shadow-sm">

        <OverlaySpinner 
            v-if="$loading()"
            :showStatusText="true" 
            :statusText="$loadingStatus()"
        />

        <h1>{{ $i18n('LABEL_WORKSPACE_SETTINGS') }}</h1>
        
        <h2 class="d-md-none">{{ $i18n(tabs[activeTab]) }}</h2>
        
        <!-- TAB NAV -->
        <ul class="nav nav-tabs mb-3 d-none d-md-flex">
            <li 
                v-for="(tabLabel, tabKey) in tabs" 
                :key="tabKey"
                class="nav-item">
                <router-link :to="'/workspace/' + tabKey" class="nav-link" :class="{active: activeTab == tabKey}">{{ $i18n(tabLabel) }}</router-link>
            </li>
        </ul>

        <Alerts />

        <div class="tab-content">

            <!-- WORKSPACE INFO TAB CONTENT -->
            <div class="tab-pane fade" :class="{show: activeTab == 'workspace-info', active: activeTab == 'workspace-info'}">
                
                <form 
                    action="api/workspace" 
                    method="post"  
                    id="editWorkspaceForm"
                    @submit="editWorkspace($event)" 
                    autocomplete="off"
                    novalidate
                >

                    <div class="row mb-3">
                        <div class="col-lg-6">

                            <label for="name" class="form-label">{{ $i18n('LABEL_WORKSPACE_NAME') }}</label>
                            <div class="input-group">
                                <input v-model="workspaceName" type="text" id="name" name="name" class="form-control" :disabled="!isWorkspaceAdmin" required />
                                <button type="submit" class="btn btn-primary" :disabled="saveWorkspaceBtnDisabled">
                                    {{ $i18n('LABEL_SAVE') }}
                                </button>
                            </div>

                            <Text v-if="!isWorkspaceAdmin" id="TEXT_ONLY_ADMINS_CAN_CHANGE_WORKSPACE_NAME" class="form-text" />

                            <input type="hidden" name="id" :value="activeWorkspaceId" />
                            <input type="hidden" name="userId" :value="$user().id" />

                        </div>
                    </div> <!-- /.row -->

                    <div class="row mb-3" v-if="isWorkspaceAdmin">
                        <div class="col-lg-6">
                            
                            <label for="newImage" class="form-label">{{ $i18n('LABEL_WORKSPACE_IMAGE') }}</label>
                            <div class="input-group">
                                <input 
                                    @change="imageChange($event)"
                                    type="file" 
                                    class="form-control" 
                                    id="newImage" 
                                    name="newImage" 
                                    accept=".svg, .png, .jpg, .jpeg, .gif, image/x-png, image/gif, image/jpeg, image/svg+xml" 
                                />
                                <button type="submit" class="btn btn-primary" :disabled="!selectedImageFile">
                                    {{ $i18n('LABEL_SAVE') }}
                                </button>
                            </div>
                            <div class="form-text mb-3">
                                <Text id="TEXT_WORKSPACE_IMAGE" />
                            </div>

                        </div>

                        <div v-if="activeWorkspace.image" class="col-lg-6">
                            <label class="form-label">{{ $i18n('LABEL_CURRENT_IMAGE') }}</label>
                            <div class="d-flex align-items-center">
                                <div class="workspace-image-preview" :style="'background-image:url('+activeWorkspace.image+');'"></div>
                                <DeleteConfirmButton btnAddClasses="" deleteEventName="deleteImage" />
                            </div>
                        </div>
                    </div> <!-- /.row -->

                </form>

                <div v-if="activeWorkspace.userId==$user().id" class="mb-5">
                    <button @click="openDeleteWorkspace()" type="button" class="btn btn-danger" :disabled="deleteWorkspaceBtnDisabled">{{ $i18n('LABEL_DELETE_WORKSPACE') }}</button>
                    <Text v-if="deleteWorkspaceBtnDisabled" id="TEXT_CANNOT_DELETE_ONLY_WORKSPACE" class="form-text" />
                </div>

                <h2>{{ $i18n('LABEL_WORKSPACE_MEMBERS') }}</h2>

                <div class="workspace-members-container mb-5">
                    <OverlaySpinner v-if="! workspaceMembers || ! workspaceUserLevels" />

                    <div v-if="workspaceMembers && workspaceMembers.length" class="table-responsive">
                        <table class="table table-hover align-middle">
                            <thead class="bg-dark text-white">
                                <tr>
                                    <th scope="col">{{ $i18n('LABEL_NAME') }}</th>
                                    <th scope="col">{{ $i18n('LABEL_EMAIL') }}</th>
                                    <th scope="col">{{ $i18n('LABEL_USER_LEVEL') }}</th>
                                    <th scope="col">
                                        <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr 
                                    v-for="member in workspaceMembers"
                                    :key="member.id+'-'+member.firstName+'-'+member.lastName">
                                    <td>{{ member.firstName + ' ' + member.lastName }}</td>
                                    <td>{{ member.email }}</td>
                                    <td>{{ getMemberUserLevelName(member) }}</td>
                                    <td class="text-end shrink">
                                        <button type="button" @click="openEditMember(member)" class="btn btn-primary btn-sm" :disabled="editMemberBtnDisabled(member)">
                                            <BIconPencilFill />
                                            <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

                <section v-if="this.activeWorkspace.userLevel >= 1000" id="workspaceInvitations">

                    <h3>{{ $i18n('LABEL_INVITATIONS') }}</h3>

                    <div class="workspace-invitations-container mb-3">
                        <OverlaySpinner v-if="! invitations" />

                        <p v-if="invitations && ! invitations.length" class="text-muted">
                            <em>{{ $i18n('LABEL_NO_INVITATIONS') }}</em>
                        </p>

                        <div v-if="invitations && invitations.length" class="table-responsive">
                            <table class="table table-hover align-middle">
                                <thead class="bg-dark text-white">
                                    <tr>
                                        <th scope="col">{{ $i18n('LABEL_EMAIL') }}</th>
                                        <th scope="col">{{ $i18n('LABEL_INVITATION_SENT') }}</th>
                                        <th scope="col">{{ $i18n('LABEL_STATUS') }}</th>
                                        <th scope="col">
                                            <span class="visually-hidden">{{ $i18n('LABEL_ACTIONS') }}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="invitation in invitations"
                                        :key="invitation.id+'-'+invitation.email">
                                        <td>{{ invitation.email }}</td>
                                        <td>{{ $formatDate(invitation.created) }}</td>
                                        <td>{{ $i18n('LABEL_'+invitation.state) }}</td>
                                        <td class="text-end shrink">
                                            <DeleteConfirmButton 
                                                v-if="invitation.state=='PENDING'"
                                                :showIcon="false"
                                                :label="$i18n('LABEL_CANCEL_INVITATION')"
                                                :confirmDeleteLabel="$i18n('LABEL_CONFIRM_CANCELLATION')"
                                                deleteEventName="cancelInvitation" 
                                                :deleteEventPayload="invitation.id" 
                                            />
                                            <DeleteConfirmButton 
                                                v-if="invitation.state=='DECLINED'"
                                                deleteEventName="deleteInvitation" 
                                                :deleteEventPayload="invitation.id" 
                                            />
                                            <button 
                                                v-if="invitation.state=='PENDING' || invitation.state=='DECLINED'" 
                                                @click="resendInvitation(invitation.id)"
                                                type="button" 
                                                class="btn btn-primary btn-sm ms-2">
                                                {{ $i18n('LABEL_RESEND') }}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div> <!-- /.workspace-invitations-container -->

                    <div class="row mb-3">
                        <div class="col-lg-6">

                            <form 
                                action="api/workspace-user-invitation" 
                                method="post"  
                                id="inviteWorkspaceUserForm"
                                @submit="sendNewInvitation($event)" 
                                autocomplete="off"
                            >
                                
                                <label for="email" class="form-label">{{ $i18n('LABEL_INVITE_NEW_MEMBER') }}</label>
                                <div class="input-group">
                                    <input v-model="inviteUserEmail" type="email" id="email" name="email" class="form-control" :placeholder="$i18n('LABEL_EMAIL')" required />
                                    <button type="submit" class="btn btn-primary" :disabled="inviteUserBtnDisabled">{{ $i18n('LABEL_SEND_INVITATION') }}</button>
                                </div>

                                <input type="hidden" name="workspaceId" :value="activeWorkspaceId" />
                                <input type="hidden" name="userId" :value="$user().id" />

                            </form>

                        </div>
                    </div> <!-- /.row -->

                </section> <!-- /#workspaceInvitations -->

            </div>


            <!-- BILLING INFO TAB CONTENT -->
            <div class="tab-pane fade" :class="{show: activeTab == 'billing-info', active: activeTab == 'billing-info'}">
                
                <BillingInfoForm />

            </div>


            <!-- SUBSCRIPTIONS TAB CONTENT -->
            <div class="tab-pane fade" :class="{show: activeTab == 'subscriptions', active: activeTab == 'subscriptions'}">
                
                <Subscriptions />

            </div>

        </div> <!-- /.tab-content -->

    </div>
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconPencilFill } from 'bootstrap-icons-vue';
import Alerts from '../components/Alerts.vue';
import BillingInfoForm from '../components/BillingInfoForm.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import Subscriptions from '../components/Subscriptions.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import Text from '../components/Text.vue';

export default {
    name: 'Workspace',
    components: {
        Alerts,
        BIconPencilFill,
        BillingInfoForm,
        DeleteConfirmButton,
        Subscriptions,
        OverlaySpinner,
        Text
    },
    computed: {
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        isWorkspaceAdmin() {
            // Check if user is the owner of this workspace
            if (this.activeWorkspace.userId == store.state.user.id) {
                return true;
            }
            // Check if user has admin rights for this workspace
            if (this.activeWorkspace.userLevel >= 1000) {
                return true;
            }
            return false;
        },
        saveWorkspaceBtnDisabled() {
            return ! this.workspaceName || this.workspaceName == this.activeWorkspace.name;
        },
        deleteWorkspaceBtnDisabled() {
            let ownedWorkspaces = store.state.user.workspaces.filter(workspace => workspace.userId == store.state.user.id);
            // Can't delete only workspace owned by user
            return ownedWorkspaces.length < 2;
        },
        inviteUserBtnDisabled() {
            return ! this.inviteUserEmail || this.userExists;
        },
        userExists() {
            let match = false;
            if (this.workspaceMembers) {
                match = this.workspaceMembers.find(member => member.email == this.inviteUserEmail);
                if (match) {
                    return true;
                }
            }
            if (this.invitations) {
                match = this.invitations.find(invitation => invitation.email == this.inviteUserEmail);
                if (match) {
                    return true;
                }
            }
            return match;
        }
    },
    data() {
        return {
            tabs: {
                'workspace-info': 'LABEL_WORKSPACE_INFO',
                'billing-info': 'LABEL_BILLING_INFO',
                'subscriptions': 'LABEL_SUBSCRIPTIONS'
            },
            activeTab: 'workspace-info',
            workspaceName: null,
            selectedImageFile: null,
            workspaceMembers: null,
            workspaceUserLevels: null,
            inviteUserEmail: null,
            invitations: null
        }
    },
    watch: {
        $route() {
            if (this.$route.params.tab) {
                this.activeTab = this.$route.params.tab;
            }
        },
        activeWorkspaceId() {
            this.workspaceName = this.activeWorkspace.name;
            this.getWorkspaceUsers();
            this.getWorkspaceUserLevels();
            this.getInvitations();
        }
    },
    created() {
        // Event listener for deleteImage
        this.$emitter.on('deleteImage', () => {
            this.deleteImage();
        });

        // Event listener for cancelInvitation
        this.$emitter.on('cancelInvitation', (id) => {
            this.cancelInvitation(id);
        });

        // Event listener for deleteInvitation
        this.$emitter.on('deleteInvitation', (id) => {
            this.deleteInvitation(id);
        });

        // Event listener for getWorkspaceUsers
        this.$emitter.on('getWorkspaceUsers', () => {
            this.getWorkspaceUsers();
        });

        this.workspaceName = this.activeWorkspace.name;
        this.getWorkspaceUsers();
        this.getWorkspaceUserLevels();
        this.getInvitations();
    },
    mounted() {
        if (this.$route.params.tab) {
            this.activeTab = this.$route.params.tab;
        }
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('cancelInvitation');
        this.$emitter.all.delete('deleteInvitation');
        this.$emitter.all.delete('getWorkspaceUsers');
    },
    methods: {
        imageChange(e) {
            if (e.target.files[0]) {
                this.selectedImageFile = e.target.files[0].name;
                return;
            }
            this.selectedImageFile = null;
        },
        /**
         * Opens modal for workspace delete confirm.
         * 
         * @param   {object}    member
         */
        openDeleteWorkspace() {
            store.commit('setModal', {
                component: 'WorkspaceDeleteConfirm'
            });
        },
        editMemberBtnDisabled(member) {
            let disabled = false;

            if (member.id == this.activeWorkspace.userId) {
                // Can't edit owner
                disabled = true;
            } else if (this.activeWorkspace.userLevel < 1000) {
                // Only workspace admins can edit member user levels
                disabled = true;
            } 
            
            if (member.id == store.state.user.id && member.id != this.activeWorkspace.userId) {
                // You can always edit yourself if not the owner
                disabled = false;
            }

            return disabled;
        },
        getMemberUserLevelName(member) {
            // Check if member is the owner
            if (member.id == this.activeWorkspace.userId) {
                return this.$i18n('LABEL_OWNER');
            }
            // Not owner, get user level name from workspaceUserLevels
            if (this.workspaceUserLevels) {
                let userLevel = this.workspaceUserLevels.find(userLevel => userLevel.level == member.level);
                if (userLevel) {
                    return userLevel.name;
                }
            }
            return null;
        },
        /**
         * 
         */
        getWorkspaceUsers() {
            axios({
                method: 'get',
                url: '/api/workspace-users',
                params: {
                    workspaceId: this.activeWorkspaceId
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.workspaceUsers) {
                    // Put current lan texts in store texts
                    this.workspaceMembers = response.data.workspaceUsers;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * 
         */
        getWorkspaceUserLevels() {
            axios({
                method: 'get',
                url: '/api/workspace-user-levels',
                params: {
                    lan: store.state.lan
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.workspaceUserLevels) {
                    // Put current lan texts in store texts
                    this.workspaceUserLevels = response.data.workspaceUserLevels;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * 
         */
        getInvitations() {
            this.invitations = null;
            axios({
                method: 'get',
                url: '/api/workspace-user-invitations',
                params: {
                    workspaceId: this.activeWorkspaceId
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.invitations) {
                    // Put current lan texts in store texts
                    this.invitations = response.data.invitations;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * Opens modal for editing workspace member data.
         * 
         * @param   {object}    member
         */
        openEditMember(member) {
            store.commit('setModal', {
                component: 'WorkspaceEditMember',
                componentProps: {
                    initMember: member,
                    workspaceUserLevels: this.workspaceUserLevels
                },
                size: 'modal-lg'
            });
        },
        /**
         * @param   {Object}    e   Native MouseEvent object. 
         */
        editWorkspace(e) {
            e.preventDefault();
            e.stopPropagation();

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving');

            let formData = new FormData(document.getElementById('editWorkspaceForm'));
            this.postWorkspace(formData);
        },
        /**
         * 
         */
        deleteImage() {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting image');

            let formData = new FormData(document.getElementById('editWorkspaceForm'));
            formData.append('deleteImage', true);
            this.postWorkspace(formData);
        },
        /**
         * 
         */
        postWorkspace(formData) {
            axios({
                method: 'post',
                url: '/api/workspace',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.$emitter.emit('getWorkspaces');
                    document.getElementById('newImage').value = null;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * 
         */
        sendNewInvitation(e) {
            e.preventDefault();
            e.stopPropagation();

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Sending invitation');

            let formData = new FormData(document.getElementById('inviteWorkspaceUserForm'));
            this.inviteUserEmail = null;
            this.postWorkspaceUserInvitation(formData);
        },
        /**
         * 
         */
        cancelInvitation(invitationId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Cancelling invitation');

            let formData = new FormData();
            formData.append('id', invitationId);
            formData.append('userId', store.state.user.id);
            formData.append('workspaceId', this.activeWorkspaceId);
            formData.append('state', 'CANCELLED');

            this.postWorkspaceUserInvitation(formData);
        },
        /**
         * 
         */
        resendInvitation(invitationId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Resending invitation');

            let formData = new FormData();
            formData.append('id', invitationId);
            formData.append('userId', store.state.user.id);
            formData.append('workspaceId', this.activeWorkspaceId);
            formData.append('state', 'PENDING');

            this.postWorkspaceUserInvitation(formData);
        },
        /**
         * 
         */
        deleteInvitation(invitationId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting invitation');

            let formData = new FormData();
            formData.append('id', invitationId);
            formData.append('userId', store.state.user.id);
            formData.append('workspaceId', this.activeWorkspaceId);
            formData.append('delete', true);

            this.postWorkspaceUserInvitation(formData);
        },
        /**
         * Posts workspace user invitation to API.
         * 
         * @param   object  formData
         */
        postWorkspaceUserInvitation(formData) {
            axios({
                method: 'post',
                url: '/api/workspace-user-invitation',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getInvitations();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>
.workspace-members-container,
.workspace-invitations-container  {
    position: relative;
    min-height: 4rem;
}
.workspace-image-preview {
    width: 64px;
    height: 64px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    margin-right: .5rem;
}
</style>
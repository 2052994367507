<template>

    <div v-if="sites.length > 1" class="mb-3">
        <div class="input-group">
            <label for="sites" class="input-group-text">{{ $i18n('LABEL_SITES') }}</label>
            <select v-model="siteUrl" id="sites" name="sites" class="form-select">
                <option 
                    v-for="(site) in sites"
                    :key="site.id"
                    :value="site.url">
                        {{ site.url }}
                </option>
            </select>
        </div>
    </div>

    <div v-if="siteUrl" class="mb-3">
        <InputSuggestions 
            @select="sitePageSelect"
            :sourceUrl="siteSuggestionsSourceUrl" 
            :placeholder="'Type to search pages from ' + siteUrlDomain"
        />
    </div>
    
    <div class="row">

        <div class="col-lg-6 mb-3">
            <label for="title" class="form-label">* {{ $i18n('LABEL_TITLE') }}</label>
            <input type="text" id="title" name="title" class="form-control" v-model="item.title" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_FIELD_REQUIRED') }}
            </div>
        </div>

        <div class="col-lg-6 mb-3">
            <label for="url" class="form-label">{{ $i18n('LABEL_LINK_URL') }}</label>
            <input type="text" id="url" name="url" class="form-control" v-model="item.url" />
        </div>

        <div class="col-lg-6 mb-3">
            <label for="image" class="form-label">{{ $i18n('LABEL_IMAGE_URL') }}</label>
            <input type="text" id="image" name="image" class="form-control" v-model="item.image" />
            <button type="button" @click="removeImage()" v-if="item.image" class="btn btn-danger btn-icon-text mt-3">
                <BIconTrash /> {{ $i18n('LABEL_REMOVE_IMAGE') }}
            </button>
        </div>

        <div v-if="! item.image" class="col-lg-6 mb-3">
            <label for="imageFile" class="form-label">{{ $i18n('LABEL_UPLOAD_IMAGE') }}</label>
            <div class="form-file">
                <input 
                    type="file" 
                    @change="imageChange($event)" 
                    class="form-control" 
                    id="imageFile" 
                    name="imageFile" 
                    accept=".svg, .png, .jpg, .jpeg, .gif, image/x-png, image/gif, image/jpeg, image/svg+xml"
                />
                <label v-if="selectedFileText" class="form-file-label" for="imageFile">
                    <span class="form-file-text">{{ selectedFileText }}</span>
                </label>
            </div>
            <div class="form-text">
                Image is used in the map info window and as the article main image. Supported formats are SVG, PNG, JPG and GIF. Recommended minimum width for bitmap formats is 1920 px. Landscape images are preferred over portait images.
            </div>
        </div>

        <div class="col-lg-6 mb-3">
            <img v-if="item.image" :src="item.image" :alt="item.title" class="mt-3" />
        </div>

        <div class="col-lg-12 mb-3">
            <label for="description" class="form-label">{{ $i18n('LABEL_DESCRIPTION') }}</label>
            <textarea id="description" name="description" class="form-control" rows="5" cols="50" maxlength="255" v-model="item.description"></textarea>
            <div class="form-text">
                Description max length is 255 characters<span v-if="item.description"> ({{ item.description.length }}) and it is shown in the map info window.</span>.
            </div>
        </div>

    </div> <!-- /.row -->

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconTrash } from 'bootstrap-icons-vue';
import InputSuggestions from '../components/InputSuggestions.vue';

export default {
    name: 'ItemEditBasicInfo',
    components: {
        BIconTrash,
        InputSuggestions
    },
    props: {
        modelValue: {
            type: Object
        }
    },
    data() {
        return {
            item: this.modelValue,
            sites: [],
            siteUrl: null,
            selectedFileText: null
        }
    },
    computed: {
        /**
         * Returns site url domain without protocol or www.
         */
        siteUrlDomain() {
            return this.siteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
        },
        siteSuggestionsSourceUrl() {
            return this.siteUrl + '/wp-json/wp/v2/search?search={{term}}';
        }
    },
    created() {
        this.getSites();
    },
    mounted() {
        
    },
    methods: {
        /**
         * Gets all user's sites from the db and sets them to this.sites.
         */
        getSites() {
            store.commit('setLoading', true);
            axios({
                method: 'get',
                url: 'api/sites',
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setMessages', response.data.messages);
                    }
                    if (response.data.sites.length) {
                        this.sites = response.data.sites;
                        this.siteUrl = this.sites[0].url;
                    } else {
                        this.sites = [];
                    }
                } else if (response.data.errors) {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Handles the select event emitted from site page search InputSuggestions component.
         * 
         * @param   {object}    wpItem    WordPress API search result item.
         */
        sitePageSelect(wpItem) {
            store.commit('setLoading', true);

            // Get page from WP API by subtype, post from endpoint posts etc. 
            let url = this.siteUrl + '/wp-json/wp/v2/' + wpItem.subtype + 's/' + wpItem.id;

            axios.get(url)
            .then((response) => {
                this.setItemData(response.data);
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading page data failed');
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Populates item object data with WordPress wpItem data.
         * 
         * @param   {object}    wpItem    WordPress API page/post item.
         */
        setItemData(wpItem) {
            //console.log(wpItem);
            this.item.title = wpItem.title.rendered;
            this.item.url = wpItem.link;

            // Strip tags from excerpt
            this.item.description = wpItem.excerpt.rendered.replace(/(<([^>]+)>)/gi, '');

            // Set page content to item text if exists
            if (wpItem.content.rendered) {
                this.item.text = wpItem.content.rendered;
            }

            // Get image url if featured media is defined
            if (wpItem.featured_media) {
                axios.get(wpItem._links['wp:featuredmedia'][0].href)
                .then((response) => {
                    this.item.image = response.data.guid.rendered;
                })
                .catch((error) => {
                    store.commit('addModalError', 'Loading page featured image failed');
                    console.error(error);
                })
                .then(() => {
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        imageChange(e) {
            if (e.target.files[0]) {
                this.selectedFileText = e.target.files[0].name;
                return;
            }
            this.selectedFileText = null;
        },
        removeImage() {
            this.item.image = null;
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n(modalTitle) }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body d-flex flex-column">

        <OverlaySpinner v-if="$loading()" />

        <!-- TAB NAV -->
        <ul class="nav nav-tabs mb-3" id="editPlaceTabList" role="tablist">
            
            <li 
                v-for="(tab, index) in tabs" 
                :key="tab.name"
                class="nav-item" 
                role="presentation">
                <a 
                    @click="setActiveTab(tab.name)"
                    :href="'#' + tab.name + 'Content'" 
                    :id="tab.name" 
                    class="nav-link" 
                    :class="{active: tab.name == activeTab, 'disabled': ! tabIsVisited(index)}"
                    data-bs-toggle="tab" 
                    role="tab" 
                    :tabindex="tabIndex(index)"
                    :aria-controls="tab.name+'Content'" 
                    :aria-selected="tab.name == activeTab"
                    :aria-disabled="! tabIsVisited(index)">
                        {{ $i18n(tab.label) }}
                    </a>
            </li>

        </ul>

        <form 
            v-if="!$loading()"
            id="editItemForm"
            :action="'api/'+itemType" 
            method="post" 
            class="needs-validation flex-grow-1 d-flex flex-column" 
            :class="{'was-validated': wasValidated}"
            @submit="postEditItem($event)" 
            enctype="multipart/form-data" 
            autocomplete="off"
            novalidate>

            <div id="editPlaceTabContent" class="tab-content flex-grow-1 d-flex flex-column">

                <!-- BASIC INFO TAB CONTENT -->
                <div class="tab-pane fade show active" id="itemBasicInfoTabContent" role="tabpanel" aria-labelledby="itemBasicInfoTab">
                    
                    <ItemEditBasicInfo v-model="item" />
                
                </div> <!-- /#itemBasicInfoTabContent -->



                <!-- LOCATION TAB CONTENT -->
                <div class="tab-pane fade" id="itemMapTabContent" role="tabpanel" aria-labelledby="itemMapTab">

                    <!-- MAP FOR PLACE -->
                    <div v-if="itemType=='place'">

                        <ItemEditMap mode="marker" :latitude="item.latitude" :longitude="item.longitude" />

                        <div class="row">

                            <div class="col-lg-6 mb-3">
                                <label for="latitude" class="form-label visually-hidden">Latitude</label>
                                <label for="longitude" class="form-label visually-hidden">Longitude</label>
                                <label class="form-label">* {{ $i18n('LABEL_LOCATION') }}</label>
                                <div class="input-group">
                                    <input type="number" id="latitude" name="latitude" class="form-control" v-model.number="item.latitude" step="any" placeholder="Latitude" required />
                                    <input type="number" id="longitude" name="longitude" class="form-control" v-model.number="item.longitude" step="any" placeholder="Longitude" required />
                                </div>
                            </div>

                        </div> <!-- /.row -->
                    </div>

                    <!-- MAP FOR ROUTE -->
                    <div v-if="itemType=='route'">

                        <ItemEditMap mode="route" :waypoints="item.waypoints" />

                        <input type="text" id="waypoints" name="waypoints" class="form-control visually-hidden" :value="waypointsString" :required="!gpxFile" />
                        <div class="invalid-feedback mb-3">
                            {{ $i18n('LABEL_ROUTE_REQUIRED') }}
                        </div>

                        <div class="mb-3">
                            <label for="gpxFile" class="form-label">{{ $i18n('LABEL_UPLOAD_GPX_FILE') }}</label>
                            <div class="form-file">
                                <input 
                                    @change="gpxFileSelected($event)"
                                    type="file" 
                                    class="form-control" 
                                    id="gpxFile" 
                                    name="gpxFile" 
                                    accept=".gpx, application/gpx+xml"
                                />
                            </div>
                        </div>

                    </div>

                </div> <!-- /#itemMapTabContent -->

                

                <!-- CATEGORIES TAB CONTENT -->
                <div class="tab-pane fade" :class="{'flex-grow-1 d-flex flex-column': activeTab=='itemCategoriesTab'}" id="itemCategoriesTabContent" role="tabpanel" aria-labelledby="itemCategoriesTab">
                    
                    <ItemEditCategories :categories="item.categories" :itemLan="item.lan" />

                </div> <!-- /#itemCategoriesTabContent -->



                <!-- ARTICLE TAB CONTENT -->
                <div class="tab-pane fade" id="itemArticleTabContent" role="tabpanel" aria-labelledby="itemArticleTab">
                    
                    <ItemEditArticle v-model="item" />

                </div> <!-- /#itemArticleTabContent -->



                <!-- SETTINGS TAB CONTENT -->
                <div class="tab-pane fade" id="itemSettingsTabContent" role="tabpanel" aria-labelledby="itemSettingsTab">

                    <ItemEditSettings v-model="item" />

                </div> <!-- /#itemSettingsTabContent -->



                <!-- TRANSLATIONS TAB CONTENT -->
                <div class="tab-pane fade" :class="{'flex-grow-1 d-flex flex-column': activeTab=='itemTranslationsTab'}" id="itemTranslationsTabContent" role="tabpanel" aria-labelledby="itemTranslationsTab">
                    
                    <ItemEditTranslations :itemLan="item.lan" :placeId="place.id" :routeId="route.id" />

                </div> <!-- /#itemTranslationsTabContent -->


                <input v-if="item.id" type="hidden" id="id" name="id" v-model="item.id" />
                <button type="submit" id="saveItemBtn" class="visually-hidden">{{ $i18n('LABEL_SAVE') }}</button>

            </div> <!-- /#editPlaceTabContent -->

        </form>

    </div> <!-- /.modal-body -->
    
    <div v-if="activeTab!='itemTranslationsTab'" class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="nextPhase()" v-if="phase < (tabs.length-1)" :disabled="continueBtnDisabled()" class="btn btn-primary me-2">{{ $i18n('LABEL_CONTINUE') }}</button>
        <button type="button" @click="submit()" v-if="phase == (tabs.length-1)" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import ItemEditArticle from '../components/ItemEditArticle.vue';
import ItemEditBasicInfo from '../components/ItemEditBasicInfo.vue';
import ItemEditCategories from '../components/ItemEditCategories.vue';
import ItemEditMap from '../components/ItemEditMap.vue';
import ItemEditSettings from '../components/ItemEditSettings.vue';
import ItemEditTranslations from '../components/ItemEditTranslations.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'ItemEdit',
    components: {
        ItemEditArticle,
        ItemEditBasicInfo,
        ItemEditCategories,
        ItemEditMap,
        ItemEditSettings,
        ItemEditTranslations,
        OverlaySpinner
    },
    props: {
        /**
         * Item type.
         * @values place, route
         */
        itemType: {
            type: String,
            required: true,
            validator: function(value) {
                // Valid values for itemType
                return ['place', 'route'].indexOf(value) !== -1;
            }
        },
        /**
         * Default values for creating a new place.
         */
        initPlace: {
            type: Object,
            default: function() {
                return {
                    latitude: store.state.defaultLatitude,
                    longitude: store.state.defaultLongitude,
                    categories: [],
                    lan: store.state.lan,
                    status: true,
                    public: false
                }
            }
        },
        /**
         * Default values for creating a new route.
         */
        initRoute: {
            type: Object,
            default: function() {
                return {
                    waypoints: null,
                    categories: [],
                    lan: store.state.lan,
                    status: true,
                    public: false
                }
            }
        }
    },
    data() {
        return {
            tabs: [
                {
                    name: 'itemBasicInfoTab',
                    label: 'LABEL_BASIC_INFO'
                },
                {
                    name: 'itemMapTab',
                    label: this.mapTabLabel()
                },
                {
                    name: 'itemCategoriesTab',
                    label: 'LABEL_CATEGORIES'
                },
                {
                    name: 'itemArticleTab',
                    label: 'LABEL_ARTICLE'
                },
                {
                    name: 'itemSettingsTab',
                    label: 'LABEL_SETTINGS'
                }
            ],
            activeTab: null,
            phase: null,
            place: this.initPlace,
            route: this.initRoute,
            gpxFile: null,
            wasValidated: false
        }
    },
    computed: {
        modalTitle() {
            if (this.itemType == 'place') {
                if (this.place.id) {
                    return 'LABEL_EDIT_PLACE';
                }
                return 'LABEL_ADD_NEW_PLACE';
            } else if (this.itemType == 'route') {
                if (this.route.id) {
                    return 'LABEL_EDIT_ROUTE';
                }
                return 'LABEL_ADD_NEW_ROUTE';
            }
            return null;
        },
        /**
         * Return correct item object depending on this.itemType.
         */
        item() {
            if (this.itemType == 'place') {
                return this.place;
            } else if (this.itemType == 'route') {
                return this.route;
            }
            return null;
        },
        categoryIds() {
            let ids = this.item.categories.map(cat => cat.id);
            return ids.join(',');
        },
        waypointsString() {
            if (this.item.waypoints) {
                return JSON.stringify(this.item.waypoints);
            }
            return null;
        },
        mapTabValidateFields() {
            if (this.itemType == 'place') {
                return ['#latitude', '#longitude'];
            } else if (this.itemType == 'route' && ! this.gpxFile) {
                return ['#waypoints'];
            }
            return null;
        },
        locationsDbCategories() {
            return store.state.locationsDbCategories;
        },
        customCategories() {
            return store.state.customCategories;
        }
    },
    created() {
        store.commit('setLoading', true);

        // Event emitted from ItemEditMap to set place location
        this.$emitter.on('setPlaceLocation', (location) => {
            this.place.latitude = location.lat;
            this.place.longitude = location.lng;
        });

        // Event emitted from ItemEditMap to set route waypoints
        this.$emitter.on('setRouteWaypoints', (waypoints) => {
            this.route.waypoints = waypoints;
        });

        this.$emitter.on('addItemCategory', (cat) => {
            this.item.categories.push(cat);
        });

        this.$emitter.on('removeItemCategory', (catId) => {
            this.item.categories = this.item.categories.filter(category => category.id != catId);
        });

        // Set activeTab and phases always on created
        this.activeTab = 'itemBasicInfoTab';
        this.phase = 0;

        if (this.item.id) {
            // Add translation tab if editing existing item
            this.tabs.push({
                name: 'itemTranslationsTab',
                label: 'LABEL_TRANSLATIONS'
            });
            // All phases done if editing existing item
            this.phase = this.tabs.length - 1;
        }
    },
    mounted() {
        // Load item full data and post editing if editing existing
        if (this.item.id) {
            // Set editing data
            let editingData = {
                workspaceId: store.state.user.activeWorkspaceId,
                userId: store.state.user.id
            };

            if (this.itemType == 'place') {
                editingData.placeId = this.item.id;
            } else if (this.itemType == 'route') {
                editingData.routeId = this.item.id;
            }
            // Post editing to DB
            this.$emitter.emit('postEditing', editingData);

            this.getItem(this.item.id);
        } else {
            store.commit('setLoading', false);
        }
    },
    beforeUnmount() {
        if (this.item.id) {
            let editingData = {
                workspaceId: store.state.user.activeWorkspaceId,
                userId: store.state.user.id,
                delete: true
            };

            if (this.itemType == 'place') {
                editingData.placeId = this.item.id;
            } else if (this.itemType == 'route') {
                editingData.routeId = this.item.id;
            }
            
            this.$emitter.emit('endItemEditing', editingData);
        }
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('setPlaceLocation');
        this.$emitter.all.delete('setRouteWaypoints');
        this.$emitter.all.delete('addItemCategory');
        this.$emitter.all.delete('removeItemCategory');
    },
    methods: {
        setActiveTab(tabName) {
            this.activeTab = tabName;
            if (this.activeTab == 'itemMapTab') {
                this.$emitter.emit('adminMapInvalidateSize');
            }
        },
        tabIsVisited(tabIndex) {
            return this.phase >= tabIndex;
        },
        /**
         * Returns -1 for tabindex attribute if the tab is disabled.
         */
        tabIndex(tabIndex) {
            if (this.phase >= tabIndex) {
                return null;
            } else {
                return '-1';
            }
        },
        /**
         * Return correct label depending on this.itemType.
         * This won't work as a computed property :/
         * 
         * @returns {string}
         */
        mapTabLabel() {
            if (this.itemType == 'place') {
                return 'LABEL_LOCATION';
            } else if (this.itemType == 'route') {
                return 'LABEL_ROUTE';
            }
            return null;
        },
        gpxFileSelected(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (files.length) {
                this.gpxFile = files[0];
            } else {
                this.gpxFile = null;
            }
            console.log(this.gpxFile);
        },
        continueBtnDisabled() {
            if (this.phase == 0) {
                return ! this.item.title;
            } if (this.phase == 1) {
                if (this.itemType == 'place') {
                    return ! this.item.latitude && ! this.item.longitude;
                } else if (this.itemType == 'route') {
                    return ! this.item.waypoints && ! this.gpxFile;
                }
            }
            return false;
        },
        /**
         * Validates current phase form elements and moves to next phase if all ok.
         */
        nextPhase() {
            let isValid = true;

            // Elements to validate for each phase
            let validateEls = {
                0: ['#title'],
                1: this.mapTabValidateFields
            };
            
            // Loop through phase elements to validate
            if (validateEls[this.phase]) {
                validateEls[this.phase].forEach((elSel) => {
                    if (! document.querySelector(elSel).checkValidity()) {
                        isValid = false;
                    }
                });
            }
            
            // Route waypoints is required but it's in a hidden field, check it manually
            if (this.phase == 1 && this.itemType == 'route' && ! this.gpxFile && (! this.route.waypoints || ! this.route.waypoints.length)) {
                isValid = false;
            }

            // If phase elements are valid, move to next phase
            if (isValid) {
                this.wasValidated = false;
                this.phase++;
                // Trigger click to change the active tab
                let triggerEl = document.querySelector('#' + this.tabs[this.phase].name);
                triggerEl.classList.remove('disabled');
                triggerEl.click();
            } else {
                this.wasValidated = true;
            }
        },
        /**
         * Closes modal.
         */
        cancel() {
            store.commit('setModal', null);
            this.$emitter.emit('getItems');
        },
        /**
         * Calls form submit; this is because the form submit button is not inside the form element due to Bootstrap modal structure (buttons in modal footer).
         */
        submit() {
            //document.getElementById('editItemForm').requestSubmit();
            document.getElementById('saveItemBtn').click();
        },
        /**
         * Load item full data.
         */
        getItem(itemId) {
            store.commit('setLoading', true);

            axios.get('/api/' + this.itemType + 's/' + itemId, {
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                if (response.data.place) {
                    this.place = response.data.place;
                } else if (response.data.route) {
                    this.route = response.data.route;
                }
                if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Validates and posts edit item form data.
         */
        postEditItem(e) {
            e.preventDefault();
            e.stopPropagation();

            // If trying to post by pressing enter when creating a new item
            if (this.phase < (this.tabs.length-1)) {
                this.nextPhase();
                return;
            }

            this.wasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            }

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving');

            // Trigger TinyMCE save to update textarea contents before posting the form
            window['tinymce'].triggerSave();

            let formData = new FormData(document.getElementById('editItemForm'));

            formData.append('workspaceId', store.state.user.activeWorkspaceId);
            formData.append('categories', this.categoryIds);

            axios({
                method: 'post',
                url: '/api/' + this.itemType,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.$emitter.emit('getItems');
                    store.commit('setModal', null);
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
            });
        }
    }
}
</script>

<style scoped>
.form-control[type='number'] {
    -moz-appearance:textfield;
}

.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>

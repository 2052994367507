<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_EDIT_USER') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <OverlaySpinner v-if="$loading()" />

            <form 
                v-if="user"
                action="api/user" 
                method="post" 
                id="userEditForm"
                class="needs-validation" 
                :class="{'was-validated': wasValidated}"
                @submit="postUser($event)" 
                novalidate>

                <p class="small">
                    {{ $i18n('LABEL_ACCOUNT_CREATED') }}: {{ user.created }}<br />
                    {{ $i18n('LABEL_LAST_MODIFIED') }}: {{ user.modified }}
                </p>

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="firstName" class="form-label">{{ $i18n('LABEL_FIRST_NAME') }}</label>
                            <input type="text" id="firstName" name="firstName" v-model="user.firstName" class="form-control" required />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="lastName" class="form-label">{{ $i18n('LABEL_LAST_NAME') }}</label>
                            <input type="text" id="lastName" name="lastName" v-model="user.lastName" class="form-control" required />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="email" class="form-label">{{ $i18n('LABEL_EMAIL') }}</label>
                            <input type="email" id="email" name="email" v-model="user.email" class="form-control" required />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="level" class="form-label">{{ $i18n('LABEL_USER_LEVEL') }}</label>
                            <select id="level" name="level" v-model="user.level" class="form-select" required>
                                <option v-for="userLevel in currentLanUserLevels" :key="userLevel.level" :value="userLevel.level">
                                    {{ userLevel.name  }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div> <!-- /.row -->

                <input type="hidden" name="id" :value="id" />
                <button type="submit" id="saveTextBtn" class="visually-hidden">{{ $i18n('LABEL_SAVE') }}</button>

            </form>

        </div> <!-- /.modal-body -->
    
    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="submit()" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'AdminUsersEdit',
    components: {
        OverlaySpinner
    },
    props: {
        id: Number,
        userLevels: Array
    },
    computed: {
        currentLanUserLevels() {
            if (this.userLevels) {
                // Get user levels with current language
                return this.userLevels.filter((item) => item.lan == store.state.lan);
            }
            return null;
        }
    },
    data() {
        return {
            user: null,
            wasValidated: false
        }
    },
    created() {
        this.getUser(this.id);
    },
    mounted() {
        
    },
    methods: {
        /**
         * Gets user with desired id from DB via API.
         * 
         * @param   {string}    id
         */
        getUser(id) {
            store.commit('setLoading', true);
            axios({
                method: 'get',
                url: '/api/users/' + id
            })
            .then((response) => {
                //console.log(response);
                if (response.data.user) {
                    this.user = response.data.user;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Closes modal.
         */
        cancel() {
            store.commit('setModal', null);
        },
        /**
         * Calls form submit; this is because the form submit button is not inside the form element due to Bootstrap modal structure (buttons in modal footer).
         */
        submit() {
            //document.getElementById('textEditForm').requestSubmit();
            document.getElementById('saveTextBtn').click();
        },
        /**
         * Checks text edit form validity and posts data to API if everything ok.
         * 
         * @param   {object}    e   Native MouseEvent object.
         */
        postUser(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            // Validate form
            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving user data');

            let formData = new FormData(document.getElementById('userEditForm'));

            axios({
                method: 'post',
                url: '/api/user',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    this.$emitter.emit('getUsers');
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
                store.commit('setModal', null);
            });
        }
    }
}
</script>

<style scoped>

</style>

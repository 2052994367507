<template>

    <div v-if="! $route.params.id">

        <!-- button class="btn btn-primary mt-3 mb-5">Add new user</button -->

        <div class="row">
            <div class="col-6 mb-3">
                <label for="textsFilter" class="form-label visually-hidden">{{ $i18n('LABEL_FILTER') }}</label>
                <input v-model="filterStr" type="text" id="usersFilter" class="form-control" :placeholder="$i18n('LABEL_FILTER_USERS_LIST_BY_TYPING')" />
            </div>
        </div>

        <table class="table table-striped table-hover table-sm align-middle">
            <thead class="bg-dark text-white">
                <tr>
                    <th class="text-end shrink">{{ $i18n('LABEL_ID') }}</th>
                    <th>{{ $i18n('LABEL_EMAIL') }}</th>
                    <th>{{ $i18n('LABEL_NAME') }}</th>
                    <th>{{ $i18n('LABEL_USER_LEVEL') }}</th>
                    <th class="text-end shrink"> </th>
                    <th class="text-end shrink"> </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                v-for="user in users" 
                v-show="showUser(user, getUserLevel(user.level))"
                :key="user.id">
                    <td class="text-end shrink">
                        {{ user.id }}
                    </td>
                    <td>
                        {{ user.email }}
                    </td>
                    <td>
                        {{ user.firstName + ' ' + user.lastName }}
                    </td>
                    <td>
                        {{ getUserLevelName(user.level) }}
                    </td>
                    <td class="text-end shrink">
                        <DeleteConfirmButton deleteEventName ="deleteUser" :deleteEventPayload="user.id" />
                    </td>
                    <td class="text-end shrink">
                        <button @click="openEditUser(user.id)" class="btn btn-primary btn-sm">
                            <BIconPencilFill />
                            <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <router-view />

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconPencilFill } from 'bootstrap-icons-vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';

export default {
    name: 'AdminTexts',
    components: {
        BIconPencilFill,
        DeleteConfirmButton
    },
    data() {
        return {
            users: [],
            userLevels: [],
            filterStr: null
        }
    },
    created() {
        this.$emitter.on('getUsers', () => {
            this.getUsers();
        });

        this.$emitter.on('deleteUser', (id) => {
            console.log('should delete ' + id);
        });

        this.getUsers();
    },
    mounted() {
        
    },
    methods: {
        getUsers() {
            store.commit('setLoading', true);
            axios({
                method: 'get',
                url: '/api/users'
            })
            .then((response) => {
                //console.log(response);
                if (response.data.users) {
                    this.users = response.data.users;
                    if (response.data.userLevels) {
                        this.userLevels = response.data.userLevels;
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        getUserLevel(level) {
            // Get user level object with current language
            return this.userLevels.find((item) => item.level == level && item.lan == store.state.lan);
        },
        getUserLevelName(level) {
            // Get user level name with current language
            let userLevel = this.getUserLevel(level);
            if (userLevel) {
                return userLevel.name;
            } else {
                // No match found in current language, fallback to English
                userLevel = this.userLevels.find((item) => item.level == level && item.lan == 'en');
            }
            if (userLevel) {
                return userLevel.name;
            }
            return null;
        },
        openEditUser(userId) {
            store.commit('setModal', {
                component: 'AdminUsersEdit',
                componentProps: {
                    id: userId,
                    userLevels: this.userLevels
                },
                size: 'modal-lg',
                fullHeight: false
            });
        },
        showUser(user, userLevel) {
            let show = false;
            // No filter, show all
            if (! this.filterStr) {
                return true;
            }
            // Loop through user object property values
            Object.values(user).forEach((value) => {
                // Show user if we have a match in any property value
                if (value) {
                    value = value.toString();
                    if (value.toLowerCase().search(this.filterStr.toLowerCase()) != -1) {
                        show = true;
                    }
                }
            });
            // Check for match in user level name
            if (userLevel && userLevel.name) {
                if (userLevel.name.toLowerCase().search(this.filterStr.toLowerCase()) != -1) {
                    show = true;
                }
            }
            return show;
        }
    }
}
</script>

<style scoped>

</style>

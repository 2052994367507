<template>
    <OverlaySpinner 
        v-if="$loading()"
        :showStatusText="true" 
        :statusText="$loadingStatus()"
    />
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import OverlaySpinner from '../components/OverlaySpinner.vue'

export default {
    name: 'Logout',
    components: {
        OverlaySpinner
    },
    data() {
        return {
            
        }
    },
    mounted() {
        store.commit('setLoading', true);
        store.commit('setLoadingStatus', 'Logging out');
        store.commit('clearErrors');
        store.commit('clearMessages');

        axios({
            method: 'post',
            url: 'api/logout'
        })
        .then((response) => {
            if (response.data.status == 'SUCCESS') {
                this.$emitter.emit('logout');
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }
}
</script>

<style scoped>

</style>

<template>

    <div v-if="isWorkspaceAdmin">

        <div v-if="!subscriptionsAndProductsLoaded" class="position-relative py-5">
            <OverlaySpinner />
        </div>

        <h2 class="h3">{{ $i18n('LABEL_ACTIVE_SUBSCRIPTIONS') }}</h2>

        <p v-if="subscriptionsAndProductsLoaded && ! activeBasicSubscriptions.length">
            {{ $i18n('LABEL_NO_SUBSCRIPTIONS') }}
        </p>

        <!-- ACTIVE SUBSCRIPTIONS -->
        <SubscriptionsTable 
            v-if="subscriptionsAndProductsLoaded && activeBasicSubscriptions.length" 
            :subscriptions="subscriptions" 
        />

        <!-- ENDED SUBSCRIPTIONS -->
        <div v-if="subscriptionsAndProductsLoaded && endedSubscriptions.length">

            <div v-if="showEndedSubscriptions" class="mt-5">

                <h2 class="h3">{{ $i18n('LABEL_ENDED_SUBSCRIPTIONS') }}</h2>

                <SubscriptionsTable 
                    :subscriptions="subscriptions" 
                    mode="ended"
                />

            </div>

            <button @click="toggleEndedSubscriptions()" type="button" class="btn btn-primary">
                <span v-if="!showEndedSubscriptions">{{ $i18n('LABEL_SHOW_ENDED_SUBSCRIPTIONS') }}</span>
                <span v-if="showEndedSubscriptions">{{ $i18n('LABEL_HIDE_ENDED_SUBSCRIPTIONS') }}</span>
            </button>

        </div>

    </div>

    <Text v-if="!isWorkspaceAdmin" :id="'TEXT_ONLY_ADMINS_CAN_CHANGE_WORKSPACE_SUBSCRIPTIONS'" class="form-text" />

</template>

<script>
import store from '../store/store.js';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import SubscriptionsTable from '../components/SubscriptionsTable.vue';
import Text from '../components/Text.vue';

export default {
    name: 'Subscriptions',
    components: {
        OverlaySpinner,
        SubscriptionsTable,
        Text
    },
    data() {
        return {
            showEndedSubscriptions: false
        }
    },
    computed: {
        subscriptionsAndProductsLoaded() {
            return store.state.subscriptions && store.state.products && store.state.products.length;
        },
        subscriptions() {
            return store.state.subscriptions;
        },
        activeBasicSubscriptions() {
            if (store.state.subscriptions) {
                return store.state.subscriptions.filter(item => !item.parentId && item.ended == null)
            }
            return null;
        },
        endedSubscriptions() {
            if (store.state.subscriptions) {
                return store.state.subscriptions.filter(item => item.ended != null)
            }
            return null;
        },
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        isWorkspaceAdmin() {
            // Check if user is the owner of this workspace
            if (this.activeWorkspace.userId == store.state.user.id) {
                return true;
            }
            // Check if user has admin rights for this workspace
            if (this.activeWorkspace.userLevel >= 1000) {
                return true;
            }
            return false;
        }
    },
    watch:{
        
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        toggleEndedSubscriptions() {
            this.showEndedSubscriptions = !this.showEndedSubscriptions;
        }
    }
}
</script>

<style scoped>
.icon-warning {
    margin-top: -4px;
}
.additional-subscription::before {
    content: '+';
    margin-right: .5rem;
}
</style>
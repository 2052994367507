<template>

    <h2 class="mt-3">{{ $i18n('LABEL_SHOPPING_CART') }}</h2>

    <div v-if="! cart.length">
        <p>{{ $i18n('TEXT_SHOPPING_CART_EMPTY') }}</p>
        <p><router-link to="/shop">{{ $i18n('LABEL_BACK_TO_SHOP_MAIN') }}</router-link> </p>
    </div>

    <div v-if="cart.length">

        <ShopCartTable />

        <button @click="openConfirmEmptyModal()" type="button" class="btn btn-danger me-2">{{ $i18n('LABEL_EMPTY_CART') }}</button>
        <router-link to="/shop/checkout" class="btn btn-primary">{{ $i18n('LABEL_CHECKOUT') }}</router-link>

    </div>

</template>

<script>
import store from '../store/store.js';
import ShopCartTable from '../components/ShopCartTable.vue';

export default {
    name: 'ShopCart',
    components: {
        ShopCartTable
    },
    data() {
        return {
            
        }
    },
    computed: {
        cart() {
            return store.state.cart;
        }
    },
    methods: {
        openConfirmEmptyModal() {
            store.commit('setModal', {
                component: 'ShopCartEmptyConfirm'
            });
        }
    }
}
</script>

<style scoped>

</style>

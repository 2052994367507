<template>

    <h2 v-if="mapData">{{ $i18n('LABEL_EDIT_MAP') }} <em>{{ mapData.name }}</em></h2>

    <div v-if="otherUsersMap" class="alert alert-warning">
        <OverlaySpinner v-if="!mapUser" />
        <BIconExclamationTriangleFill class="me-2" />
        <span v-if="mapUser">
            {{ $i18n('LABEL_EDITING_OTHER_USER_MAP') }} <em>{{ mapUser.firstName + ' ' + mapUser.lastName }}</em>.
        </span>
    </div>

    <div v-if="editing" class="alert alert-danger">
        <BIconExclamationTriangleFill class="me-2" />
        {{ editing.userName }} {{ $i18n('LABEL_IS_EDITING') }}.
    </div>

    <div v-if="mapData" class="row">
        <div class="col-xl-6 col-lg-8 mb-3">
            <label for="mapLan">{{ $i18n('LABEL_PREVIEW_MAP_LANGUAGE') }}</label>
            <select @change="setMapLan($event)" id="mapLan" class="form-select">
                <option 
                    v-for="(lanItem, lanKey) in languages"
                    :key="lanKey"
                    :value="lanKey"
                    :selected="lanKey==mapLan">
                        {{ lanItem.name }} ({{ lanItem.nativeName }})
                </option>
            </select>
        </div>
    </div>

    <MapEditMapPreview v-if="mapData" :key="mapData.mapKey" :mapKey="mapData.mapKey" />

    <form 
        v-if="mapData"
        action="api/map" 
        method="post" 
        class="needs-validation" 
        :class="{'was-validated': wasValidated}"
        @submit="postEditMap($event)" 
        novalidate>

        <!-- TAB NAV -->
        <ul class="nav nav-tabs mb-3" id="editMapTabList" role="tablist">
            <li class="nav-item" role="presentation">
                <a href="#basicInfoTabContent" id="basicInfoTab" @click="setActiveTab('basicInfoTab')" class="nav-link active" data-bs-toggle="tab" role="tab" aria-controls="basicInfoTabContent" aria-selected="true">{{ $i18n('LABEL_BASIC_INFO') }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a href="#categoriesTabContent" id="categoriesTab" @click="setActiveTab('categoriesTab')" class="nav-link" data-bs-toggle="tab" role="tab" aria-controls="categoriesTabContent" aria-selected="false">{{ $i18n('LABEL_CATEGORIES') }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a href="#sourcesTabContent" id="sourcesTab" @click="setActiveTab('sourcesTab')" class="nav-link" data-bs-toggle="tab" role="tab" aria-controls="sourcesTabContent" aria-selected="false">{{ $i18n('LABEL_SOURCES') }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a href="#mapLayersTabContent" id="mapLayersTab" @click="setActiveTab('mapLayersTab')" class="nav-link" data-bs-toggle="tab"  role="tab" aria-controls="mapLayersTabContent" aria-selected="false">{{ $i18n('LABEL_MAP_LAYERS') }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a href="#optionsTabContent" id="optionsTab" @click="setActiveTab('optionsTab')" class="nav-link" data-bs-toggle="tab" role="tab" aria-controls="optionsTabContent" aria-selected="false">{{ $i18n('LABEL_SETTINGS') }}</a>
            </li>
        </ul>

        <div class="tab-content" id="editMapTabContent">
            
            <!-- BASIC INFO TAB CONTENT -->
            <div class="tab-pane fade show active" id="basicInfoTabContent" role="tabpanel" aria-labelledby="basicInfoTab">
                
                <MapEditBasicInfo 
                    v-model="mapData" 
                    :useCurrentBtnDisabled="useCurrentBtnDisabled"
                />

            </div> <!-- /#basicInfoTabContent -->



            <!-- CATEGORIES TAB CONTENT -->
            <div class="tab-pane fade" id="categoriesTabContent" role="tabpanel" aria-labelledby="categoriesTab">

                <div class="row mt-4">

                    <div class="col-lg-6">
                        
                        <h3 class="h6">{{ $i18n('LABEL_MAP_CATEGORIES') }}</h3>

                        <p v-if="!mapData.config.categories">{{ $i18n('TEXT_MAP_NO_CATEGORIES') }}</p>

                        <draggable 
                            :list="mapData.config.categories" 
                            @change="setMapConfig()"
                            class="draggable draggable-categories list-group mb-3">
                                <div 
                                    v-for="category in mapData.config.categories" :key="category.name"
                                    class="list-group-item bg-light d-flex align-items-center">
                                    <input 
                                        v-model="mapData.config.initCheckedCategories" 
                                        type="checkbox" 
                                        class="me-1"
                                        :value="category.id" />
                                    <img v-if="getCategoryIcon(category.id)" :src="getCategoryIcon(category.id)" class="list-category-img" :alt="getCategoryName(category.id)" />
                                    <div class="flex-grow-1">
                                        {{ getCategoryName(category.id) }}
                                    </div>
                                    <!-- button 
                                        v-if="isCustomCategory(category.id)"
                                        @click="openEditCustomCategory(category.id)" 
                                        type="button" 
                                        class="btn btn-primary draggable-btn me-1">
                                        <BIconPencil />
                                    </button -->
                                    <button 
                                        type="button" 
                                        @click="removeCategory(category.id)" 
                                        class="btn btn-danger draggable-btn">
                                        <BIconTrash />
                                    </button>
                                </div>
                        </draggable>

                    </div>

                    <div class="col-lg-6">

                        <h3 class="h6 d-flex justify-content-between">
                            {{ $i18n('LABEL_ADD_CATEGORIES_TO_MAP') }}
                            <button 
                                @click="$emitter.emit('openHelpText', 'TEXT_HELP_CATEGORIES')"
                                type="button" 
                                class="btn btn-primary btn-help">
                                <BIconQuestion />
                            </button>
                        </h3>

                        <div class="position-relative">
                            <OverlaySpinner v-if="! locationsDbCategories || ! customCategories" />

                            <div v-if="locationsDbCategories" class="mb-3">
                                <label for="addCategory" class="form-label visually-hidden">{{ $i18n('LABEL_DEFAULT_CATEGORIES') }}</label>
                                <select @change="addCategory($event)" id="addCategory" name="addCategory" class="form-select">
                                    <option value="">{{ $i18n('LABEL_DEFAULT_CATEGORIES') }}</option>
                                    <option 
                                        v-for="category in locationsDbCategories"
                                        :key="'cat-' + category.id"
                                        :value="category.id"
                                        :disabled="categoryExistsInSelected(category.id)">
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>
                            
                            <div v-if="customCategories && customCategories.length" class="mb-3">
                                <label for="addCustomCategory" class="form-label visually-hidden">{{ $i18n('LABEL_CUSTOM_CATEGORIES') }}</label>
                                <select @change="addCategory($event)" id="addCustomCategory" name="addCustomCategory" class="form-select">
                                    <option value="">{{ $i18n('LABEL_CUSTOM_CATEGORIES') }}</option>
                                    <option 
                                        v-for="category in customCategories"
                                        :key="'cat-' + category.id"
                                        :value="category.id"
                                        :disabled="categoryExistsInSelected(category.id)">
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>
                            
                            <!-- div v-if="customCategories" class="text-end mb-3">
                                <button type="button" @click="openAddNewCustomCategory()" class="btn btn-primary mb-3">
                                    {{ $i18n('LABEL_ADD_NEW_CUSTOM_CATEGORY') }}
                                </button>
                            </div -->

                        </div>

                    </div>

                </div> <!-- /.row -->

            </div> <!-- /#categoriesTabContent -->



            <!-- SOURCES TAB CONTENT -->
            <div class="tab-pane fade" id="sourcesTabContent" role="tabpanel" aria-labelledby="sourcesTab">

                <MapEditSources :sourceIds="mapData.config.sourceIds" :externalSourceNames="externalSourceNames" :testSources="testSources" />

            </div> <!-- /#sourcesTabContent -->



            <!-- MAP LAYERS TAB CONTENT -->
            <div class="tab-pane fade" id="mapLayersTabContent" role="tabpanel" aria-labelledby="mapLayersTab">

                <MapEditMapLayers v-model="mapData.config" />

            </div> <!-- /#mapLayersTabContent -->



            <!-- OPTIONS TAB CONTENT -->
            <div class="tab-pane fade" id="optionsTabContent" role="tabpanel" aria-labelledby="optionsTab">
                
                <!-- EDIT GENERAL -->
                <MapEditOptionsGeneral v-model="mapData" />

                <!-- EDIT APPEARANCE -->
                <MapEditOptionsAppearance v-model="mapData" />
                
                <!-- EDIT WELCOME MESSAGES -->
                <MapEditOptionsWelcomeMessage v-model="mapData" />

            </div> <!-- /#optionsTabContent -->



        </div> <!-- /#editMapTabContent -->

        <!-- p class="text-muted lh-1">
            <small>
                Created on {{ mapData.created }}<br />
                Modified on {{ mapData.modified }}<br />
                Map key: {{ mapData.mapKey }}
            </small>
        </p -->

        <div v-if="showSaveBtn" class="text-center my-5">
            <button type="submit" class="btn btn-primary btn-lg" :disabled="testModeActive()">{{ $i18n('LABEL_SAVE') }}</button>
            <div v-if="testModeActive()">
                <small class="text-muted">{{ $i18n('TEXT_CANNOT_SAVE_MAP_IN_TEST_MODE') }}</small>
            </div>
        </div>

    </form>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import languages from '../assets/data/languages.json';
import mapDefaultConfig from '../../public/default-config.json';
import { VueDraggableNext } from 'vue-draggable-next';
import { BIconExclamationTriangleFill, BIconQuestion, BIconTrash } from 'bootstrap-icons-vue';
import MapEditBasicInfo from '../components/MapEditBasicInfo.vue';
import MapEditMapLayers from '../components/MapEditMapLayers.vue';
import MapEditMapPreview from '../components/MapEditMapPreview.vue';
import MapEditOptionsAppearance from '../components/MapEditOptionsAppearance.vue';
import MapEditOptionsGeneral from '../components/MapEditOptionsGeneral.vue';
import MapEditOptionsWelcomeMessage from '../components/MapEditOptionsWelcomeMessage.vue';
import MapEditSources from '../components/MapEditSources.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'MapsEdit',
    components: {
        draggable: VueDraggableNext,
        BIconExclamationTriangleFill,
        BIconQuestion,
        BIconTrash,
        MapEditBasicInfo,
        MapEditMapLayers,
        MapEditMapPreview,
        MapEditOptionsAppearance,
        MapEditOptionsGeneral,
        MapEditOptionsWelcomeMessage,
        MapEditSources,
        OverlaySpinner
    },
    computed: {
        lan() {
            return store.state.lan;
        },
        showSaveBtn() {
            let showSaveTabs = ['basicInfoTab', 'categoriesTab', 'sourcesTab', 'mapLayersTab', 'optionsTab'];
            return showSaveTabs.includes(this.activeTab);
        },
        locationsDbCategories() {
            return store.state.locationsDbCategories;
        },
        customCategories() {
            if (store.state.customCategories) {
                return store.state.customCategories.filter(item => item.lan == store.state.lan);
            }
            return null;
        },
        sourceProducts() {
            if (store.state.products) {
                return store.state.products.filter((item) => item.categoryId == 3);
            }
            return null;
        },
        externalSourceNames() {
            let extSourceNames = [];
            if (this.mapData.config.externalSources) {
                for (let group in this.mapData.config.externalSources) {
                    for (let i in this.mapData.config.externalSources[group].sources) {
                        let sourceId = this.mapData.config.externalSources[group].sources[i].id;
                        extSourceNames.push(sourceId);
                    }
                }
            }
            return extSourceNames;
        },
        useCurrentBtnDisabled() {
            return ! this.mapCurrentZoom || ! this.mapCurrentLat || ! this.mapCurrentLng;
        },
        otherUsersMap() {
            return this.mapData && store.state.user && (this.mapData.userId != store.state.user.id);
        }
    },
    watch: {
        /**
         * Watchers for all map config properties that need to trigger setMapConfig.
         */
        'mapData.config.markersUrl': function() {
            this.setMapConfig();
        },
        'mapData.config.routesUrl': function() {
            this.setMapConfig();
        },
        'mapData.config.initCheckedCategories': function() {
            this.setMapConfig();
        },
        'mapData.config.settingsPanelClosed': function() {
            this.setMapConfig();
        },
        'mapData.config.showTypeFilters': function() {
            this.setMapConfig();
        },
        'mapData.config.showCategoryFilters': function() {
            this.setMapConfig();
        },
        'mapData.config.showGeosearch': function() {
            this.setMapConfig();
        },
        'mapData.config.showMyLocationButton': function() {
            this.setMapConfig();
        },
        'mapData.config.showFullscreenToggle': function() {
            this.setMapConfig();
        },
        'mapData.config.fullscreenOnFocus': function() {
            this.setMapConfig();
        },
        'mapData.config.showShareButtons': function() {
            this.setMapConfig();
        },
        'mapData.config.theme': function() {
            this.setMapConfig();
        },
        'mapData.config.themeColor': function() {
            this.setMapConfig();
        },
        'mapData.config.boxed': function(val) {
            if (! val) {
                this.mapData.config.boxedWidth = null;
                this.mapData.config.boxedHeight = null;
            }
            this.setMapConfig();
        },
        'mapData.config.boxedWidth': function() {
            this.setMapConfig();
        },
        'mapData.config.boxedHeight': function() {
            this.setMapConfig();
        },
        'mapData.config.anyLan': function() {
            this.setMapConfig();
        },
    },
    data() {
        return {
            wasValidated: false,
            activeTab: 'basicInfoTab',
            mapData: null,
            mapCurrentZoom: null,
            mapCurrentLat: null,
            mapCurrentLng: null,
            mapLan: store.state.lan,
            mapUser: null,
            testSources: [],
            languages: languages,
            editing: null
        }
    },
    created() {
        this.$emitter.on('ready', () => {
            this.$emitter.emit('getCustomCategories');
        });

        this.$emitter.on('setMapConfig', () => {
            this.setMapConfig();
        });

        /**
         * Listener for testSource event emitted from MapEditSources.
         */
        this.$emitter.on('testSource', this.testSource);

        /**
         * Listener for endTestSource event emitted from MapEditSources.
         */
        this.$emitter.on('endTestSource', this.endTestSource);

        /**
         * Listener for useSource event emitted from MapEditSources.
         */
        this.$emitter.on('useSource', this.useSource);

        /**
         * Listener for removeSource event emitted from MapEditSources.
         */
        this.$emitter.on('removeSource', this.removeSource);

        /**
         * Listener for testMapLayer event emitted from MapEditMapLayers.
         */
        this.$emitter.on('testMapLayer', (data) => {
            // Set map layer to test mode
            data.test = true;
            // Set mapKey to url
            data.url = data.url.replace('{mapKey}', this.mapData.mapKey);
            this.mapData.config.mapLayers.unshift(data);
            this.setMapConfig();
        });

        /**
         * Listener for testOverlayLayer event emitted from MapEditMapLayers.
         */
        this.$emitter.on('testOverlayLayer', (data) => {
            // Set map layer to test mode
            data.test = true;
            // Set mapKey to url
            data.url = data.url.replace('{mapKey}', this.mapData.mapKey);
            // Make sure overlayLayers is set for backwards compatibility
            if (! this.mapData.config.overlayLayers) {
                this.mapData.config.overlayLayers = [];
            }
            this.mapData.config.overlayLayers.unshift(data);
            this.setMapConfig();
        });

        /**
         * Listener for useMapLayer event emitted from MapEditMapLayers.
         */
        this.$emitter.on('useMapLayer', (data) => {
            // Set map api map key
            data.url = data.url.replace('{mapKey}', this.mapData.mapKey);
            this.mapData.config.mapLayers.unshift(data);
            this.setMapConfig();
        });

        /**
         * Listener for useOverlayLayer event emitted from MapEditMapLayers.
         */
        this.$emitter.on('useOverlayLayer', (data) => {
            // Set map api map key
            data.url = data.url.replace('{mapKey}', this.mapData.mapKey);
            // Make sure overlayLayers is set for backwards compatibility
            if (! this.mapData.config.overlayLayers) {
                this.mapData.config.overlayLayers = [];
            }
            this.mapData.config.overlayLayers.push(data);
            this.setMapConfig();
        });

        /**
         * Listener for removeMapLayer event emitted from MapEditMapLayers.
         */
        this.$emitter.on('removeMapLayer', (name) => {
            let index = this.mapData.config.mapLayers.findIndex((item) => {
                return item.name == name
            });
            if (index !== -1) {
                this.mapData.config.mapLayers.splice(index, 1);
                this.setMapConfig();
            }
        });

        /**
         * Listener for removeOverlayLayer event emitted from MapEditMapLayers.
         */
        this.$emitter.on('removeOverlayLayer', (name) => {
            let index = this.mapData.config.overlayLayers.findIndex((item) => {
                return item.name == name
            });
            if (index !== -1) {
                this.mapData.config.overlayLayers.splice(index, 1);
                this.setMapConfig();
            }
        });

        /**
         * Listener for deleteWelcomeMessage event.
         */
        this.$emitter.on('deleteWelcomeMessage', (textLan) => {
            delete(this.mapData.config.welcomeMessage[textLan]);
            if (Object.keys(this.mapData.config.welcomeMessage).length === 0) {
                delete(this.mapData.config.welcomeMessage);
            }
            this.setMapConfig();
        });

        /**
         * Listener for useCurrentLocationAndZoom event.
         */
        this.$emitter.on('useCurrentLocationAndZoom', () => {
            this.useCurrentLocationAndZoom();
        });

        /**
         * Listener for openMapEmbedCode event.
         */
        this.$emitter.on('openMapEmbedCode', () => {
            this.openMapEmbedCode();
        });

        /**
         * Listener for openMapEditBounds event.
         */
        this.$emitter.on('openMapEditBounds', () => {
            this.openMapEditBounds();
        });

        /**
         * Listener for setMapMaxBounds event.
         */
        this.$emitter.on('setMapMaxBounds', (bounds) => {
            this.mapData.config.maxBounds = bounds;
            this.setMapConfig();
            window.emitter.emit('setMapMaxBounds', bounds);
        });
    },
    mounted() {
        this.mapData = null;

        if (this.$route.params.mapKey) {
            // We're editing an existing map
            this.getMap(this.$route.params.mapKey);
        }

        window.emitter.on('previewMapReady', () => {
            if (this.mapData) {
                this.setMapConfig();
            }
        });

        window.emitter.on('setInitLocation', (location) => {
            this.mapCurrentLat = location.lat;
            this.mapCurrentLng = location.lng;
        });

        window.emitter.on('setInitZoom', (zoom) => {
            this.mapCurrentZoom = zoom;
        });

        window.emitter.on('useCurrentLocationAndZoom', () => {
            this.useCurrentLocationAndZoom();
        });
    },
    beforeUnmount() {
        window.emitter.emit('removeMapListeners');

        if (this.mapData) {
            this.$emitter.emit('endMapEditing', {
                workspaceId: this.mapData.workspaceId,
                mapId: this.mapData.id,
                userId: store.state.user.id,
                delete: true
            });
        }
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('ready');
        this.$emitter.all.delete('setMapConfig');
        this.$emitter.all.delete('testSource');
        this.$emitter.all.delete('endTestSource');
        this.$emitter.all.delete('useSource');
        this.$emitter.all.delete('removeSource');
        this.$emitter.all.delete('testMapLayer');
        this.$emitter.all.delete('useMapLayer');
        this.$emitter.all.delete('removeMapLayer');
        this.$emitter.all.delete('deleteWelcomeMessage');
        this.$emitter.all.delete('setMapMaxBounds');
        window.emitter.all.delete('previewMapReady');
        window.emitter.all.delete('setInitLocation');
        window.emitter.all.delete('setInitZoom');
    },
    methods: {
        /**
         * Gets map data from the db.
         * 
         * @param   {string}    mapKey
         */
        getMap(mapKey) {
            axios({
                method: 'get',
                url: '/api/map/' + mapKey,
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setMessages', response.data.messages);
                    }
                    if (response.data.map) {
                        // Check if someone is already editing
                        this.editing = this.otherUserIsEditing(response.data.map.id);
                        if (! this.editing) {
                            this.mapData = response.data.map;
                            // Set map config missing default values for backwards compatibility
                            this.setMapConfigDefaults();
                            this.setMapConfig();
                            // Post editing to DB
                            this.$emitter.emit('postEditing', {
                                workspaceId: this.mapData.workspaceId,
                                mapId: this.mapData.id,
                                userId: store.state.user.id
                            });
                            // If we're editing someone else's map, get user data
                            if (this.mapData.userId != store.state.user.id) {
                                this.getMapUser();
                            }
                        }
                    } 
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            });
        },
        /**
         * Gets map user data.
         */
        getMapUser() {
            axios({
                method: 'get',
                url: '/api/users/' + this.mapData.userId,
                params: {
                    workspaceId: store.state.user.activeWorkspaceId
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.user) {
                    this.mapUser = response.data.user;
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        otherUserIsEditing(mapId) {
            if (store.state.editings) {
                let editing = store.state.editings.find(item => item.mapId == mapId && item.userId != store.state.user.id);
                if (editing) {
                    return editing;
                }
            }
            return false;
        },
        /**
         * Sets map config missing default values for backwards compatibility.
         */
        setMapConfigDefaults() {
            // Loop through default config
            for (const [key, value] of Object.entries(mapDefaultConfig)) {
                // Check if a config option is missing from the current map config
                if (! Object.prototype.hasOwnProperty.call(this.mapData.config, key)) {
                    // Add missing options with default value to current map config
                    this.mapData.config[key] = value;
                }
            }
        },
        /**
         * @param   {string}    tabName
         */
        setActiveTab(tabName) {
            this.activeTab = tabName;
        },
        setMapLan(e) {
            this.mapLan = e.target.value;
            this.setMapConfig();
        },
        useCurrentLocationAndZoom() {
            if (this.mapData && this.mapCurrentZoom && this.mapCurrentLat && this.mapCurrentLng) {
                this.mapData.config.initZoom = this.mapCurrentZoom;
                this.mapData.config.initLocation.lat = this.mapCurrentLat;
                this.mapData.config.initLocation.lng = this.mapCurrentLng;
            }
        },
        /**
         * Checks if a category is already added to map config.
         * 
         * @param   {number}    catId
         * @returns {boolean}
         */
        categoryExistsInSelected(catId) {
            if (this.mapData.config.categories) {
                return this.mapData.config.categories.find(category => category.id == catId);
            }
            return false;
        },
        /**
         * Checks if a category is a custom category.
         * 
         * @param   {number}    catId
         * @returns {boolean}
         */
        isCustomCategory(catId) {
            if (this.customCategories) {
                return this.customCategories.find(category => category.id == catId);
            }
            return false;
        },
        /**
         * @param   {number}    catId
         * @returns {string}
         */
        getCategoryName(catId) {
            let allCats = this.locationsDbCategories.concat(this.customCategories);
            if (! allCats || ! allCats.length) {
                return null;
            }
            let cat = allCats.find(item => item && item.id == catId && item.lan == store.state.lan);
            if (cat) {
                return cat.name;
            }
            // Category not found in current language, get English
            cat = allCats.find(item => item && item.id == catId && item.lan == 'en');
            if (cat) {
                return cat.name;
            }
            // Category not found in English, get it in any language
            cat = allCats.find(item => item && item.id == catId);
            if (cat) {
                return cat.name;
            }
            // Category not found in any language (which shouldn't really happen)
            return null;
        },
        /**
         * @param   {number}    catId
         * @returns {string}
         */
        getCategoryIcon(catId) {
            let allCats = this.locationsDbCategories.concat(this.customCategories);
            if (! allCats || ! allCats.length) {
                return null;
            }
            let cat = allCats.find(item => item && item.id == catId);
            if (cat) {
                return cat.icon;
            }
            return null;
        },
        /**
         * Adds a Locations DB category to map config.
         * 
         * @param   {object}    e   Native event object.
         */
        /*addCategoryFromLocationsDb(e) {
            // Create categories array to config if not set
            if (! this.mapData.config.categories) {
                this.mapData.config.categories = [];
            }
            if (e.target.value) {
                let cat = this.locationsDbCategories.find(category => category.id == e.target.value);
                this.mapData.config.categories.push(cat);
                this.mapData.config.initCheckedCategories.push(cat.id);
                e.target.selectedIndex = 0;
                this.setMapConfig();
            }
        },*/
        /**
         * Adds a custom category to map config.
         * 
         * @param   {object}    e   Native event object.
         */
        /*addCustomCategory(e) {
            // Create categories array to config if not set
            if (! this.mapData.config.categories) {
                this.mapData.config.categories = [];
            }
            if (e.target.value) {
                let cat = this.customCategories.find(category => category.id == e.target.value);
                this.mapData.config.categories.push(cat);
                this.mapData.config.initCheckedCategories.push(cat.id);
                e.target.selectedIndex = 0;
                this.setMapConfig();
            }
        },*/
        addCategory(e) {
            // Create categories array to config if not set
            if (! this.mapData.config.categories) {
                this.mapData.config.categories = [];
            }
            if (e.target.value) {
                this.mapData.config.categories.push({
                    id: parseInt(e.target.value)
                });
                this.mapData.config.initCheckedCategories.push(parseInt(e.target.value));
                e.target.selectedIndex = 0;
                this.setMapConfig();
            }
        },
        /**
         * Opens CustomCategoryEdit in modal for adding a new custom category.
         */
        openAddNewCustomCategory() {
            store.commit('setModal', {
                component: 'CustomCategoryEdit',
                size: 'modal-lg'
            });
        },
        /**
         * Opens CustomCategoryEdit in modal for editing an existing custom category.
         */
        /*openEditCustomCategory(catId) {
            store.commit('setModal', {
                component: 'CustomCategoryEdit',
                componentProps: {
                    initCustomCategory: this.customCategories.find((category) => category.id == catId)
                },
                size: 'modal-lg'
            });
        },*/
        /**
         * Removes a category from map config.
         * 
         * @param   {number}    categoryId
         */
        removeCategory(categoryId) {
            this.mapData.config.categories = this.mapData.config.categories.filter(category => category.id != categoryId);
            this.setMapConfig();
        },
        /**
         * @param   {number|string} sourceId
         */
        testSource(sourceId) {
            // Set sourceId to testSources
            this.testSources.push(sourceId);
            // Check if it is an external source
            if (isNaN(sourceId)) {
                // Id not a number, it's an external source
                let extSource = this.getExternalSourceProductById(sourceId);
                this.addExternalSource(extSource);
            } else {
                // Id is a number, it's a Locations DB source
                // Set sourceId to map config
                this.mapData.config.sourceIds.push(sourceId);
            }
            this.setMapConfig();
        },
        endTestSource(sourceId) {
            // Remove sourceId from testSources
            this.testSources = this.testSources.filter(item => item !== sourceId);
            // Remove source from config sourceIds or externalSources
            this.removeSource(sourceId);
        },
        /**
         * @param   {object}    sourceData
         */
        useSource(sourceData) {
            if (sourceData.type == 'source') {
                // Set sourceId to map config
                this.mapData.config.sourceIds.push(sourceData.id);
                this.setMapConfig();
            } else if (sourceData.type == 'externalSource') {
                this.addExternalSource(sourceData);
            } else {
                console.error('Invalid source type');
            }
        },
        /**
         * Removes sourceId or external source from map config.
         * @param   {number|string}    sourceId
         */
        removeSource(sourceId) {
            if (isNaN(sourceId)) {
                // Id not a number, it's an external source
                for (let group in this.mapData.config.externalSources) {
                    for (let i in this.mapData.config.externalSources[group].sources) {
                        if (this.mapData.config.externalSources[group].sources[i].id == sourceId) {
                            // Match found
                            // Check if it was the last one in the group and delete the whole group if needed
                            if (this.mapData.config.externalSources[group].sources.length == 1) {
                                // Check if it was the last group
                                if (this.mapData.config.externalSources.length == 1) {
                                    // Delete whole externalSources object
                                    delete this.mapData.config.externalSources;
                                } else {
                                    // Delete group
                                    delete this.mapData.config.externalSources[group];
                                }
                            } else {
                                // Delete source from group sources
                                // For some weird reason splice throws an error here so we use delete and temp array
                                let tempSources = this.mapData.config.externalSources[group].sources;
                                delete tempSources[Object.keys(tempSources)[i]];
                                this.mapData.config.externalSources[group].sources = tempSources.filter(function(){return true;});
                                tempSources = null;
                            }
                            this.setMapConfig();
                            break;
                        }
                    }
                }
            } else {
                // Id is a number, it's a Locations DB source
                // Remove sourceId from map config
                this.mapData.config.sourceIds = this.mapData.config.sourceIds.filter(item => item !== sourceId);
                this.setMapConfig();
            }
        },
        /**
         * @param   {object}    sourceData
         */
        addExternalSource(sourceData) {
            // Recreate externalSources each time to make it reactive
            let tempExtSources = {};
            // Check if map config has any external sources already
            if (this.mapData.config.externalSources) {
                // Get existing external sources
                tempExtSources = this.mapData.config.externalSources;
            }
            // Check if existing externalSources has the externalSource group set
            if (! tempExtSources[sourceData.group]) {
                // Group not set, create it
                tempExtSources[sourceData.group] = {
                    showToggleAll: false,
                    sources: []
                };
            }
            tempExtSources[sourceData.group].sources.push(sourceData);
            this.mapData.config.externalSources = null;
            this.mapData.config.externalSources = tempExtSources;
            tempExtSources = null;
            this.setMapConfig();
        },
        /**
         * @param   {string}    sourceId
         * @returns {object}
         */
        getExternalSourceProductById(sourceId) {
            for (let i in this.sourceProducts) {
                if (this.sourceProducts[i].name == sourceId) {
                    return this.sourceProducts[i].data;
                }
            }
        },
        /**
         * Opens MapEmbedCode in modal for copying map embed code.
         */
        openMapEmbedCode() {
            store.commit('setModal', {
                component: 'MapEmbedCode',
                componentProps: {
                    mapKey: this.mapData.mapKey
                },
                size: 'modal-lg'
            });
        },
        openMapEditBounds() {
            store.commit('setModal', {
                component: 'MapEditBounds',
                componentProps: {
                    initBounds: this.mapData.config.maxBounds,
                    latitude: this.mapData.config.initLocation.lat,
                    longitude: this.mapData.config.initLocation.lng,
                    initZoom: this.mapData.config.initZoom
                },
                size: 'modal-lg'
            });
        },
        /**
         * Emits setMapConfig event to window.emitter to set the map app config.
         */
        setMapConfig() {
            store.commit('setMapCategories', this.mapData.config.categories);
            // Make sure workspaceId is set for backwards compatibility
            this.mapData.config.workspaceId = this.mapData.workspaceId;
            window.emitter.emit('setMapLan', this.mapLan);
            window.emitter.emit('setMapConfig', this.mapData.config);
        },
        testModeActive() {
            // Check for sources in test mode
            if (this.testSources.length > 0) {
                return true;
            }
            // Check for map layers in test mode
            if (this.mapData.config.mapLayers.find(item => { return item.test })) {
                return true;
            }
            // Check for overlay layers in test mode
            if (this.mapData.config.overlayLayers && this.mapData.config.overlayLayers.find(item => { return item.test })) {
                return true;
            }
            return false;
        },
        /**
         * Checks map edit form validity and posts data to API if everything ok.
         * 
         * @param   {object}    e   Native event object.
         */
        postEditMap(e) {
            e.preventDefault();
            e.stopPropagation();

            // Make sure map cannot be saved if map layer testing is active
            if (this.testModeActive()) {
                store.commit('addModalError', this.$i18n('TEXT_CANNOT_SAVE_MAP_IN_TEST_MODE'));
                return;
            }

            this.wasValidated = true;

            // Validate form
            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving map data');
            store.commit('clearErrors');
            store.commit('clearMessages');

            let url = '/api/map/';

            // Add mapKey to url if editing existing map
            if (this.$route.params.mapKey) {
                url += this.$route.params.mapKey;
            }

            axios({
                method: 'post',
                url: url,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: this.createFormData()
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
            });
        },
        /**
         * Creates FormData object for saving map data.
         */
        createFormData() {
            let formData = new FormData();

            this.mapData.config.initZoom = parseInt(this.mapData.config.initZoom);
            
            this.mapData.config.initLocation.lat = parseFloat(this.mapData.config.initLocation.lat);
            this.mapData.config.initLocation.lng = parseFloat(this.mapData.config.initLocation.lng);

            // Always set userId to map config
            this.mapData.config.userId = this.$user().id;

            // Set user sourceId to map config sourceIds if not defined
            if (! this.mapData.config.sourceIds || this.mapData.config.sourceIds.length == 0) {
                this.mapData.config.sourceIds = [this.$user().sourceId];
            }

            let mapWorkspaceId = null;
            
            // Use map's workspaceId if defined so admin edits won't change it
            if (this.mapData.workspaceId) {
                mapWorkspaceId = this.mapData.workspaceId;
            } else {
                mapWorkspaceId = store.state.user.activeWorkspaceId;
            }

            let dataObj = {
                name: this.mapData.name,
                workspaceId: mapWorkspaceId,
                config: JSON.stringify(this.mapData.config)
            };

            if (this.$route.params.mapKey) {
                dataObj.mapKey = this.$route.params.mapKey;
            }

            for (var key in dataObj) {
                formData.append(key, dataObj[key]);
            }

            return formData;
        }
    }
}
</script>

<style scoped>

</style>
<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_MAP_EMBED_CODE') }}</h5>
        <button type="button" @click="close()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <div class="mb-3">
            <label for="dataLan" class="form-label">{{ $i18n('LABEL_MAP_LANGUAGE') }}</label>
            <select v-model="dataLan" id="dataLan" name="dataLan" class="form-select" required>
                <option :value="null">{{ $i18n('LABEL_USE_SITE_LANGUAGE') }}</option>
                <optgroup :label="$i18n('LABEL_SELECT_LANGUAGE')">
                    <option 
                        v-for="(lanItem, lanCode) in languages"
                        :key="lanCode"
                        :value="lanCode"
                    >
                        {{ lanItem.name }} ({{ lanItem.nativeName }})
                    </option>
                </optgroup>
            </select>
        </div>

<pre><code @click="selectEmbedCode($event)" class="map-embed-code">&lt;div id=&quot;ecm&quot; {{ getDataLan() }}data-map-key=&quot;{{ mapKey }}&quot;&gt;&lt;/div&gt;
&lt;link rel=&quot;stylesheet&quot; href=&quot;{{ mapDirPath }}css/&quot;&gt;
&lt;script src=&quot;{{ mapDirPath }}js/&quot;&gt;&lt;/script&gt;</code></pre>

    </div>

     <div class="modal-footer">
        <button type="button" @click="close()" class="btn btn-primary">{{ $i18n('LABEL_CLOSE') }}</button>
    </div>

</template>

<script>
import store from '../store/store.js';
import languages from '../assets/data/languages.json';

export default {
    name: 'MapEmbedCode',
    props: {
        mapKey: {
            type: String,
            required: true
        }
    },
    computed: {
        /**
         * Computed property for env var VUE_APP_MAP_PATH
         * so it can be used in the template as well.
         * 
         * @returns {string}
         */
        mapDirPath() {
            return process.env.VUE_APP_MAP_PATH;
        }
    },
    data() {
        return {
            languages: languages,
            dataLan: null
        }
    },
    methods: {
        getDataLan() {
            if (this.dataLan) {
                return 'data-lan="' + this.dataLan + '" ';
            }
            return null;
        },
        /**
         * Selects all text in the embed code element.
         * @param   {MouseEvent}    e
         */
        selectEmbedCode(e) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(e.target);
            selection.removeAllRanges();
            selection.addRange(range);
        },
        close() {
            store.commit('setModal', null);
        }
    }
}
</script>

<style scoped>
.map-embed-code {
    color: #d63384;
}
</style>

<template>
    <div class="position-relative bg-white p-3 my-3 shadow-sm">

        <h1>{{ $i18n('LABEL_PAGE_NOT_FOUND') }}</h1>

        <div class="alert alert-danger">
            Page not found :(
        </div>

    </div>
</template>

<script>

export default {
    name: 'PageNotFound',
    components: {
        
    },
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>

</style>
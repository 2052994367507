<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ title }}</h5>
        <button type="button" @click="close()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <Text :id="helpTextKey" />

    </div>

     <div class="modal-footer">
        <button type="button" @click="close()" class="btn btn-primary">Close</button>
    </div>

</template>

<script>
//import axios from 'axios';
import store from '../store/store.js';
import Text from '../components/Text.vue';

export default {
    name: 'HelpText',
    components: {
        Text
    },
    props: {
        helpTextKey: String,
        title: {
            type: String,
            default: ''
        }
    },
    methods: {
        close() {
            store.commit('setModal', null);
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_EDIT_TEXT') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <OverlaySpinner v-if="$loading()" />

            <form 
                action="api/text" 
                method="post" 
                id="textEditForm"
                class="needs-validation" 
                :class="{'was-validated': wasValidated}"
                @submit="postText($event)" 
                novalidate>

                <div class="mb-3">
                    <input type="text" :value="id" class="form-control" disabled />
                </div>
                
                <!-- div class="mb-3">
                    <div class="form-check form-switch mt-2">
                        <input type="checkbox" id="initLoad" name="initLoad" class="form-check-input" true-value="1" false-value="0">
                        <label class="form-check-label" for="initLoad">Load on init</label>
                    </div>
                </div -->

                <!-- LABEL AND TEXT TYPES -->
                <div v-if="isLabel || isText">
                    <div 
                        v-for="(textArr, lan) in texts"
                        :key="lan"
                        class="input-group has-validation mb-3">
                        <label class="input-group-text font-monospace">{{ lan.toUpperCase() }}</label>

                        <!-- FOR TYPE LABEL -->
                        <input v-if="isLabel" type="text" :name="lan" v-model="textArr[0].text" class="form-control" required />
                        
                        <!-- FOR TYPE TEXT -->
                        <textarea v-if="isText" :name="lan" v-model="textArr[0].text" rows="5" cols="50" class="form-control" required></textarea>

                        <div class="invalid-feedback">
                            {{ $i18n('LABEL_FIELD_REQUIRED') }}
                        </div>
                    </div>
                </div>

                <!-- HTML -->
                <div v-if="isHTML">
                    <div 
                        v-for="(textArr, lan) in texts"
                        :key="lan"
                        class="input-group has-validation mb-3">
                        <label class="input-group-text font-monospace">{{ lan.toUpperCase() }}</label>

                        <editor
                            api-key="xw3j6ukphzxnee0qiafvv7wplmpb4d2oku9fatn4ztv3xsks"
                            :init="tinyOptions(lan)"
                            :id="lan"
                            :name="lan"
                            v-model="textArr[0].text"
                        />

                        <div class="invalid-feedback">
                            {{ $i18n('LABEL_FIELD_REQUIRED') }}
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="input-group has-validation">
                        <input type="text" v-model="addNewLanCode" maxlength="2" :class="{'is-invalid': lanExists}" class="form-control" placeholder="Language code (2 characters)" />
                        <button type="button" @click="addNewTranslation()" class="btn btn-primary" :disabled="addTranslationBtnDisabled">Add new translation</button>
                        <div class="invalid-feedback">
                            Translation for this language exists already.
                        </div>
                    </div>
                </div>

                <input type="hidden" name="id" :value="id" />
                <button type="submit" id="saveTextBtn" class="visually-hidden">{{ $i18n('LABEL_SAVE') }}</button>

            </form>

        </div> <!-- /.modal-body -->
    
    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="submit()" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import Editor from '@tinymce/tinymce-vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'AdminTextsEdit',
    components: {
        OverlaySpinner,
        Editor
    },
    props: {
        id: String
    },
    computed: {
        lanExists() {
            return Object.keys(this.texts).includes(this.addNewLanCode);
        },
        addTranslationBtnDisabled() {
            return ! (this.addNewLanCode && this.addNewLanCode.length == 2) || this.lanExists;
        },
        isHTML() {
            return this.id.substring(0,5) == 'HTML_';
        },
        isText() {
            return this.id.substring(0,5) == 'TEXT_';
        },
        isLabel() {
            return this.id.substring(0,6) == 'LABEL_';
        }
    },
    data() {
        return {
            texts: {},
            addNewLanCode: null,
            wasValidated: false
        }
    },
    created() {
        this.getText(this.id);
    },
    mounted() {
        
    },
    methods: {
        /**
         * Gets text with desired id from DB via API.
         * 
         * @param   {string}    id
         */
        getText(id) {
            store.commit('setLoading', true);
            axios({
                method: 'get',
                url: '/api/texts/' + id
            })
            .then((response) => {
                if (response.data.texts &&  response.data.texts.length == 0) {
                    store.commit('addModalError', 'Text not found.');
                } else if (response.data.texts) {
                    this.texts = response.data.texts;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Closes modal.
         */
        cancel() {
            store.commit('setModal', null);
        },
        /**
         * Calls form submit; this is because the form submit button is not inside the form element due to Bootstrap modal structure (buttons in modal footer).
         */
        submit() {
            //document.getElementById('textEditForm').requestSubmit();
            document.getElementById('saveTextBtn').click();
        },
        /**
         * Checks text edit form validity and posts data to API if everything ok.
         * 
         * @param   {object}    e   Native MouseEvent object.
         */
        postText(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            // Validate form
            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving text');

            // Trigger TinyMCE save to update textarea contents before posting the form
            if (this.isHTML) {
                window['tinymce'].triggerSave();
            }

            let formData = new FormData(document.getElementById('textEditForm'));

            axios({
                method: 'post',
                url: '/api/text',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                console.log(response);
                if (response.data.status == 'SUCCESS') {
                    this.$emitter.emit('getTexts');
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
                store.commit('setModal', null);
            });
        },
        addNewTranslation() {
            this.texts[this.addNewLanCode] = [
                {
                    id: this.$route.params.id,
                    text: ''
                }
            ];
            this.addNewLanCode = null;
        },
        /**
         * Returns TinyMCE init options with desired language selector.
         * 
         * @param   {string}    lan
         * @returns {object}
         */
        tinyOptions(lan) {
            return {
                selector: '#' + lan,
                menubar: false,
                plugins: 'lists link code fullscreen',
                toolbar: 'styleselect | bold italic | alignleft aligncenter alignright | bullist numlist | link | code | fullscreen',
                entity_encoding : 'raw'
            }
        }
    }
}
</script>

<style scoped>

</style>

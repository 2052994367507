<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div id="modalBody" class="modal-body" :style="{'min-height': minHeight}">

        <!-- TAB NAV -->
        <ul v-if="customCategory.id" class="nav nav-tabs mb-3" id="editCategoryTabList" role="tablist">
            
            <li 
                v-for="(tab) in tabs" 
                :key="tab.name"
                class="nav-item" 
                role="presentation">
                <a 
                    @click="setActiveTab(tab.name)"
                    :href="'#' + tab.name + 'Content'" 
                    :id="tab.name" 
                    class="nav-link" 
                    :class="{active: tab.name == activeTab}"
                    data-bs-toggle="tab" 
                    role="tab" 
                    :aria-controls="tab.name+'Content'" 
                    :aria-selected="tab.name == activeTab">
                        {{ $i18n(tab.label) }}
                    </a>
            </li>

        </ul>

        <div id="editCustomCategoryTabContent" class="tab-content">

            <!-- CATEGORY BASIC INFO TAB CONTENT -->
            <div class="tab-pane fade show active" id="categoryBasicInfoTabContent" role="tabpanel" aria-labelledby="categoryBasicInfoTab">
            
                <form 
                    id="editCustomCategoryForm"
                    action="api/category" 
                    method="post" 
                    class="needs-validation" 
                    :class="{'was-validated': wasValidated}"
                    @submit="editCustomCategoryFormSubmit($event)" 
                    enctype="multipart/form-data" 
                    autocomplete="off"
                    novalidate>

                    <div class="row">

                        <div class="col-lg-6">

                            <div class="mb-3">
                                <label for="name" class="form-label">{{ $i18n('LABEL_NAME') }}</label>
                                <input type="text" id="name" name="name" class="form-control" v-model="customCategory.name" required />
                            </div>

                            <div class="mb-3">
                                <label for="customCategoryImage" class="form-label">{{ imageLabel }}</label>
                                <div class="form-file">
                                    <input 
                                        type="file"  
                                        class="form-control" 
                                        id="customCategoryImage" 
                                        name="customCategoryImage" 
                                        accept=".svg, .png, .jpg, .jpeg, .gif, image/x-png, image/gif, image/jpeg, image/svg+xml"
                                        :required="! customCategory.icon" 
                                    />
                                    <!-- label v-if="selectedFileText" class="form-file-label" for="customCategoryImage">
                                        <span class="form-file-text">{{ selectedFileText }}</span>
                                    </label -->
                                </div>
                                <div class="form-text mb-3">
                                    <Text id="TEXT_CATEGORY_IMAGE" />
                                </div>
                                <button 
                                    v-if="!showIconsBrowser" 
                                    @click="openIconsBrowser()" 
                                    type="button" 
                                    class="btn btn-primary" 
                                    :class="{'btn-loading': !icons}"
                                    :disabled="!icons">
                                    {{ $i18n('LABEL_SELECT_IMAGE_FROM_ICON_LIBRARY') }}
                                </button>
                            </div>

                        </div>

                        <!-- Hide parentId for now... -->
                        <!-- div class="col-lg-6 mb-3">
                            <label for="name" class="form-label">Parent category</label>
                            <select id="parentId" name="parentId" class="form-select" v-model="customCategory.parentId">
                                <option value="null">No parent category</option>
                                <option 
                                    v-for="category in mapCategories"
                                    :key="'parent-cat-' + category.id"
                                    :value="category.id">
                                    {{ category.name }}
                                </option>
                            </select>
                        </div -->

                        <div class="col-lg-6">

                            <div class="mb-3">
                                <label for="lan" class="form-label">{{ $i18n('LABEL_LANGUAGE') }}</label>
                                <select id="lan" name="lan" class="form-select" required>
                                    <option 
                                        v-for="(lanItem, lanCode) in languages"
                                        :key="lanCode"
                                        :selected="lanSelected(lanCode)"
                                        :disabled="lanDisabled(lanCode)"
                                        :value="lanCode">
                                            {{ lanItem.name }} ({{ lanItem.nativeName }})
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>

                            <div v-if="customCategory.icon" class="mb-3">
                                <label class="form-label">{{ $i18n('LABEL_IMAGE') }}</label>
                                <div>
                                    <div class="icon-preview-container mb-2" :style="iconPreviewContainerStyle">
                                        <OverlaySpinner v-if="!previewIconLoaded" />
                                        <img :src="customCategory.icon" @load="previewIconLoadHandler($event)" class="icon-preview" :alt="customCategory.name" />
                                    </div>
                                    <button 
                                        v-if="isCustomSvg && !showColorSelector" 
                                        @click="openColorSelector()" 
                                        type="button" 
                                        class="btn btn-primary" 
                                    >
                                        {{ $i18n('LABEL_SET_ICON_COLORS') }}
                                    </button>
                                </div>

                                <div class="form-text mb-3">
                                    <Text v-if="isCustomSvg" id="TEXT_CATEGORY_ICON_COLORS" />
                                </div>

                                <input type="hidden" name="icon" :value="customCategory.icon" />

                                <div v-if="showColorSelector" class="mt-3">
                                    <div class="row">
                                        <div class="col-sm">
                                            <!-- Use native color input if the browser supports it -->
                                            <div v-if="$supportsInputType('color')" class="mb-3">
                                                <label for="fillColor" class="form-label">{{ $i18n('LABEL_FILL_COLOR') }}</label>
                                                <input v-model="this.fillColor" type="color" id="fillColor" class="form-control p-0" />
                                            </div>

                                            <!-- Color input type not supported, use text input with custom validation -->
                                            <div v-if="! $supportsInputType('color')" class="mb-3">
                                                <label for="fillColor" class="form-label">{{ $i18n('LABEL_FILL_COLOR') }}</label>
                                                <input v-model="this.fillColor" type="text" id="fillColor" class="form-control" pattern="(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))" />
                                                <div class="invalid-feedback">
                                                    {{ $i18n('LABEL_ENTER_VALID_COLOR') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm">
                                            <!-- Use native color input if the browser supports it -->
                                            <div v-if="$supportsInputType('color')" class="mb-3">
                                                <label for="iconColor" class="form-label">{{ $i18n('LABEL_ICON_COLOR') }}</label>
                                                <input v-model="this.iconColor" type="color" id="iconColor" class="form-control p-0" />
                                            </div>

                                            <!-- Color input type not supported, use text input with custom validation -->
                                            <div v-if="! $supportsInputType('color')" class="mb-3">
                                                <label for="iconColor" class="form-label">{{ $i18n('LABEL_ICON_COLOR') }}</label>
                                                <input v-model="this.iconColor" type="text" id="iconColor" class="form-control" pattern="(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))" />
                                                <div class="invalid-feedback">
                                                    {{ $i18n('LABEL_ENTER_VALID_COLOR') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm">
                                            <!-- Use native color input if the browser supports it -->
                                            <div v-if="$supportsInputType('color')" class="mb-3">
                                                <label for="borderColor" class="form-label">{{ $i18n('LABEL_BORDER_COLOR') }}</label>
                                                <input v-model="this.borderColor" type="color" id="borderColor" class="form-control p-0" />
                                            </div>

                                            <!-- Color input type not supported, use text input with custom validation -->
                                            <div v-if="! $supportsInputType('color')" class="mb-3">
                                                <label for="borderColor" class="form-label">{{ $i18n('LABEL_BORDER_COLOR') }}</label>
                                                <input v-model="this.borderColor" type="text" id="borderColor" class="form-control" pattern="(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))" />
                                                <div class="invalid-feedback">
                                                    {{ $i18n('LABEL_ENTER_VALID_COLOR') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- /.row -->

                                    <button @click="resetColors()" type="button" class="btn btn-primary" :disabled="!customColorsSet">
                                        {{ $i18n('LABEL_RESET_COLORS') }}
                                    </button>

                                </div>

                            </div>

                        </div>

                    </div> <!-- /.row -->
                    
                    <div v-if="showIconsBrowser" class="icons-browser">
                         <OverlaySpinner v-if="!iconsLoaded" />
                         <div v-if="showIcons">
                            <button v-for="icon in icons" :key="icon" @click="selectIcon(icon)" type="button" class="icons-browser-btn">
                                <img 
                                    :src="getIconPath(icon)" 
                                    @load="iconLoaded()" 
                                    width="32" 
                                    height="32" 
                                    :alt="icon" 
                                    :title="icon.replace('.svg', '')"
                                />
                            </button>
                        </div>
                    </div>

                    <input v-if="customCategory.id" type="hidden" name="id" :value="customCategory.id" />
                    <input type="hidden" name="workspaceId" :value="activeWorkspaceId" />

                    <button type="submit" id="saveCustomCategoryBtn" class="visually-hidden">
                        {{ $i18n('LABEL_SAVE') }}
                    </button>

                </form>

            </div> <!-- /#categoryBasicInfoTabContent -->



            <!-- CATEGORY TRANSLATIONS TAB CONTENT -->
            <div v-if="customCategory.id" class="tab-pane fade" id="categoryTranslationsTabContent" role="tabpanel" aria-labelledby="categoryTranslationsTab">
                
                <!-- Show current lan first and disabled -->
                <div class="input-group mb-1">
                    <span class="input-group-text font-monospace">{{ customCategory.lan.toUpperCase() }}</span>
                    <input type="text" class="form-control" :value="customCategory.name" disabled />
                </div>

                <form 
                    id="editTranslationForm"
                    action="api/category-translation" 
                    method="post" 
                    @submit="editTranslationFormSubmit($event)" 
                    autocomplete="off">

                    <div v-for="cat in categoryTranslations" :key="cat.lan" class="input-group mb-1">
                        <span class="input-group-text font-monospace">{{ cat.lan.toUpperCase() }}</span>
                        <input type="text" name="name" class="form-control" :value="cat.name" :disabled="editTranslation != cat.lan" />
                        
                        <DeleteConfirmButton 
                            v-if="editTranslation != cat.lan" 
                            deleteEventName ="deleteTranslation" 
                            :deleteEventPayload="cat.lan" 
                            :btnGroupAddClasses="null" 
                            :btnAddClasses="null" 
                        />

                        <input v-if="editTranslation == cat.lan" type="hidden" name="lan" :value="cat.lan" />

                        <button v-if="editTranslation == cat.lan" @click="setEditTranslation(null)" type="button" class="btn btn-secondary">
                            {{ $i18n('LABEL_CANCEL') }}
                        </button>

                        <button v-if="editTranslation == cat.lan" type="submit" class="btn btn-primary">
                            {{ $i18n('LABEL_SAVE') }}
                        </button>

                        <button v-if="editTranslation != cat.lan" @click="setEditTranslation(cat.lan)" type="button" class="btn btn-primary">
                            <BIconPencilFill />
                            <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                        </button>
                    </div>

                    <input type="hidden" name="id" :value="customCategory.id" />
                    <input type="hidden" name="parentId" :value="customCategory.parentId" />
                    <input type="hidden" name="workspaceId" :value="activeWorkspaceId" />
                    <input type="hidden" name="icon" :value="customCategory.icon" />

                </form>


                <h5 class="mt-5">{{ $i18n('LABEL_ADD_TRANSLATION') }}</h5>

                <form 
                    id="addTranslationForm"
                    action="api/category-translation" 
                    method="post" 
                    @submit="addTranslationFormSubmit($event)" 
                    autocomplete="off">

                    <div class="input-group mb-3">
                        <select v-model="addTranslationLan" id="addTranslationLan" name="lan" class="form-select" required>
                            <option :value="null">{{ $i18n('LABEL_SELECT_LANGUAGE') }}</option>
                            <option 
                                v-for="(lanItem, lanCode) in languages"
                                :key="lanCode"
                                :value="lanCode" 
                                :disabled="translationLanDisabled(lanCode)">
                                    {{ lanItem.name }} ({{ lanItem.nativeName }})
                            </option>
                        </select>
                        <input v-model="addTranslationName" type="text" id="addTranslationName" name="name" class="form-control" :placeholder="$i18n('LABEL_NAME')" required />
                        <button type="submit" class="btn btn-primary" :disabled="addTranslationBtnDisabled">{{ $i18n('LABEL_SAVE') }}</button>
                    </div>
                    
                    <input type="hidden" name="id" :value="customCategory.id" />
                    <input type="hidden" name="parentId" :value="customCategory.parentId" />
                    <input type="hidden" name="workspaceId" :value="activeWorkspaceId" />
                    <input type="hidden" name="icon" :value="customCategory.icon" />

                </form> <!-- /#addTranslationForm -->


            </div> <!-- /#categoryTranslationsTabContent -->



        </div> <!-- /#editCustomCategoryTabContent -->

    </div> <!-- /.modal-body -->
        

    <div v-if="activeTab=='categoryBasicInfoTab'" class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="submit()" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import languages from '../assets/data/languages.json';
import { BIconPencilFill } from 'bootstrap-icons-vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import Text from '../components/Text.vue';

export default {
    name: 'CustomCategoryEdit',
    components: {
        BIconPencilFill,
        DeleteConfirmButton,
        OverlaySpinner,
        Text
    },
    props: {
        initCustomCategory: {
            type: Object,
            default: function() {
                return {
                    parentId: null,
                    workspaceId: store.state.user.activeWorkspaceId
                }
            }
        }
    },
    data() {
        return {
            activeTab: 'categoryBasicInfoTab',
            confirmCustomCategoryDelete: null,
            customCategory: this.initCustomCategory,
            addTranslationLan: null,
            addTranslationName: null,
            editTranslation: null,
            languages: languages,
            minHeight: null,
            showIconsBrowser: false,
            showIcons: false,
            icons: null,
            iconsLoaded: false,
            previewIconLoaded: false,
            iconPreviewContainerW: 80,
            iconPreviewContainerH: 80,
            showColorSelector: false,
            iconColor: null,
            fillColor: null,
            borderColor: null,
            tabs: [
                {
                    name: 'categoryBasicInfoTab',
                    label: 'LABEL_BASIC_INFO'
                },
                {
                    name: 'categoryTranslationsTab',
                    label: 'LABEL_TRANSLATIONS'
                }
            ],
            wasValidated: false
        }
    },
    created() {
        this.$emitter.on('deleteTranslation', (catLan) => {
            this.deleteTranslation(catLan);
        });

        this.setColors();
        this.getIcons();
    },
    mounted() {
        this.minHeight = document.getElementById('modalBody').offsetHeight + 'px';
        
        // Post editing to DB
        if (this.customCategory.id) {
            this.$emitter.emit('postEditing', {
                workspaceId: store.state.user.activeWorkspaceId,
                categoryId: this.customCategory.id,
                userId: store.state.user.id
            });
        }
    },
    beforeUnmount() {
        if (this.customCategory.id) {
            this.$emitter.emit('endCategoryEditing', {
                workspaceId: store.state.user.activeWorkspaceId,
                categoryId: this.customCategory.id,
                userId: store.state.user.id,
                delete: true
            });
        }
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('deleteTranslation');
    },
    watch: {
        fillColor() {
            this.setIconUrlParams();
        },
        iconColor() {
            this.setIconUrlParams();
        },
        borderColor() {
            this.setIconUrlParams();
        }
    },
    computed: {
        lan() {
            return store.state.lan;
        },
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        categoryTranslations() {
            if (store.state.customCategories) {
                return store.state.customCategories.filter(item => item.id == this.customCategory.id && item.lan != this.customCategory.lan);
            }
            return [];
        },
        modalTitle() {
            if (this.customCategory.id) {
                return this.$i18n('LABEL_EDIT_CUSTOM_CATEGORY');
            }
            return this.$i18n('LABEL_ADD_NEW_CUSTOM_CATEGORY');
        },
        imageLabel() {
            if (this.customCategory.icon) {
                return this.$i18n('LABEL_CHANGE_IMAGE');
            }
            return this.$i18n('LABEL_IMAGE');
        },
        addTranslationBtnDisabled() {
            return ! this.addTranslationLan || ! this.addTranslationName;
        },
        isCustomSvg() {
            if (! this.customCategory.icon) {
                return false;
            }
            if (this.customCategory.icon.indexOf('.svg') !== -1) {
                return true;
            } else if (this.customCategory.icon.indexOf('/markers-custom') !== -1) {
                return true;
            }
            return false;
        },
        customColorsSet() {
            return this.fillColor || this.iconColor || this.borderColor;
        },
        iconPreviewContainerStyle() {
            return 'width:' + this.iconPreviewContainerW + 'px; height:' + this.iconPreviewContainerH + 'px;'
        }
    },
    methods: {
        setActiveTab(tabName) {
            this.activeTab = tabName;
        },
        lanSelected(lanCode) {
            if (this.customCategory.id) {
                return lanCode == this.customCategory.lan;
            } else {
                return lanCode == this.lan;
            }
        },
        lanDisabled(lan) {
            let disabled = false;
            this.categoryTranslations.forEach((item) => {
                if (item.lan == lan) {
                    // Category has translation for this lan, disable
                    disabled = true;
                    return;
                }
            });
            return disabled;
        },
        translationLanDisabled(lan) {
            let disabled = false;
            if (lan == this.customCategory.lan) {
                // Lan is current lan, disable
                return true;
            }
            this.categoryTranslations.forEach((item) => {
                if (item.lan == lan) {
                    // Category has translation for this lan, disable
                    disabled = true;
                    return;
                }
            });
            return disabled;
        },
        previewIconLoadHandler(e) {
            this.previewIconLoaded = true;
            if (this.isCustomSvg) {
                this.iconPreviewContainerW = 80;
                this.iconPreviewContainerH = 80;
            } else {
                this.iconPreviewContainerW = e.target.width + 16;
                this.iconPreviewContainerH = e.target.height + 16;
            }
        },
        /*imageChange(e) {
            if (e.target.files[0]) {
                this.selectedFileText = e.target.files[0].name;
                return;
            }
            this.selectedFileText = null;
        },*/
        cancel() {
            this.$emitter.emit('getCustomCategories');
            store.commit('setModal', null);
        },
        submit() {
            //document.getElementById('editCustomCategoryForm').requestSubmit();
            document.getElementById('saveCustomCategoryBtn').click();
        },
        setEditTranslation(lan) {
            this.addTranslationLan = null;
            this.addTranslationName = null;
            this.editTranslation = lan;
        },
        setColors() {
            if (this.isCustomSvg) {
                let urlParams = new URLSearchParams(this.customCategory.icon);
                let fillColor = urlParams.get('fillColor');
                let iconColor = urlParams.get('iconColor');
                let borderColor = urlParams.get('borderColor');
                
                if (fillColor) {
                    if (fillColor.indexOf('#') !== -1) {
                        fillColor = this.hex3to6(fillColor);
                    }
                    this.fillColor = fillColor;
                }

                if (iconColor) {
                    if (iconColor.indexOf('#') !== -1) {
                        iconColor = this.hex3to6(iconColor);
                    }
                    this.iconColor = iconColor;
                }

                if (borderColor) {
                    if (borderColor.indexOf('#') !== -1) {
                        borderColor = this.hex3to6(borderColor);
                    }
                    this.borderColor = borderColor;
                }
            }
        },
        hex3to6(hexColor) {
            // Remove hash
            hexColor = hexColor.replace('#', '');
            // If it's a three digit hex make six
            if (hexColor.length === 3) {
                hexColor = hexColor.split('').map(function (hex) {
                    return hex + hex;
                }).join('');
            }
            return '#' + hexColor;
        },
        setIconUrlParams() {
            let url = new URL(this.customCategory.icon);
            let urlParams = new URLSearchParams(url.search);

            urlParams.set('fillColor', this.fillColor);
            urlParams.set('iconColor', this.iconColor);
            urlParams.set('borderColor', this.borderColor);

            if (! this.fillColor) {
                urlParams.delete('fillColor');
            }

            if (! this.iconColor) {
                urlParams.delete('iconColor');
            }

            if (! this.borderColor) {
                urlParams.delete('borderColor');
            }
            

            let newUrl = new URL(this.customCategory.icon);
            newUrl.search = urlParams.toString();
            this.customCategory.icon = newUrl.toString();
        },
        getIcons() {
            axios({
                method: 'get',
                url: process.env.VUE_APP_LOCATIONS_PATH + 'images/markers-custom/get-icons.php'
            })
            .then((response) => {
                //console.log(response);
                this.icons = response.data;
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
                this.$router.push('/');
            });
        },
        openIconsBrowser() {
            if (! this.showIconsBrowser) {
                this.showIconsBrowser = true;
                window.setTimeout(() => {
                    this.showIcons = true;
                }, 250);
            }
        },
        openColorSelector() {
            if (! this.customColorsSet) {
                this.iconColor = '#ffffff';
                this.fillColor = '#000000';
                this.borderColor = '#ffffff';
            }
            this.showColorSelector = true;
        },
        resetColors() {
            this.iconColor = null;
            this.fillColor = null;
            this.borderColor = null;
            this.showColorSelector = false;
        },
        getIconPath(icon) {
            return process.env.VUE_APP_LOCATIONS_PATH + 'vendor/icons-1.5.0/icons/' + icon;
        },
        selectIcon(icon) {
            this.previewIconLoaded = false;
            let iconName = icon.replace('.svg', '');
            this.customCategory.icon = process.env.VUE_APP_LOCATIONS_PATH + 'images/markers-custom/?icon=' + iconName;
            this.setIconUrlParams();
        },
        iconLoaded() {
            if (! this.iconsLoaded) {
                // Set iconsLoaded true when all icons is loaded
                this.iconsLoaded = true;
            }
        },
        /**
         * Validates and posts edit custom category form data.
         * 
         * @param   {Object}    e   Native MouseEvent object.
         */
        editCustomCategoryFormSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            }

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving custom category');
            store.commit('clearErrors');
            store.commit('clearMessages');

            let formData = new FormData(document.getElementById('editCustomCategoryForm'));

            axios({
                method: 'post',
                url: '/api/category',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.$emitter.emit('getCustomCategories');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
                store.commit('setModal', null);
            });
        },
        /**
         * Validates and posts add custom category translation form data.
         * 
         * @param   {Object}    e   Native MouseEvent object.
         */
        addTranslationFormSubmit(e) {
            e.preventDefault();
            e.stopPropagation();

            if (! e.target.checkValidity()) {
                return;
            }

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving category translation');
            store.commit('clearErrors');
            store.commit('clearMessages');

            let formData = new FormData(document.getElementById('addTranslationForm'));

            axios({
                method: 'post',
                url: '/api/category-translation',
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.addTranslationLan = null;
                    this.addTranslationName = null;
                    this.$emitter.emit('getCustomCategories');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Validates and posts edit custom category translation form data.
         * 
         * @param   {Object}    e   Native MouseEvent object.
         */
        editTranslationFormSubmit(e) {
            e.preventDefault();
            e.stopPropagation();

            if (! e.target.checkValidity()) {
                return;
            }

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving category translation');
            store.commit('clearErrors');
            store.commit('clearMessages');

            let formData = new FormData(document.getElementById('editTranslationForm'));

            axios({
                method: 'post',
                url: '/api/category-translation',
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.editTranslation = null;
                    this.$emitter.emit('getCustomCategories');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Deletes a category translation from the DB permanently.
         */
        deleteTranslation(catLan) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting translation');
            store.commit('clearErrors');
            store.commit('clearMessages');

            axios({
                method: 'delete',
                url: '/api/category/' + this.customCategory.id + '/' + this.activeWorkspaceId + '/' + catLan,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.$emitter.emit('getCustomCategories');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>
.icon-preview-container {
    position: relative;
    width: 80px;
    height: 80px;
    background-color: #ddd;
    background-image: url('../assets/images/checkerboard-bg.png');
    padding: 8px;
    border: 1px solid #ddd;
}
.icon-preview {
    max-width: 128px;
    height: auto;
}
.icons-browser {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    border: 1px solid #ddd;
    overflow-y: scroll;
}
.icons-browser-btn {
    background-color: transparent;
    padding: .5rem;
    border: none;
    cursor: pointer;
}
.icons-browser-btn:hover {
    background-color: #ddd;
}
</style>

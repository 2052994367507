<template>

    <div v-if="invitations && invitations.length" class="row mt-4 mx-1 mx-sm-0">
        <div class="col-12">

            <div class="card">
                <div class="card-header">
                    <BIconPersonPlusFill /> {{ $i18n('LABEL_INVITATIONS') }}
                </div>
                <div class="card-body">

                    <OverlaySpinner v-if="$loading()" />

                    <div class="alert alert-warning">
                        <BIconExclamationTriangleFill /> {{ $i18n('TEXT_NEW_INVITATIONS') }}
                    </div>
                    
                    <table class="table table-hover align-middle border-top">
                        <tbody>
                            <tr 
                                v-for="invitation in invitations"
                                :key="invitation.id+'-'+invitation.email">
                                <td class="shrink">{{ $formatDate(invitation.created) }}</td>
                                <td>
                                    <div v-html="getInvitationText(invitation)"></div>
                                </td>
                                <td class="text-end shrink">
                                    <DeleteConfirmButton 
                                        :showIcon="false"
                                        :label="$i18n('LABEL_DECLINE')"
                                        :confirmDeleteLabel="$i18n('LABEL_CONFIRM_DECLINE')"
                                        deleteEventName="declineInvitation" 
                                        :deleteEventPayload="invitation.id"
                                        btnAddClasses=""
                                    />
                                    <button @click="postInvitation(invitation.id, 'ACCEPTED')" type="button" class="btn btn-primary ms-2">
                                        {{ $i18n('LABEL_ACCEPT') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconExclamationTriangleFill, BIconPersonPlusFill } from 'bootstrap-icons-vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'Invitations',
    components: {
        BIconExclamationTriangleFill,
        BIconPersonPlusFill,
        DeleteConfirmButton,
        OverlaySpinner
    },
    props: {
        
    },
    data() {
        return {
            invitations: null
        }
    },
    created() {
        this.$emitter.on('declineInvitation', (id) => {
            this.postInvitation(id, 'DECLINED');
        });

        this.getInvitations();
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('declineInvitation');
    },
    methods: {
        getInvitationText(invitation) {
            let invitationText = this.$i18n('HTML_INVITATION_TEMPLATE');
            invitationText = invitationText.replace('[INVITATION_SENDER_NAME]', invitation.senderName);
            invitationText = invitationText.replace('[WORKSPACE_NAME]', invitation.workspaceName);
            return invitationText;
        },
        /**
         * Gets all user's pending invitations from the DB
         */
        getInvitations() {
            this.invitations = null;
            axios({
                method: 'get',
                url: '/api/user-invitations',
                params: {
                    email: store.state.user.email
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    this.invitations = response.data.invitations;
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            });
        },
        postInvitation(id, state) {
            store.commit('setLoading', true);

            let formData = new FormData();

            formData.append('id', id);
            formData.append('state', state);

            axios({
                method: 'post',
                url: '/api/user-invitation',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    if (state == 'ACCEPTED') {
                        store.commit('addModalMessage', this.$i18n('TEXT_USER_ACCEPTED_WORKSPACE_INVITE'));
                    } 
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.$emitter.emit('getWorkspaces');
                    this.getInvitations();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>
.card-header svg {
    position: relative;
    top: -2px;
}
</style>





<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_DEFAULT_CATEGORIES') }}</h5>
        <button type="button" @click="close()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <ul 
        v-if="locationsDbCategories && locationsDbCategories.length"
        class="list-unstyled">
        <li 
            v-for="(category, index) in locationsDbCategories" 
            :key="'item-'+index"
            class="mb-2">
            <img v-if="category.icon" :src="category.icon" class="list-category-img" :alt="category.name" />
            {{ category.name }}
        </li>
    </ul>

    </div> <!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button" @click="close()" class="btn btn-secondary">{{ $i18n('LABEL_CLOSE') }}</button>
    </div>

</template>

<script>
import store from '../store/store.js';

export default {
    name: 'LocationsDbCategoriesListModal',
    components: {
        
    },
    props: {
        
    },
    data() {
        return {
            
        }
    },
    computed: {
        locationsDbCategories() {
            return store.state.locationsDbCategories;
        }
    },
    methods: {
        close() {
            store.commit('setModal', null);
        }
    }
}
</script>

<style scoped>

</style>

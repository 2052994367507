<template>

    <form 
        id="resetPasswordForm"
        action="api/reset-password" 
        method="post" 
        class="needs-validation mb-3" 
        :class="{'was-validated': resetPasswordFormWasValidated}"
        @submit="postResetPassword($event)" 
        novalidate>
        <p v-if="email" class="form-text">Reset password for account {{ email }}</p>

        <div class="mb-3">
            <label for="newPassword" class="form-label visually-hidden">New password</label>
            <input v-model="newPassword" type="password" id="password" name="newPassword" class="form-control" minlength="10" placeholder="New password" required />
            <div class="invalid-feedback">
                This field is required (minimum length 10 characters).
            </div>
        </div>

        <div class="mb-3">
            <label for="newPasswordAgain" class="form-label visually-hidden">New password again</label>
            <input v-model="newPasswordAgain" type="password" id="newPasswordAgain" name="newPasswordAgain" class="form-control" placeholder="New password again" :pattern="newPassword" required />
            <div class="invalid-feedback">
                This field is required (must match new password).
            </div>
        </div>

        <input v-model="token" type="hidden" id="token" name="token" />

        <button type="submit" class="btn btn-primary btn-block">Save new password</button>
    </form> <!-- /#resetPasswordForm -->

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'ResetPasswordForm',
    data() {
        return {
            email: null,
            resetPasswordFormWasValidated: false,
            newPassword: null,
            newPasswordAgain: null,
            token: null
        }
    },
    created() {
        // check token if we're on ResetPassword route
        if (this.$route.name == 'ResetPassword') {
            if (! this.$route.params.token) {
                // No token, redirect to login
                this.$router.push('/');
            }
        }
    },
    mounted() {
        if (this.$route.params.token) {
            this.token = this.$route.params.token;
            this.verifyToken(this.token);
        } else {
            store.commit('setLoading', false);
        }
    },
    methods: {
        /**
         * Sends the token to the server for verification.
         * 
         * @param   {string}    token
         */
        verifyToken(token) {
            let formData = new FormData();
            formData.append('token', token);
            store.commit('setLoading', true);

            axios({
                method: 'post',
                url: '/api/verify-token',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') 
                {
                    // All ok, token verified
                    this.email = response.data.email;
                } 
                else if (response.data.errors) 
                {
                    store.commit('setModalErrors', response.data.errors);
                    // Invalid token, redirect to login
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Post token and new password to the server vor verification and validation.
         * Sets new password and redicets to login with success or error message.
         * 
         * @param   {object}    e   Native event object.
         */
        postResetPassword(e) {
            e.preventDefault();
            e.stopPropagation();
            this.resetPasswordFormWasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Resetting password');

            let formData = new FormData(e.target);

            axios({
                method: 'post',
                url: '/api/reset-password',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setModalMessages', response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                // Redirect to login
                this.$router.push('/');
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>
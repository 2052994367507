<template>
    <div class="position-relative bg-white p-3 my-3 shadow-sm">
        
        <OverlaySpinner 
            v-if="$loading()"
            :showStatusText="true" 
            :statusText="$loadingStatus()"
        />

        <h1>{{ $i18n('LABEL_ROUTES') }}</h1>
        
        <Alerts />

        <div v-if="!routes.length && !$loading" class="alert alert-info">
            You don't have any routes yet. Start creating a new route by clicking the button below.
        </div>

        <div v-if="routes && routes.length >= config.freeRoutesLimit && ! $hasBasicSubscription()" v-html="$i18n('HTML_ROUTES_LIMIT_REACHED')" class="alert alert-danger"></div>
        <button v-else type="button" @click="openAddNewRoute()" class="btn btn-primary mb-3">
            Add new route
        </button>

        <table v-if="routes.length" class="table table-hover align-middle my-4">
            <thead class="bg-dark text-white">
                <tr>
                    <th scope="col">{{ $i18n('LABEL_TITLE') }}</th>
                    <th scope="col">{{ $i18n('LABEL_CATEGORIES') }}</th>
                    <th scope="col">{{ $i18n('LABEL_LANGUAGE') }}</th>
                    <th scope="col">{{ $i18n('LABEL_TRANSLATIONS') }}</th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_DELETE') }}</span>
                    </th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="route in routes"
                    :key="route.id">
                    <td>
                        {{ route.title }}
                        <div v-if="getRouteEditing(route.id)" class="d-flex align-items-center small text-muted">
                            <BIconLockFill /> {{ getRouteEditing(route.id).userName }} {{ $i18n('LABEL_IS_EDITING') }} 
                        </div>
                    </td>
                    <td>
                        <img 
                            v-for="category in route.categories" 
                            :key="'route-category-' + category.id" 
                            :src="category.icon"
                            class="list-category-img"
                            :class="{'d-none': ! category.icon}"
                            :alt="category.name" />
                    </td>
                    <td>
                        {{ route.lan.toUpperCase() }}
                    </td>
                    <td>
                        {{  route.availableTranslations.map(lan => lan.toUpperCase()).join(', ') }}
                    </td>
                    <td class="text-end shrink">
                        <DeleteConfirmButton v-if="!getRouteEditing(route.id)" deleteEventName ="deleteRoute" :deleteEventPayload="route.id" />
                    </td>
                    <td class="text-end shrink">
                        <button v-if="!getRouteEditing(route.id)" @click="openEditRoute(route.id)" class="btn btn-primary btn-sm">
                            <BIconPencilFill />
                            <span class="visually-hidden">Edit</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconLockFill, BIconPencilFill } from 'bootstrap-icons-vue';
import Alerts from '../components/Alerts.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'Routes',
    components: {
        Alerts,
        BIconLockFill,
        BIconPencilFill, 
        DeleteConfirmButton,
        OverlaySpinner
    },
    data() {
        return {
            routes: [],
            confirmRouteDelete: null
        }
    },
    computed: {
        config() {
            return store.state.config;
        },
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        routeEditings() {
            if (store.state.editings) {
                return store.state.editings.filter(item => item.routeId != null);
            }
            return null;
        }
    },
    watch: {
        activeWorkspaceId() {
            this.getRoutes();
        }
    },
    created() {
        this.$emitter.on('getItems', () => {
            this.getRoutes();
        });

        this.$emitter.on('deleteRoute', (id) => {
            this.deleteRoute(id);
        });

        this.getRoutes();
    },
    mounted() {
        store.commit('clearErrors');
        store.commit('clearMessages');
    },
    methods: {
        getRouteEditing(routeId) {
            if (this.routeEditings) {
                let editing = this.routeEditings.find(item => item.routeId == routeId);
                if (editing) {
                    return editing;
                }
            }
            return null;
        },
        /**
         * Gets all user's routes from Locations DB and sets them to this.routes.
         */
        getRoutes() {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Loading routes');
            
            axios.get('/api/routes', {
                params: {
                    workspaceId: this.activeWorkspaceId
                }
            })
            .then((response) => {
                this.routes = [];
                if (response.data.routes.length) {
                    this.routes = response.data.routes;
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        openAddNewRoute() {
            store.commit('setModal', {
                component: 'ItemEdit',
                componentProps: {
                    itemType: 'route'
                },
                size: 'modal-lg',
                fullHeight: true
            });
        },
        openEditRoute(routeId) {
            store.commit('setModal', {
                component: 'ItemEdit',
                componentProps: {
                    itemType: 'route',
                    initRoute: this.routes.find((route) => route.id == routeId)
                },
                size: 'modal-lg',
                fullHeight: true
            });
        },
        /**
         * Deletes a route from the DB permanently.
         */
        deleteRoute(routeId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting route');

            axios({
                method: 'delete',
                url: '/api/route/' + routeId,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getRoutes();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>
<template>

    <div :class="{'small': small}">
       <div v-if="billingAddress.company">
            {{ billingAddress.company }} ({{ $i18n('LABEL_BIC_ABBR') }}: {{ billingAddress.bic }})
        </div>
        <div v-if="billingAddress.firstName || billingAddress.lastName">
            {{ billingAddress.firstName }} {{ billingAddress.lastName }}
        </div>
        <div>{{ billingAddress.address }}</div>
        <div>{{ billingAddress.postalCode }} {{ billingAddress.postOffice }}</div>
        <div>{{ billingAddress.country }}</div>
        <div class="mt-2">
            {{ $i18n('LABEL_INVOICE_TYPE') }}:
            <span v-if="billingAddress.invoiceType=='email'">
                {{ $i18n('LABEL_EMAIL') }}
                ({{ billingAddress.email }})
            </span>
            <span v-if="billingAddress.invoiceType=='einvoice'">
                {{ $i18n('LABEL_E_INVOICE') }}
                ({{ billingAddress.eInvoiceAddress }})
            </span>
        </div>
    </div>

</template>

<script>

export default {
    name: 'BillingInfo',
    components: {

    },
    props: {
        billingAddress: {
            type: Object
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    computed: {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
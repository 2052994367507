<template>

    <div class="row">
        <div class="col-xl">

            <h3 class="h4">{{ $i18n('LABEL_MAP_LAYERS_USED_ON_MAP') }}</h3>

            <p class="form-text">
                {{ $i18n('TEXT_MAP_LAYERS') }}
            </p>

            <draggable 
                :list="modelValue.mapLayers" 
                @change="setMapConfig()"
                class="draggable list-group mb-3">
                <div
                    class="list-group-item bg-light d-flex align-items-center"
                    v-for="(mapLayer) in modelValue.mapLayers"
                    :key="mapLayer.name">
                    <div class="flex-grow-1">
                        <strong>{{ getMapLayerName(mapLayer.name) }}</strong><br />
                        <span v-if="mapLayer.minZoom||mapLayer.maxZoom" class="form-text">Zoom levels {{ mapLayer.minZoom }} - {{ mapLayer.maxZoom }}</span>
                    </div>
                    <button 
                        v-if="mapLayer.test" 
                        @click="removeMapLayer(mapLayer.name)"
                        type="button" 
                        class="btn btn-danger">
                        {{ $i18n('LABEL_END_TESTING') }}
                    </button>
                    <button 
                        v-if="! isFreeMapLayer(mapLayer.name) && ! mapLayer.test" 
                        @click="removeMapLayer(mapLayer.name)"
                        type="button" 
                        class="btn btn-danger">
                        {{ $i18n('LABEL_REMOVE') }}
                    </button>
                </div>
            </draggable>
            
            <div v-if="modelValue.overlayLayers && modelValue.overlayLayers.length > 0" class="my-4">
                <h3 class="h4">{{ $i18n('LABEL_OVERLAY_LAYERS_USED_ON_MAP') }}</h3>

                <draggable 
                    :list="modelValue.overlayLayers" 
                    @change="setMapConfig()"
                    class="draggable list-group mb-3">
                    <div
                        class="list-group-item bg-light d-flex align-items-center"
                        v-for="(mapLayer) in modelValue.overlayLayers"
                        :key="mapLayer.name">
                        <div class="form-check">
                            <input type="checkbox" v-model="mapLayer.checked" class="form-check-input" :value="true" />
                        </div>
                        <div class="flex-grow-1">
                            <strong>{{ getMapLayerName(mapLayer.name) }}</strong><br />
                            <span v-if="mapLayer.minZoom||mapLayer.maxZoom" class="form-text">Zoom levels {{ mapLayer.minZoom }} - {{ mapLayer.maxZoom }}</span>
                        </div>
                        <button 
                            v-if="mapLayer.test" 
                            @click="removeOverlayLayer(mapLayer.name)"
                            type="button" 
                            class="btn btn-danger">
                            {{ $i18n('LABEL_END_TESTING') }}
                        </button>
                        <button 
                            v-if="! mapLayer.test" 
                            @click="removeOverlayLayer(mapLayer.name)"
                            type="button" 
                            class="btn btn-danger">
                            {{ $i18n('LABEL_REMOVE') }}
                        </button>
                    </div>
                </draggable>
            </div>

        </div>

        <div class="col-xl position-relative">
            <div v-if="mapLayerProducts && mapLayerProducts.length > 0">
                <OverlaySpinner v-if="! products" />
                
                <h3 class="h4">{{ $i18n('LABEL_PURCHASE_BASE_MAPS') }}</h3>
                
                <ul v-if="userMapLayerProductsData" class="list-group">
                    <li 
                        v-for="product in mapLayerProducts"
                        :key="'map-layer-' + product.id"
                        class="list-group-item bg-light d-flex align-items-center pt-2">
                        <div class="flex-grow-1">
                            <h4 class="h5 m-0">{{ getProductName(product.id) }}</h4>
                            <p class="lh-sm mb-2">
                                <small v-if="getProductDescription(product.id)" class="text-muted">
                                {{ getProductDescription(product.id) }}
                                </small>
                            </p>
                            <!-- Show prices only if user has not purchased the product already -->
                            <div v-if="! userHasProduct(product.id)">
                                <p v-if="product.price" class="m-0">
                                    <small><strong>{{ product.price.toFixed(2) }} &euro;</strong></small>
                                </p>
                                <p v-if="product.pricePerMonth || product.pricePerYear" class="m-0">
                                    <small><strong v-if="product.pricePerMonth">{{ product.pricePerMonth.toFixed(2) }} &euro;</strong>/{{ $i18n('LABEL_PER_MONTH') }} (+ {{ $i18n('LABEL_VAT') }})</small>
                                    <br v-if="product.pricePerMonth && product.pricePerYear" />
                                    <small><strong v-if="product.pricePerYear">{{ product.pricePerYear.toFixed(2) }} &euro;</strong>/{{ $i18n('LABEL_PER_YEAR') }} (+ {{ $i18n('LABEL_VAT') }})</small>
                                </p>
                            </div>
                        </div>
                        <button 
                            v-if="userHasProduct(product.id)" 
                            @click="useMapLayer(product.data)"
                            type="button" 
                            class="btn btn-primary ms-3"
                            :disabled="mapLayerIsInUse(product.data.name)">
                            {{ $i18n('LABEL_USE') }}
                        </button>
                        <div v-if="! userHasProduct(product.id)" class="d-grid gap-2 ms-3">
                            <button 
                                v-if="! mapLayerIsTesting(product.data.name)"
                                @click="testMapLayer(product.data)" 
                                type="button" 
                                class="btn btn-primary">
                                {{ $i18n('LABEL_TEST') }}
                            </button>
                            <button 
                                v-if="mapLayerIsTesting(product.data.name)" 
                                @click="removeMapLayer(product.data.name)"
                                type="button" 
                                class="btn btn-danger text-nowrap">
                                {{ $i18n('LABEL_END_TESTING') }}
                            </button>
                            <router-link :to="getProductLink(product)" class="btn btn-primary">{{ $i18n('LABEL_BUY') }}</router-link>
                        </div>
                    </li>
                </ul>

            </div>
        
            <div v-if="overlayLayerProducts && overlayLayerProducts.length > 0" class="my-4">
                <OverlaySpinner v-if="! products" />
                
                <h3 class="h4">{{ $i18n('LABEL_PURCHASE_OVERLAY_MAPS') }}</h3>

                <ul v-if="userOverlayLayerProductsData" class="list-group">
                    <li 
                        v-for="product in overlayLayerProducts"
                        :key="'map-layer-' + product.id"
                        class="list-group-item bg-light d-flex align-items-center pt-2">
                        <div class="flex-grow-1">
                            <h4 class="h5 m-0">{{ getProductName(product.id) }}</h4>
                            <p class="lh-sm mb-2">
                                <small v-if="getProductDescription(product.id)" class="text-muted">
                                {{ getProductDescription(product.id) }}
                                </small>
                            </p>
                            <!-- Show prices only if user has not purchased the product already -->
                            <div v-if="! userHasProduct(product.id)">
                                <p v-if="product.price" class="m-0">
                                    <small><strong>{{ product.price.toFixed(2) }} &euro;</strong></small>
                                </p>
                                <p v-if="product.pricePerMonth || product.pricePerYear" class="m-0">
                                    <small><strong v-if="product.pricePerMonth">{{ product.pricePerMonth.toFixed(2) }} &euro;</strong>/{{ $i18n('LABEL_PER_MONTH') }} (+ {{ $i18n('LABEL_VAT') }})</small>
                                    <br v-if="product.pricePerMonth && product.pricePerYear" />
                                    <small><strong v-if="product.pricePerYear">{{ product.pricePerYear.toFixed(2) }} &euro;</strong>/{{ $i18n('LABEL_PER_YEAR') }} (+ {{ $i18n('LABEL_VAT') }})</small>
                                </p>
                            </div>
                        </div>
                        <button 
                            v-if="userHasProduct(product.id)" 
                            @click="useOverlayLayer(product.data)"
                            type="button" 
                            class="btn btn-primary ms-3"
                            :disabled="overlayLayerIsInUse(product.data.name)">
                            {{ $i18n('LABEL_USE') }}
                        </button>
                        <div v-if="! userHasProduct(product.id)" class="d-grid gap-2 ms-3">
                            <button 
                                v-if="! overlayLayerIsTesting(product.data.name)"
                                @click="testOverlayLayer(product.data)" 
                                type="button" 
                                class="btn btn-primary">
                                {{ $i18n('LABEL_TEST') }}
                            </button>
                            <button 
                                v-if="overlayLayerIsTesting(product.data.name)" 
                                @click="removeOverlayLayer(product.data.name)"
                                type="button" 
                                class="btn btn-danger text-nowrap">
                                {{ $i18n('LABEL_END_TESTING') }}
                            </button>
                            <router-link :to="getProductLink(product)" class="btn btn-primary">{{ $i18n('LABEL_BUY') }}</router-link>
                        </div>
                    </li>
                </ul>
            </div>

        </div>

    </div> <!-- /.row -->

</template>

<script>

import store from '../store/store.js';
import { VueDraggableNext } from 'vue-draggable-next';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'MapEditMapLayers',
    components: {
        draggable: VueDraggableNext,
        OverlaySpinner
    },
    props: {
        modelValue: {
            type: Object
        }
    },
    data() {
        return {
            mapLayers: this.userMapLayerProductsData,
            overlayLayers: this.userOverlayLayerProductsData
        }
    },
    computed: {
        products() {
            return store.state.products;
        },
        mapLayerProducts() {
            if (store.state.products) {
                return store.state.products.filter((item) => item.categoryId == 2);
            }
            return null;
        },
        overlayLayerProducts() {
            if (store.state.products) {
                return store.state.products.filter((item) => item.categoryId == 4);
            }
            return null;
        },
        userMapLayerProducts() {
            if (store.state.products && store.state.subscriptions) {
                return store.state.products.filter((item) => {
                    return item.categoryId == 2 && this.userHasProduct(item.id);
                });
            }
            return null;
        },
        userOverlayLayerProducts() {
            if (store.state.products && store.state.subscriptions) {
                return store.state.products.filter((item) => {
                    return item.categoryId == 4 && this.userHasProduct(item.id);
                });
            }
            return null;
        },
        userMapLayerProductsData() {
            if (this.userMapLayerProducts) {
                let dataArr = [];
                this.userMapLayerProducts.forEach(item => {
                    dataArr.push(item.data);
                });
                return dataArr;
            }
            return null;
        },
        userOverlayLayerProductsData() {
            if (this.userOverlayLayerProducts) {
                let dataArr = [];
                this.userOverlayLayerProducts.forEach(item => {
                    dataArr.push(item.data);
                });
                return dataArr;
            }
            return null;
        }
    },
    methods: {
        /**
         * @param   {string}    name
         * @returns {string}
         */
        getMapLayerName(name) {
            return this.$i18n('LABEL_' + name.toUpperCase());
        },
        /**
         * @param   {number}    id
         * @returns {string}
         */
        getProductName(id) {
            return this.$i18n('LABEL_PRODUCT_' + id);
        },
        /**
         * @param   {number}    id
         * @returns {string}
         */
        getProductDescription(id) {
            if (this.$i18nExists('TEXT_PRODUCT_' + id)) {
                return this.$i18n('TEXT_PRODUCT_' + id);
            }
            return null;
        },
        /**
         * @param   {object}    product
         * @returns {string}
         */
        getProductLink(product) {
            return '/shop/' + product.id + '/' + product.name;
        },
        setMapConfig() {
            this.$emitter.emit('setMapConfig');
        },
        isFreeMapLayer(name) {
            return name == 'openstreetmap' || name == 'opentopomap';
        },
        userHasProduct(productId) {
            if (store.state.subscriptions) {
                return store.state.subscriptions.find(item => item.productId == productId && item.ended == null);
            }
            return false;
        },
        mapLayerIsTesting(name) {
            return this.modelValue.mapLayers.find((item) => {
                return item.test && item.name == name;
            });
        },
        overlayLayerIsTesting(name) {
            if (this.modelValue.overlayLayers) {
                return this.modelValue.overlayLayers.find((item) => {
                    return item.test && item.name == name;
                });
            }
            return false;
        },
        mapLayerIsInUse(name) {
            return this.modelValue.mapLayers.find((item) => {
                return item.name == name;
            });
        },
        overlayLayerIsInUse(name) {
            if (this.modelValue.overlayLayers) {
                return this.modelValue.overlayLayers.find((item) => {
                    return item.name == name;
                });
            }
            return false;
        },
        testMapLayer(data) {
            this.$emitter.emit('testMapLayer', data);
        },
        testOverlayLayer(data) {
            this.$emitter.emit('testOverlayLayer', data);
        },
        useMapLayer(data) {
            this.$emitter.emit('useMapLayer', data);
        },
        useOverlayLayer(data) {
            this.$emitter.emit('useOverlayLayer', data);
        },
        removeMapLayer(name) {
            this.$emitter.emit('removeMapLayer', name);
        },
        removeOverlayLayer(name) {
            this.$emitter.emit('removeOverlayLayer', name);
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title">{{ $i18n('LABEL_CREATE_WORKSPACE') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div id="modalBody" class="modal-body">
            
        <form 
            action="api/workspace" 
            method="post"  
            id="createWorkspaceForm"
            @submit="postWorkspace($event)" 
            autocomplete="off"
            novalidate
        >

            <div class="mb-3">
                <label for="newWorkspaceName" class="form-label">{{ $i18n('LABEL_WORKSPACE_NAME') }}</label>
                <input v-model="newWorkspaceName" type="text" id="newWorkspaceName" name="name" class="form-control" required />
            </div>

            <input type="hidden" name="userId" :value="$user().id" />

            <button type="submit" id="createWorkspaceBtn" class="visually-hidden">
                {{ $i18n('LABEL_SAVE') }}
            </button>

        </form>

    </div> <!-- /.modal-body -->
    
    <div class="modal-footer">


        <button type="button" @click="cancel()" class="btn btn-secondary ms-auto me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="submit()" :disabled="!newWorkspaceName" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'WorkspaceCreateNew',
    data() {
        return {
            newWorkspaceName: null
        }
    },
    computed: {
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        }
    },
    methods: {
        cancel() {
            store.commit('setModal', null);
        },
        submit() {
            //document.getElementById('createWorkspaceBtn').requestSubmit();
            document.getElementById('createWorkspaceBtn').click();
        },
        /**
         * 
         */
        postWorkspace(e) {
            e.preventDefault();
            e.stopPropagation();

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving');

            let formData = new FormData(document.getElementById('createWorkspaceForm'));
            
            axios({
                method: 'post',
                url: '/api/workspace',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    // Set new workspace active
                    this.$emitter.emit('changeWorkspace', response.data.workspace.id);
                    store.commit('setModal', null);
                    // Redirect to workspace settings
                    this.$router.push('/workspace/workspace-info');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="text-container">
        <OverlaySpinner v-if="loading" />
        <div v-html="text"></div>
    </div>
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'Texts',
    components: {
        OverlaySpinner
    },
    props: {
        id: {
            type: String,
            required: true
        },
        replaces: Object
    },
    data() {
        return {
            loading: true,
            text: null
        }
    },
    computed: {
        lan() {
            return store.state.lan;
        }
    },
    created() {
        // Event listener for language change
        this.$emitter.on('lanChange', () => {
            this.loading = true;
            this.text = null;
            this.getText();
        });
        
        this.getText();
    },
    methods: {
        /**
         * Gets texts from the db with this id and lan.
         */
        getText() {
            axios.get('/api/texts/' + this.id, {
                params: {
                    lan: this.lan
                }
            })
            .then((response) => {
                if (response.data.text) {
                    this.text = this.doReplaces(response.data.text.text);
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading text failed');
                console.error(error);
            })
            .then(() => {
                this.loading = false;
            });
        },
        /**
         * Inserts names etc. variable stuff to text by this.replaces prop name and value.
         * @param   {string}    text   
         * */
        doReplaces(text) {
            if (this.replaces) {
                for (let prop in this.replaces) {
                    text = text.replace(prop, this.replaces[prop]);
                }
            }
            return text;
        }
    }
}
</script>

<style scoped>
.text-container {
    position: relative;
    min-height: 2rem;
}
.mh-none {
    min-height: 0;
}
</style>

<template>

    <div id="mapEditOptionsAppearance" class="my-4">

        <h3>{{ $i18n('LABEL_APPEARANCE') }}</h3>

        <div class="row">
            
            <div class="col-lg-6">
                <div class="mb-3">
                    <label for="theme" class="form-label">{{ $i18n('LABEL_MAP_SIZE') }}</label>
                    <select v-model="mapData.config.boxed" id="boxed" name="boxed" class="form-select">
                        <option :value="false" selected>{{ $i18n('LABEL_MAP_SIZE_FILL_VIEWPORT') }}</option>
                        <option :value="true">{{ $i18n('LABEL_MAP_SIZE_BOXED') }}</option>
                    </select>
                    <div v-if="! mapData.config.boxed" class="form-text">
                        {{ $i18n('TEXT_MAP_SIZE_FILL_VIEWPORT') }}
                    </div>
                    <div v-if="mapData.config.boxed" class="form-text">
                        {{ $i18n('TEXT_MAP_SIZE_BOXED') }}
                    </div>
                </div>
            </div>
            
            <div v-if="mapData.config.boxed" class="col-lg-3">
                <div class="mb-3">
                    <label for="boxedWidth" class="form-label">{{ $i18n('LABEL_WIDTH') }}</label>
                    <input v-model="mapData.config.boxedWidth" type="text" id="boxedWidth" class="form-control" placeholder="auto" />
                </div>
            </div>

            <div v-if="mapData.config.boxed" class="col-lg-3">
                <div class="mb-3">
                    <label for="boxedHeight" class="form-label">{{ $i18n('LABEL_HEIGHT') }}</label>
                    <input v-model="mapData.config.boxedHeight" type="text" id="boxedHeight" class="form-control" placeholder="450px" />
                </div>
            </div>

        </div> <!-- /.row -->

        <div class="row">
            
            <div class="col-lg-6">

                <div class="mb-3">
                    <label for="theme" class="form-label">{{ $i18n('LABEL_THEME') }}</label>
                    <select v-model="mapData.config.theme" id="theme" name="theme" class="form-select">
                        <option value="light">{{ $i18n('LABEL_THEME_LIGHT') }}</option>
                        <option value="dark">{{ $i18n('LABEL_THEME_DARK') }}</option>
                    </select>
                </div>

            </div>

            <div class="col-lg-6">

                <!-- Use native color input if the browser supports it -->
                <div v-if="$supportsInputType('color')" class="mb-3">
                    <label for="themeColor" class="form-label">{{ $i18n('LABEL_THEME_COLOR') }}</label>
                    <input v-model="mapData.config.themeColor" type="color" id="themeColor" class="form-control p-0 border-0" />
                    <div class="form-text">{{ $i18n('TEXT_CLICK_COLOR_BAR') }}</div>
                </div>

                <!-- Color input type not supported, use text input with custom validation -->
                <div v-if="! $supportsInputType('color')" class="mb-3">
                    <label for="themeColor" class="form-label">{{ $i18n('LABEL_THEME_COLOR') }}</label>
                    <input v-model="mapData.config.themeColor" type="text" id="themeColor" class="form-control" pattern="(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))" />
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_ENTER_VALID_COLOR') }}
                    </div>
                </div>

            </div>

        </div> <!-- /.row -->

    </div> <!-- /#mapEditOptionsAppearance -->

</template>

<script>

export default {
    name: 'MapEditOptionsAppearance',
    components: {
        
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        mapData() {
            return this.modelValue;
        }
    },
    methods: {
       
    }
}
</script>

<style scoped>

</style>

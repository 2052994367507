<template>

    <div class="d-flex justify-content-between align-items-center mb-3">
        <label for="domainCount" class="small w-100">{{ $i18n('LABEL_DOMAINS_AMOUNT') }}</label>
        <div class="input-group justify-content-end flex-nowrap">
            <button @click="decrease" type="button" class="btn btn-primary btn-sm" :disabled="decreaseDisabled">-</button>
            <input 
            :value="modelValue" 
            type="text" 
            class="form-control form-control-sm numeric-stepper-input"
            :min="min"
            :max="max" 
            :readonly="readonly" 
            :disabled="disabled"/>
            <button @click="increase" type="button" class="btn btn-primary btn-sm" :disabled="increaseDisabled">+</button>
        </div>
    </div>

</template>

<script>

export default {
    name: 'NumericStepper',
    props: {
        changeEventName: String,
        modelValue: {
            type: Number,
            default: 1
        },
        min: {
            type: Number,
            default: 1
        },
        max: {
            type: Number,
            default: null
        },
        readonly: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        decreaseDisabled() {
            return this.disabled || (this.modelValue <= this.min && this.min != null);
        },
        increaseDisabled() {
            return this.disabled || (this.modelValue >= this.max && this.max != null);
        }
    },
    methods: {
        decrease() {
            this.$emitter.emit(this.changeEventName, -1);
        },
        increase() {
            this.$emitter.emit(this.changeEventName, 1);
        }
    }
}
</script>

<style scoped>
.numeric-stepper-input {
    flex: 0 0 36px !important;
    text-align: center;
}
</style>

<template>

   <div id="mapEditOptionsWelcomeMessage" class="my-4">

        <div class="row">

            <div class="col-lg-6">

                <h3>{{ $i18n('LABEL_WELCOME_MESSAGE') }}</h3>

                <Text id="TEXT_WELCOME_MESSAGE" />

                <div v-for="(text, textLan) in mapData.config.welcomeMessage" :key="textLan">

                    <div class="mb-3">
                        <label :for="'title-'+textLan" class="form-label">{{ $i18n('LABEL_WELCOME_MESSAGE_TITLE') }} ({{ textLan.toUpperCase() }})</label>
                        <input v-model="text.title" :id="'title-'+textLan" class="form-control" />
                    </div>

                    <div class="mb-3">
                        <label :for="'body-'+textLan" class="form-label">{{ $i18n('LABEL_WELCOME_MESSAGE_BODY') }} ({{ textLan.toUpperCase() }})</label>
                        <textarea v-model="text.body" :id="'body-'+textLan" rows="5" cols="50" class="form-control"></textarea>
                    </div>

                    <div class="mb-3">
                        <label :for="'btnLabel-'+textLan" class="form-label">{{ $i18n('LABEL_WELCOME_MESSAGE_BTN_LABEL') }} ({{ textLan.toUpperCase() }})</label>
                        <input v-model="text.btnLabel" :id="'btnLabel-'+textLan" class="form-control" />
                    </div>

                    <div class="mb-3">
                        <DeleteConfirmButton :label="$i18n('LABEL_DELETE WELCOME_MESSAGE')+' ('+textLan.toUpperCase()+')'" deleteEventName ="deleteWelcomeMessage" :deleteEventPayload="textLan" />
                    </div>

                </div>

            </div>

        </div> <!-- /.row -->

        <div class="row">

            <div class="col-lg-6">

                <div class="input-group my-3">
                    <select v-model="addwelcomeMessageLan" class="form-select">
                        <option :value="null">{{ $i18n('LABEL_SELECT_LANGUAGE') }}</option>
                        <option disabled>──────────</option>
                        <option value="fi" :disabled="welcomeMessageLanExists('fi')">Finnish</option>
                        <option value="en" :disabled="welcomeMessageLanExists('en')">English</option>
                        <option disabled>──────────</option>
                        <option 
                            v-for="(lanItem, lanKey) in languages"
                            :key="lanKey"
                            :value="lanKey"
                            :disabled="welcomeMessageLanExists(lanKey)">
                                {{ lanItem.name }} ({{ lanItem.nativeName }})
                        </option>
                    </select>
                    <button 
                        @click="addWelcomeMessage()" 
                        type="button" 
                        class="btn btn-primary" 
                        :disabled="!addwelcomeMessageLan">
                        {{ $i18n('LABEL_ADD_WELCOME_MESSAGE') }}
                    </button>
                </div>

            </div>

        </div> <!-- /.row -->

    </div> <!-- /#mapEditOptionsWelcomeMessage -->

</template>

<script>
import languages from '../assets/data/languages.json';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import Text from '../components/Text.vue';

export default {
    name: 'MapEditOptionsWelcomeMessage',
    components: {
        DeleteConfirmButton,
        Text
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            addwelcomeMessageLan: null,
            languages: languages
        }
    },
    computed: {
        mapData() {
            return this.modelValue;
        }
    },
    methods: {
       /**
         * @param   {string}    lanKey
         * @return  {boolean}
         */
        welcomeMessageLanExists(lanKey) {
            return this.mapData.config.welcomeMessage && this.mapData.config.welcomeMessage[lanKey];
        },
        /**
         * Adds a new language to config welcomeMessage.
         */
        addWelcomeMessage() {
            if (! this.mapData.config.welcomeMessage) {
                this.mapData.config.welcomeMessage = {};
            }
            this.mapData.config.welcomeMessage[this.addwelcomeMessageLan] = {
                title: null,
                body: null,
                btnLabel: null
            };
            this.addwelcomeMessageLan = null;
        }
    }
}
</script>

<style scoped>

</style>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import mitt from 'mitt';
import globals from './globals.js';

import './assets/scss/bootstrap-matkailukartta.scss';
import './assets/css/common.css';

// Global emitter to communicate with the map app
window.emitter = mitt();

window.karttamapDashboard = {};
window.karttamapDashboard = createApp(App);
window.karttamapDashboard.use(router);
window.karttamapDashboard.use(globals);

window.karttamapDashboard = window.karttamapDashboard.mount('#app');
<template>

    <form 
        id="orderForm"
        action="api/order" 
        method="post" 
        class="needs-validation" 
        :class="{'was-validated': wasValidated}"
        @submit="postOrder($event)" 
        novalidate
    >

        <h2 class="mt-3">{{ $i18n('LABEL_CONFIRM_ORDER') }}</h2>

        <div v-if="! cart.length">
            <p>{{ $i18n('TEXT_SHOPPING_CART_EMPTY') }}</p>
            <p><router-link to="/shop">{{ $i18n('LABEL_BACK_TO_SHOP_MAIN') }}</router-link> </p>
        </div>

        <div v-if="cart.length">
            
            <div class="row">

                <div class="col-lg-6 mb-3 position-relative">
                    <OverlaySpinner v-if="!billingAddresses" />
                    <h3>{{ $i18n('LABEL_BILLING_ADDRESS') }}</h3>
                    
                    <div v-if="defaultBillingAddress">
                        <BillingInfo :billingAddress="defaultBillingAddress" />
                    </div>

                    <div v-if="billingAddresses && !defaultBillingAddress">
                        <p>Et ole vielä määrittänyt laskutusosoitetta.</p>
                        <button @click="openBillingAddressModal()" type="button" class="btn btn-primary">Anna laskutusosoite</button>
                    </div>

                </div>

                <div class="col-lg-6">
                    <h3>{{ $i18n('LABEL_SHOPPING_CART') }}</h3>

                    <ShopCartTable />
                    
                    <div class="input-group">
                        <input 
                            v-model="newDiscountCode"
                            @keypress="discountCodeKeyPress($event)"
                            type="text" 
                            name="discountCode"
                            class="form-control" 
                            :placeholder="$i18n('LABEL_INSERT_DISCOUNT_CODE')"
                            :disabled="checkDiscountCodeDisabled"
                        />
                        <button v-if="!discountCode" @click="checkDiscountCode()" type="button" class="btn btn-primary" :disabled="checkDiscountCodeBtnDisabled">{{ $i18n('LABEL_APPLY') }}</button>

                         <button v-if="discountCode" @click="removeDiscountCode()" type="button" class="btn btn-danger">{{ $i18n('LABEL_REMOVE') }}</button>

                    </div>

                </div>

            </div> <!-- /.row -->

            <div v-if="basicSubscription" class="my-3">
                <h3>{{ $i18n('LABEL_DOMAINS') }}</h3>
                <p>{{ $i18n('TEXT_DOMAINS') }}</p>
                
                <div class="domains-list-container">
                    <div v-for="(domain, index) in domains"
                        :key="'domain-'+index"
                        class="input-group mb-2"
                    >
                        <input 
                            v-model="domains[index]" 
                            type="text" 
                            :name="'domains['+index+']'"
                            class="form-control" 
                            :class="{'is-invalid': !domainValid(domain)}"
                            :placeholder="domainPlaceholder(index)"
                            required
                        />
                        <DeleteConfirmButton v-if="index" deleteEventName="removeDomain" :deleteEventPayload="index" />
                    </div>
                    <div class="text-end">
                        <button @click="addDomain()" type="button" class="btn btn-primary btn-sm">+ {{ $i18n('LABEL_ADD_DOMAIN') }}</button>
                    </div>
                </div>
            </div>

            <div class="form-check my-3">
                <input v-model="terms" id="terms" class="form-check-input" type="checkbox" value="true" required />
                <label for="terms" class="form-check-label">
                    * {{ $i18n('LABEL_I_HAVE_READ') }} <a href="#" @click="$emitter.emit('openHelpText', 'HTML_TERMS_AND_CONDITIONS')">{{ $i18n('LABEL_TERMS_AND_CONDITIONS') }}</a>
                </label>
            </div>

            <div class="text-center">
                <button type="submit" class="btn btn-primary btn-lg" :disabled="confirmOrderBtnDisabled">{{ $i18n('LABEL_CONFIRM_ORDER') }}</button>
            </div>

        </div>

    </form>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import BillingInfo from '../components/BillingInfo.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import ShopCartTable from '../components/ShopCartTable.vue';

export default {
    name: 'ShopCheckout',
    components: {
        BillingInfo,
        DeleteConfirmButton,
        OverlaySpinner,
        ShopCartTable
    },
    data() {
        return {
            domains: [],
            newDiscountCode: null,
            terms: false,
            wasValidated: false
        }
    },
    computed: {
        billingAddresses() {
            return store.state.billingAddresses;
        },
        defaultBillingAddress() {
            if (store.state.billingAddresses && store.state.billingAddresses.length) {
                return store.state.billingAddresses[0];
            }
            return null;
        },
        cart() {
            return store.state.cart;
        },
        discountCode() {
            return store.state.discountCode;
        },
        basicSubscription() {
            return store.state.cart.find(item => item.productId == 1);
        },
        checkDiscountCodeDisabled() {
            return store.state.discountCode;
        },
        checkDiscountCodeBtnDisabled() {
            return ! this.newDiscountCode;
        },
        confirmOrderBtnDisabled() {
            return ! this.defaultBillingAddress || ! this.terms;
        }
    },
    created() {
        /*this.$emitter.on('billingAddressesLoaded', () => {
            console.log('billingAddressesLoaded');
            this.openBillingAddressModal();
        });*/

        // Listener for removeDomain event emitted from DeleteConfirmButton
        this.$emitter.on('removeDomain', (index) => {
            this.domains.splice(index, 1);
            this.updateDomains(this.domains.length);
        });

        if (this.basicSubscription) {
            for (let i = 0; i < this.basicSubscription.domainCount; i++) {
                this.domains.push('');
            }
        }

        if (store.state.discountCode) {
            this.newDiscountCode = store.state.discountCode.code;
        }
    },
    mounted() {
        this.$emitter.emit('closeCart');

        /*if (! this.defaultBillingAddress && this.billingAddresses) {
            // Billing addresses are loaded but billing address not set
            this.openBillingAddressModal();
        }*/
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('removeDomain');
    },
    methods: {
        domainPlaceholder(index) {
            if (index) {
                return 'additional-domain-'+(index)+'.com'
            }
            return 'mydomain.com';
        },
        domainValid(domain) { 
            if (domain) {
                // Domains must be unique
                if (this.domains.filter(item => item === domain).length > 1) {
                    return false;
                }
                var re = new RegExp(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/); 
                return domain.match(re);
            }
            return true;
        },
        addDomain() {
            // Add empty domain string to domains
            this.domains.push('');
            this.updateDomains(this.domains.length);
        },
        /**
         * Updates cart product and prices when domains are added or removed.
         * @param   {number}    domainCount
         */
        updateDomains(domainCount) {
            let newPrice;
            // Get basic subscription product
            let product = store.state.products.find(item => item.id == 1);
            let productName = this.$i18n('LABEL_PRODUCT_' + product.id);
            // Set additional domains count to product name if needed
            if (domainCount > 1) {
                productName += ' (+ ' + (domainCount-1) + ' ' + this.$i18n('LABEL_ADDITONAL_DOMAINS') + ')';
            }
            // Calculate new price
            if (this.basicSubscription.subscription == 'month') {
                newPrice = this.$calculatePrice(product.pricePerMonth, domainCount);
            } else if (this.basicSubscription.subscription == 'year') {
                newPrice = this.$calculatePrice(product.pricePerYear, domainCount);
            } 
            // Get old subscription index before adding the new subscription
            let oldSubscriptionIndex = store.state.cart.findIndex(item => item.productId == 1);
            // Add updated subscription to cart
            store.commit('addToCart', {
                productId: this.basicSubscription.productId,
                productName: productName,
                parentId: this.basicSubscription.parentSubscriptionId,
                subscription: this.basicSubscription.subscription,
                price: newPrice,
                domainCount: domainCount
            });
            // Remove old subscription from cart
            store.commit('removeFromCart', oldSubscriptionIndex);
        },
        openBillingAddressModal() {
            store.commit('setModal', {
                component: 'BillingInfoFormModal',
                size: 'modal-lg'
            });
        },
        discountCodeKeyPress(e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                this.checkDiscountCode();
                return false;
            }     
        },
        /**
         * Checks discount code validity via API.
         */
        checkDiscountCode() {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Checking dicount code');

            axios({
                method: 'get',
                url: '/api/discount-code/' + this.newDiscountCode
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    store.commit('setDiscountCode', response.data.discountCode);
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                    this.newDiscountCode = null;
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        removeDiscountCode() {
            store.commit('setDiscountCode', null);
            this.newDiscountCode = null;
        },
        /**
         * Validates and posts order form data to API.
         */
        postOrder(e) {
            e.preventDefault();
            e.stopPropagation();

            this.wasValidated = true;
            
            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Sending subscription');
            
            let formData = new FormData();
            let discountCodeJSON = null;

            if (store.state.discountCode) {
                discountCodeJSON = JSON.stringify(store.state.discountCode);
            }

            let dataObj = {
                billingAddressId: this.defaultBillingAddress.id,
                workspaceId: store.state.user.activeWorkspaceId,
                userId: store.state.user.id,
                cart: JSON.stringify(this.cart),
                domains: this.domains
            };

            if (discountCodeJSON) {
                dataObj.discountCode = discountCodeJSON;
            }

            for (var key in dataObj) {
                formData.append(key, dataObj[key]);
            }

            axios({
                method: 'post',
                url: '/api/order',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    store.commit('emptyCart');
                    store.commit('addModalMessage', this.$i18n('TEXT_ORDER_COMPLETE'));
                    this.$emitter.emit('getSubscriptions');
                    // Redirect to workspace subscriptions
                    this.$router.push('/workspace/subscriptions');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>
.domains-list-container {
    max-width: 576px;
}
</style>

<template>

    <div class="position-relative">
        
        <OverlaySpinner v-if="! translations" />

        <div v-if="translations && translations.length" id="itemTranslationsAccordion" class="accordion mb-4">

            <div v-for="translation in translations" :key="translation.lan" class="accordion-item">
                <h2 :id="translationHeadingId(translation.lan)" class="accordion-header">
                    <button @click="wasValidated=false" type="button" class="accordion-button collapsed" data-bs-toggle="collapse" :data-bs-target="'#'+translation.lan" :aria-controls="translation.lan" aria-expanded="false">
                        {{ translation.lan.toUpperCase() }}
                    </button>
                </h2>
                <div :id="translation.lan" class="accordion-collapse collapse" :aria-labelledby="translationHeadingId(translation.lan)" data-bs-parent="#itemTranslationsAccordion">
                    <div class="accordion-body">
                        
                        <form 
                            action="api/translation" 
                            method="post" 
                            class="needs-validation" 
                            :class="{'was-validated': wasValidated}"
                            @submit="postTranslation($event)" 
                            autocomplete="off"
                            novalidate
                        >

                            <div class="mb-3">
                                <label :for="translationTitleId(translation.lan)" class="form-label">* {{ $i18n('LABEL_TITLE') }}</label>
                                <input type="text" v-model="translation.title" :id="translationTitleId(translation.lan)" name="title" class="form-control" required />
                                <div class="invalid-feedback">
                                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                                </div>
                            </div>

                            <div class="mb-3">
                                <label :for="translationDescriptionId(translation.lan)" class="form-label">{{ $i18n('LABEL_DESCRIPTION') }}</label>
                                <textarea v-model="translation.description" :id="translationDescriptionId(translation.lan)" name="description" class="form-control" rows="3" cols="50"></textarea>
                            </div>

                            <div class="mb-3">
                                <label :for="translationUrlId(translation.lan)" class="form-label">{{ $i18n('LABEL_LINK_URL') }}</label>
                                <input type="text" v-model="translation.url" :id="translationUrlId(translation.lan)" name="url" class="form-control" />
                            </div>

                            <div class="mb-3">
                                <label :for="translationTextId(translation.lan)" class="form-label">{{ $i18n('LABEL_ARTICLE') }}</label>
                                <editor
                                    api-key="xw3j6ukphzxnee0qiafvv7wplmpb4d2oku9fatn4ztv3xsks"
                                    :init="tinyOptions(translationTextId(translation.lan))"
                                    :id="translationTextId(translation.lan)"
                                    name="text"
                                    v-model="translation.text"
                                />
                            </div>

                            <div class="form-check form-switch mb-3">
                                <input type="checkbox" id="status" name="status" class="form-check-input" v-model="translation.status" value="true" />
                                <label class="form-check-label" for="status">{{ $i18n('LABEL_PUBLISHED') }}</label>
                            </div>

                            <!-- input type="hidden" name="slug" :value="translation.slug" / -->
                            <input type="hidden" name="id" :value="translation.id" />
                            <input type="hidden" name="placeId" :value="translation.placeId" />
                            <input type="hidden" name="routeId" :value="translation.routeId" />
                            <input type="hidden" name="lan" :value="translation.lan" />

                            <div class="text-end mb-3">
                                <div class="d-inline-block me-2">
                                    <DeleteConfirmButton 
                                        v-if="translation.id" 
                                        deleteEventName ="deleteTranslation" 
                                        :deleteEventPayload="translation.id" 
                                        :btnGroupAddClasses="null" 
                                        :btnAddClasses="null" 
                                    />
                                </div>
                                <button type="submit" class="btn btn-primary">{{ $i18n('LABEL_SAVE_TRANSLATION') }} {{ translation.lan.toUpperCase() }}</button>
                            </div>

                        </form>

                    </div> <!-- /.accordion-body -->
                </div>
            </div>

        </div> <!-- /#itemTranslationsAccordion -->
        
        <div v-if="translations" class="mb-3">
            <label for="addTranslationLan" class="form-label">{{ $i18n('LABEL_ADD_TRANSLATION') }}</label>
            <div class="input-group">
                <select v-model="addTranslationLan" id="addTranslationLan" class="form-select">
                    <option :value="null">{{ $i18n('LABEL_SELECT_LANGUAGE') }}</option>
                    <option 
                        v-for="(lanItem, lanCode) in languages"
                        :key="lanCode"
                        :value="lanCode" 
                        :disabled="translationLanDisabled(lanCode)">
                            {{ lanItem.name }} ({{ lanItem.nativeName }})
                    </option>
                </select>
                <button @click="addTranslation()" type="button" class="btn btn-primary" :disabled="addTranslationBtnDisabled">{{ $i18n('LABEL_ADD') }}</button>
            </div>
        </div>

    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import languages from '../assets/data/languages.json';
import tinyStylesString from '!!raw-loader!../assets/css/tinymce-content.css';
import Editor from '@tinymce/tinymce-vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'ItemEditTranslations',
    components: {
        DeleteConfirmButton,
        Editor,
        OverlaySpinner
    },
    props: {
        itemLan: String,
        placeId: Number,
        routeId: Number
    },
    data() {
        return {
            translations: null,
            languages: languages,
            editorHeight: 350,
            addTranslationLan: null,
            wasValidated: false
        }
    },
    computed: {
        addTranslationBtnDisabled() {
            return !this.addTranslationLan;
        }
    },
    created() {
        this.$emitter.on('deleteTranslation', (id) => {
            this.deleteTranslation(id);
        });
    },
    mounted() {
        this.getTranslations();
    },
    methods: {
        translationHeadingId(lan) {
            return 'heading_' + lan;
        },
        translationTitleId(lan) {
            return 'title_' + lan;
        },
        translationDescriptionId(lan) {
            return 'description_' + lan;
        },
        translationTextId(lan) {
            return 'text_' + lan;
        },
        translationUrlId(lan) {
            return 'url_' + lan;
        },
        /*translationSlug(title) {
            return this.$slugify(title);
        },*/
        translationLanDisabled(lan) {
            let disabled = false;
            if (lan == this.itemLan) {
                // Lan is current lan, disable
                return true;
            }
            this.translations.forEach((item) => {
                if (item.lan == lan) {
                    // Translation for this lan exists already, disable
                    disabled = true;
                    return;
                }
            });
            return disabled;
        },
        addTranslation() {
            this.translations.push({
                placeId: this.placeId,
                routeId: this.routeId,
                lan: this.addTranslationLan,
                title: null,
                description: null,
                text: null,
                url: null,
                status: true
            });
            
            this.addTranslationLan = null;            
        },
        getTranslations() {
            this.translations = null;

            axios({
                method: 'get',
                url: '/api/translations',
                params: {
                    placeId: this.placeId,
                    routeId: this.routeId
                }
            })
            .then((response) => {
                //console.log(response.data);
                this.translations = response.data.translations;
                this.wasValidated = false;
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading translations failed');
                console.error(error);
            });
        },
        postTranslation(e) {
            e.preventDefault();
            e.stopPropagation();

            this.wasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            }

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving translation');

            // Trigger TinyMCE save to update textarea contents before posting the form
            window['tinymce'].triggerSave();

            let formData = new FormData(e.target);

            axios({
                method: 'post',
                url: '/api/translation',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getTranslations();
                    this.$emitter.emit('getItems');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
            });
        },
        /**
         * Deletes a translation from the DB permanently.
         */
        deleteTranslation(id) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting translation');
            store.commit('clearErrors');
            store.commit('clearMessages');

            axios({
                method: 'delete',
                url: '/api/translation/' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getTranslations();
                    this.$emitter.emit('getItems');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * Returns TinyMCE init options with desired language selector.
         * 
         * @param   {string}    lan
         * @returns {object}
         */
        tinyOptions(id) {
            return {
                selector: '#' + id,
                menubar: false,
                height: this.editorHeight,
                plugins: 'lists link fullscreen',
                style_formats : [
                    {title: 'Paragraph', block: 'p'},
                    {title: 'Heading 2', block: 'h2'},
                    {title: 'Heading 3', block: 'h3'}
                ],
                toolbar: 'styleselect | bold italic | bullist numlist | link | fullscreen',
                entity_encoding : 'raw',
                content_style: tinyStylesString
            }
        }
    }
}
</script>

<style scoped>

</style>

import { createStore } from 'vuex';

// Values needed in config as constants/variables
const passwordMinLength = 10;

// Default state getter so it can be reset easily
const getDefaultState = () => {
    return {
        config: {
            freeCategoriesLimit: 5,
            freeMapsLimit: 1,
            freePlacesLimit: 10,
            freeRoutesLimit: 5,
            passwordMinLength: passwordMinLength,
            textsReplaceChart: {
                PASSWORD_MIN_LENGTH: passwordMinLength
            },
        },
        lan: 'fi',
        defaultLatitude: 60.166640739,
        defaultLongitude: 24.943536799,
        texts: [],
        loading: true,
        loadingStatus: 'Loading',
        errors: [],
        messages: [],
        modalMessages: [],
        modalErrors: [],
        toasts: [],
        modal: null,
        user: null,
        billingAddresses: null,
        subscriptions: null,
        locationsDbCategories: null,
        customCategories: null,
        mapCategories: [],
        products: null,
        cart: [],
        discountCode: null,
        editings: null,
        maintenanceMode: null
    }
}

export default createStore({
    state: getDefaultState(),
    getters: {
        /**
         * Returns a text by id with current lan.
         * Returns id if text not found.
         * 
         * @param   {string}  id
         * @returns {string}
         */
        getTextById: (state) => (id) => {
            let text = state.texts.find(text => text.id === id);
            if (text) {
                return text.text;
            }
            return id;
        }
    },
    mutations: {
        resetState (state) {
            Object.assign(state, getDefaultState());
        },
        initStore: (state) => {
			// Check if stuff exist in localStorage
			if (localStorage.getItem('kmdb_lan')) {
				state.lan = localStorage.getItem('kmdb_lan');
			}
		},
        setLan: (state, lan) => {
            state.lan = lan;
            localStorage.setItem('kmdb_lan', lan);
        },
        setTexts: (state, texts) => {
            // Replace dynamic bits of texts; for example "password min length xx characters" etc.
            for (const [key, value] of Object.entries(state.config.textsReplaceChart)) {
                for (let i in texts) {
                    texts[i].text = texts[i].text.replace('['+key+']', value);
                }
            }
            state.texts = texts;
        },
        setLoading: (state, loading) => {
            state.loading = loading;
            if (! loading) {
                state.loadingStatus = 'Loading';
            }
        },
        setLoadingStatus: (state, loadingStatus) => {
            state.loadingStatus = loadingStatus;
        },
        setErrors: (state, errors) => {
            state.errors = errors;
        },
        addError: (state, errorMsg) => {
            state.errors.push(errorMsg);
        },
        clearErrors: (state) => {
            state.errors = [];
        },
        setMessages: (state, messages) => {
            state.messages = messages;
        },
        addMessage: (state, msg) => {
            state.messages.push(msg);
        },
        clearMessages: (state) => {
            state.messages = [];
        },
        setModalMessages: (state, modalMessages) => {
            state.modalMessages = modalMessages;
        },
        addModalMessage: (state, msg) => {
            state.modalMessages.push(msg);
        },
        dismissModalMessage: (state, index) => {
            state.modalMessages.splice(index, 1); 
        },
        clearModalMessages: (state) => {
            state.modalMessages = [];
        },
        setModalErrors: (state, modalErrors) => {
            state.modalErrors = modalErrors;
        },
        addModalError: (state, errorMsg) => {
            state.modalErrors.push(errorMsg);
        },
        dismissModalError: (state, index) => {
            state.modalErrors.splice(index, 1); 
        },
        clearModalErrors: (state) => {
            state.modalErrors = [];
        },
        addToast: (state, toast) => {
            let initId = 0;
            if (state.toasts.length) {
                initId = state.toasts[0].id;
            }
            let maxId = state.toasts.reduce((max, toast) => (toast.id > max ? toast.id : max),
                initId
            );
            
            toast.id = maxId + 1
            state.toasts.push(toast);
        },
        deleteToast: (state, id) => {
            for (let i in state.toasts) {
                if (state.toasts[i].id == id) {
                    state.toasts.splice(i, 1);
                    break;
                }
            }
        },
        setModal: (state, modal) => {
            state.modal = modal;
        },
        setUser: (state, user) => {
            state.user = user;
            // Set activeWorkspace when setting the user data
            if (state.user) {
                // Check if activeWorkspaceId exists in localStorage for this user
                if (localStorage.getItem('kmdb_activeWorkspaceId') && localStorage.getItem('kmdb_activeUserId') && localStorage.getItem('kmdb_activeUserId') == state.user.id) {
                    state.user.activeWorkspaceId = localStorage.getItem('kmdb_activeWorkspaceId');
                } else if (state.user.workspaces) {
                    // No activeWorkspaceId in localStorage for this user, clear localStorage
                    localStorage.removeItem('kmdb_activeWorkspaceId');
                    localStorage.removeItem('kmdb_activeUserId');
                    // Set own private workspace active
                    let privateWorkspace = state.user.workspaces.find(workspace => workspace.type == 'PRIVATE' && workspace.userId == state.user.id);
                    if (privateWorkspace) {
                        state.user.activeWorkspaceId = privateWorkspace.id;
                    } else {
                        // No private workspace found, set first own workspace active
                        let firstWorkspace = state.user.workspaces.find(workspace => workspace.userId == state.user.id);
                        if (firstWorkspace) {
                            state.user.activeWorkspaceId = firstWorkspace.id;
                        } else {
                            console.error('No workspace account found');
                        }
                    }
                } else {
                    console.error('User has no workspace accounts');
                }
            }
        },
        setUserWorkspaces: (state, workspaces) => {
            state.user.workspaces = workspaces;
        },
        setActiveWorkspace: (state, workspaceId) => {
            state.user.activeWorkspaceId = workspaceId;
            localStorage.setItem('kmdb_activeWorkspaceId', workspaceId);
            localStorage.setItem('kmdb_activeUserId', state.user.id);
        },
        setBillingAddresses: (state, billingAddresses) => {
            state.billingAddresses = billingAddresses;
        },
        setSubscriptions: (state, subscriptions) => {
            state.subscriptions = subscriptions;
            if (state.subscriptions) {
                // Parse history from json string
                state.subscriptions.forEach(item => {
                    if (item.history) {
                        item.history = JSON.parse(item.history);
                    }
                });
            }
        },
        setEditings: (state, editings) => {
            state.editings = editings;
        },
        setMaintenanceMode: (state, maintenanceMode) => {
            state.maintenanceMode = maintenanceMode;
        },
        setLocationsDbCategories: (state, locationsDbCategories) => {
            state.locationsDbCategories = locationsDbCategories;
        },
        setCustomCategories: (state, customCategories) => {
            state.customCategories = customCategories;
        },
        setMapCategories: (state, mapCategories) => {
            state.mapCategories = mapCategories;
        },
        setProducts: (state, products) => {
            state.products = products;
        },
        setCart: (state, cart) => {
            state.cart = cart;
        },
        addToCart: (state, item) => {
            state.cart.push(item);
            window.localStorage.setItem('km_cart', JSON.stringify(state.cart));
        },
        removeFromCart: (state, index) => {
            state.cart.splice(index, 1);
            window.localStorage.setItem('km_cart', JSON.stringify(state.cart));
        },
        emptyCart: (state) => {
            state.cart = [];
            state.discountCode = null;
            window.localStorage.removeItem('km_cart');
        },
        setDiscountCode: (state, discountCode) => {
            state.discountCode = discountCode;
        },
        setDefaultLatitude: (state, lat) => {
            state.defaultLatitude = parseFloat(lat);
        },
        setDefaultLongitude: (state, lng) => {
            state.defaultLongitude = parseFloat(lng);
        }
    }
});
<template>

    <table v-if="cart.length" class="table align-middle border-top">
        <tbody>
            <tr 
                v-for="(item, index) in cart"
                :key="'item-'+item.productId">
                <td>{{ item.productName }}</td>
                <td class="shrink text-end text-nowrap">
                    <span v-html="itemPrice(item.price)"></span> &euro;
                    <span v-if="item.subscription=='month'"> / {{ $i18n('LABEL_PER_MONTH') }}</span>
                    <span v-if="item.subscription=='year'"> / {{ $i18n('LABEL_PER_YEAR') }}</span>
                </td>
                <td v-if="$route.name=='ShopCart'" class="shrink">
                    <DeleteConfirmButton deleteEventName ="removeItem" :deleteEventPayload="index" />
                </td>
            </tr>
        </tbody>
    </table>

    <div v-if="$route.name!='ShopCart'" class="text-end mb-5">
        <router-link to="/shop/cart" class="btn btn-primary">{{ $i18n('LABEL_SHOPPING_CART_EDIT') }}</router-link>
    </div>

</template>

<script>
import store from '../store/store.js';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';

export default {
    name: 'ShopCart',
    components: {
        DeleteConfirmButton
    },
    computed: {
        cart() {
            return store.state.cart;
        }
    },
    created() {
        this.$emitter.on('removeItem', (index) => {
            console.log('remove item ' + index);
            store.commit('removeFromCart', index)
        });
    },
    mounted() {
        this.$emitter.emit('closeCart');
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('removeItem');
    },
    methods: {
        itemPrice(itemPrice) {
            if (store.state.discountCode) {
                let newPrice = itemPrice * store.state.discountCode.discount;
                return '<span class="text-decoration-line-through">' + itemPrice + '</span> ' + newPrice;
            }
            return itemPrice;
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div v-if="$user()" v-show="cart.length" class="position-relative">

        <button @click="toggleCart()" type="button" class="btn btn-link btn-cart">
            <BIconCart4 /><span class="d-none d-md-inline ms-1">{{ $i18n('LABEL_SHOPPING_CART') }}</span><span class="badge rounded-pill bg-danger text-white ms-1">{{ cart.length }}</span>
        </button>

        <div v-show="cartOpen" class="popover fade bs-popover-bottom" :class="{show: cartOpen}">
            <div class="popover-arrow"></div>
            <h3 class="popover-header d-flex justify-content-between align-items-center">
                <span>Ostoskori</span>
                <button @click="toggleCart()" type="button" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
            </h3>
            <div class="popover-body">
                <ul class="list-unstyled">
                    <li 
                        v-for="item in cart"
                        :key="'item-'+item.productId"
                        class="cart-item d-flex justify-content-between">
                        <span class="cart-item-name text-nowrap">{{ item.productName }}</span>
                        <span class="cart-item-price text-nowrap ms-5">
                            {{ item.price }} &euro;
                            <span v-if="item.subscription=='month'">/{{ $i18n('LABEL_PER_MONTH') }}</span>
                            <span v-if="item.subscription=='year'">/{{ $i18n('LABEL_PER_YEAR') }}</span>
                        </span>
                    </li>
                </ul>
                <router-link to="/shop/cart" class="btn btn-primary w-100 text-nowrap my-2">{{ $i18n('LABEL_SHOPPING_CART_EDIT') }}</router-link>
                <router-link to="/shop/checkout" class="btn btn-primary w-100 text-nowrap">{{ $i18n('LABEL_CHECKOUT') }}</router-link>
            </div>
        </div>

    </div>

</template>

<script>
import store from '../store/store.js';
import { BIconCart4 } from 'bootstrap-icons-vue';

export default {
    name: 'ShopCartMenu',
    components: {
        BIconCart4
    },
    data() {
        return {
            cartOpen: false
        }
    },
    computed: {
        cart() {
            return store.state.cart;
        }
    },
    mounted() {
        // Event listener for opening the shopping cart popover
        this.$emitter.on('openCart', () => {
            this.cartOpen = true;
        });
        // Event listener for closing the shopping cart popover
        this.$emitter.on('closeCart', () => {
            this.cartOpen = false;
        });
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('openCart');
        this.$emitter.all.delete('closeCart');
    },
    methods: {
        toggleCart() {
            if (window.innerWidth < 375) {
                // Viewport too narrow to show cart popover, go to cart
                this.$router.push('/shop/cart');
            } else {
                this.cartOpen = ! this.cartOpen;
            }
        }
    }
}
</script>

<style scoped>
.btn-cart {
    color: #fff;
    text-decoration: none;
}
.popover {
    /*left: 50%;
    top: 40px;
    transform: translateX(-50%);*/
    left: auto;
    right: 0;
    top: 40px;
}
.popover-arrow {
    /*left: 50%;
    transform: translateX(-50%);*/
    right: 10px;
}
</style>

<template>

    <OverlaySpinner v-if="! products || ! subscriptions" />

    <div class="bg-white p-3 mt-sm-3 shadow-sm">

        <h1>{{ $i18n('LABEL_SHOP') }}</h1>

        <div v-if="!isWorkspaceAdmin" class="alert alert-warning d-flex align-items-center">
            <BIconExclamationTriangleFill class="me-1" /> <Text :id="'TEXT_ONLY_ADMINS_CAN_MAKE_WORKSPACE_SUBSCRIPTIONS'" class="mh-none" />
        </div>

        <div v-if="$route.name=='Shop'" v-html="$i18n('HTML_SHOP')"></div>

        <router-view v-if="$route.name!='Shop'" />
    
    </div>

    <div v-if="$route.name=='Shop'" class="mx-3 mx-sm-0">
        <div class="row mt-4">
            <div 
            v-for="product in products"
            :key="'product-' + product.id" 
            class="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <ShopProductInfo :product="product" :card="true" />
            </div>

            <!-- Retkipaikka articles teaser -->
            <!-- div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div class="card h-100 shadow-sm">
                    <img :src="require('../assets/images/retkipaikka-artikkelit.jpg')" class="card-img-top" alt="Retkipaikan artikkelit" />
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Retkipaikan artikkelit</h5>
                        <p class="card-text lh-sm mb-2">
                            <small>Lisää kartallesi Retkipaikan mielenkiintoiset artikkelit täysimittaisina. Tuhansia artikkeleja ympäri Suomen.</small>
                        </p>
                        <p class="text-center mt-auto"><strong>TULOSSA</strong></p>
                    </div>
                </div>
            </div -->

        </div> <!-- /.row -->
    </div>

</template>

<script>
import store from '../store/store.js';
import { BIconExclamationTriangleFill } from 'bootstrap-icons-vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import ShopProductInfo from '../components/ShopProductInfo.vue';
import Text from '../components/Text.vue';

export default {
    name: 'Shop',
    components: {
        BIconExclamationTriangleFill,
        OverlaySpinner,
        ShopProductInfo,
        Text
    },
    data() {
        return {
            product: null
        }
    },
    computed: {
        products() {
            return store.state.products;
        },
        subscriptions() {
            return store.state.subscriptions;
        },
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        isWorkspaceAdmin() {
            // Check if user is the owner of this workspace
            if (this.activeWorkspace.userId == store.state.user.id) {
                return true;
            }
            // Check if user has admin rights for this workspace
            if (this.activeWorkspace.userLevel >= 1000) {
                return true;
            }
            return false;
        }
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
<template>

    <form 
        action="api/change-password" 
        method="post" 
        id="changePasswordForm"
        class="needs-validation" 
        :class="{'was-validated': wasValidated}"
        @submit="postChangePassword($event)" 
        novalidate>

        <div class="row">
    
            <div class="col-xl-8 col-xxl-6">
                
                <div class="mb-3">
                    <label for="currentPassword" class="form-label">{{ $i18n('LABEL_CURRENT_PASSWORD') }}</label>
                    <input v-model="currentPassword" type="password" id="currentPassword" name="currentPassword" class="form-control" autocomplete="off" required />
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_FIELD_REQUIRED') }}
                    </div>
                </div>

                <div class="mb-3">
                    <label for="newPassword" class="form-label">{{ $i18n('LABEL_NEW_PASSWORD') }}</label>
                    <input v-model="newPassword" type="password" id="newPassword" name="newPassword" class="form-control" autocomplete="off" :minlength="passwordMinLength" required />
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_FIELD_REQUIRED') }} ({{ $i18n('LABEL_PASSWORD_MIN_LENGTH') }})
                    </div>
                </div>

                <div class="mb-3">
                    <label for="newPasswordAgain" class="form-label">{{ $i18n('LABEL_NEW_PASSWORD_AGAIN') }}</label>
                    <input v-model="newPasswordAgain" type="password" id="newPasswordAgain" name="newPasswordAgain" class="form-control" autocomplete="off" :pattern="newPassword" required />
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_FIELD_REQUIRED') }} ({{ $i18n('LABEL_MUST_MATCH_NEW_PASSWORD') }}).
                    </div>
                </div>

                <div class="text-center d-grid gap-2 d-sm-block my-5">
                    <button type="submit" class="btn btn-primary">{{ $i18n('LABEL_SAVE_NEW_PASSWORD') }}</button>
                </div>

            </div>

        </div> <!-- /.row -->

    </form>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'ChangePasswordForm',
    data() {
        return {
            wasValidated: false,
            currentPassword: null,
            newPassword: null,
            newPasswordAgain: null
        }
    },
    computed: {
        passwordMinLength() {
            return store.state.config.passwordMinLength;
        }
    },
    methods: {
        /**
         * Checks that newPassword and newPasswordAgain match.
         *  
         * @returns  {boolean}
         */
        newPasswordsMatch() {
            return this.newPassword === this.newPasswordAgain;
        },
        /**
         * Checks password change form validity and posts data to API if everything ok.
         * 
         * @param   {object}    e   Native MouseEvent object.
         */
        postChangePassword(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            // Validate form
            if (! e.target.checkValidity()) {
                return;
            } 

            // Check that new passwords match
            // This is just in case input pattern is not supported by user's browser
            if (! this.newPasswordsMatch()) {
                store.commit('addModalError', 'New passwords do not match.');
                return;
            }

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving new password');

            let formData = new FormData(document.getElementById('changePasswordForm'));

            axios({
                method: 'post',
                url: '/api/change-password',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setModalMessages', response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
                this.currentPassword = null;
                this.newPassword = null;
                this.newPasswordAgain = null;
            });
        }
    }
}
</script>

<style scoped>

</style>
<template>

    <div v-if="showConfirm" class="btn-group" :class="btnGroupAddClasses" role="group" aria-label="Confirm or cancel delete">
        <button type="button" @click="confirmDelete()" class="btn btn-danger" :class="btnAddClasses">
            {{ getConfirmDeleteLabel }}
        </button>
        <button type="button" @click="toggleShowConfirm()" class="btn btn-secondary" :class="btnAddClasses">
            {{ getCancelDeleteLabel }}
        </button>
    </div>
    <button 
        v-if="!showConfirm"
        @click="toggleShowConfirm()"
        type="button"
        class="btn btn-danger"
        :class="btnAddClasses"
        :disabled="disabled"
    >
        <BIconTrash v-if="showIcon" />
        <span v-if="label">
            {{ label }}
        </span>
    </button>

</template>

<script>
import { BIconTrash } from 'bootstrap-icons-vue';

export default {
    name: 'DeleteConfirmButton',
    components: {
        BIconTrash
    },
    props: {
        showIcon: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: null
        },
        confirmDeleteLabel: {
            type: String,
            default: 'LABEL_CONFIRM_DELETE'
        },
        cancelDeleteLabel: {
            type: String,
            default: 'LABEL_CANCEL'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        deleteEventName: String,
        deleteEventPayload: undefined,
        btnGroupAddClasses: {
            type: String,
            default: 'me-1'
        },
        btnAddClasses: {
            type: String,
            default: 'btn-sm'
        }
    },
    computed: {
        getConfirmDeleteLabel() {
            return this.$i18n(this.confirmDeleteLabel);
        },
        getCancelDeleteLabel() {
            return this.$i18n(this.cancelDeleteLabel);
        }
    },
    data() {
        return {
            showConfirm: false
        }
    },
    methods: {
        toggleShowConfirm() {
            this.showConfirm = ! this.showConfirm;
        },
        confirmDelete() {
            this.$emitter.emit(this.deleteEventName, this.deleteEventPayload);
            this.showConfirm = false;
        }
    }
}
</script>

<style scoped>

</style>

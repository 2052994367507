<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_SET_MAP_BOUNDS') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body d-flex flex-column">

        <ItemEditMap mode="bounds" :latitude="latitude" :longitude="longitude" :bounds="initBounds" :initZoom="initZoom" />

    </div> <!-- /.modal-body -->
    
    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="save()" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import store from '../store/store.js';
import ItemEditMap from '../components/ItemEditMap.vue';

export default {
    name: 'MapEditBounds',
    components: {
        ItemEditMap
    },
    props: {
        latitude: {
            type: Number,
            default: store.state.defaultLatitude
        },
        longitude: {
            type: Number,
            default: store.state.defaultLongitude
        },
        initBounds: {
            type: Array,
            default: null
        },
        initZoom: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            bounds: null
        }
    },
    computed: {
        
    },
    created() {
        if (this.initBounds) {
            this.bounds = this.initBounds;
        }

        this.$emitter.on('setBounds', (bounds) => {
            this.bounds = bounds;
        });
    },
    mounted() {
        
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('setBounds');
    },
    methods: {
        /**
         * Closes modal.
         */
        cancel() {
            store.commit('setModal', null);
        },
        save() {
            let bounds = null;
            if (Array.isArray(this.bounds)) {
                bounds = this.bounds;
            } else if (this.bounds) {
                // Convert Leaflet LatLngBounds to array
                bounds = [
                    [this.bounds.getNorthEast().lat, this.bounds.getNorthEast().lng], 
                    [this.bounds.getSouthWest().lat, this.bounds.getSouthWest().lng]
                ];
            }
            this.$emitter.emit('setMapMaxBounds', bounds);
            store.commit('setModal', null);
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div v-if="! $route.params.id">

        <!-- button class="btn btn-primary mt-3 mb-5">Add new text</button -->

        <div class="row">
            <div class="col-6 mb-3">
                <label for="textsLan" class="form-label">Language</label>
                <select @change="getTexts()" v-model="lan" id="textsLan" class="form-select text-uppercase">
                    <option 
                        v-for="lanCode in languages"
                        :key="lanCode"
                        :selected="lanCode==lan">
                        {{ lanCode }}
                    </option>
                </select>
            </div>
            <div class="col-6 mb-3">
                <label for="textsFilter" class="form-label">Filter</label>
                <input v-model="filterStr" type="text" id="textsFilter" class="form-control" :placeholder="$i18n('LABEL_FILTER_TEXTS_LIST_BY_TYPING')" />
            </div>
        </div>

        <table class="table table-striped table-hover table-sm align-middle">
            <thead class="bg-dark text-white">
                <tr>
                    <th>Id</th>
                    <th>Text</th>
                    <th> </th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                v-for="text in texts" 
                v-show="showText(text)"
                :key="text.id">
                    <td class="align-top">
                        {{ text.id }}
                    </td>
                    <td class="align-top expand">
                        {{ htmlToPlainText(text.text, 180) }}
                    </td>
                    <td class="align-top text-end shrink">
                        <DeleteConfirmButton deleteEventName ="deleteText" :deleteEventPayload="text.id" />
                    </td>
                    <td class="align-top text-end shrink">
                        <button @click="openEditText(text.id)" class="btn btn-primary btn-sm">
                            <BIconPencilFill />
                            <span class="visually-hidden">Edit</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <router-view />

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconPencilFill } from 'bootstrap-icons-vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';

export default {
    name: 'AdminTexts',
    components: {
        BIconPencilFill,
        DeleteConfirmButton
    },
    data() {
        return {
            lan: store.state.lan,
            languages: [],
            texts: [],
            filterStr: null
        }
    },
    created() {
        this.$emitter.on('deleteText', (id) => {
            console.log('should delete ' + id);
        });

        this.$emitter.on('getTexts', () => {
            this.getTexts();
        });

        this.getTexts();
    },
    mounted() {
        
    },
    methods: {
        getTexts() {
            store.commit('setLoading', true);
            this.languages = [];
            axios({
                method: 'get',
                url: '/api/texts'
            })
            .then((response) => {
                //console.log(response);
                if (response.data.texts) {
                    // Put current lan texts in this.texts
                    this.texts = response.data.texts[this.lan];
                    // Populate language select
                    Object.keys(response.data.texts).forEach((value) => {
                        this.languages.push(value);
                    });
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        openEditText(textId) {
            store.commit('setModal', {
                component: 'AdminTextsEdit',
                componentProps: {
                    id: textId
                },
                size: 'modal-lg',
                fullHeight: false
            });
        },
        showText(text) {
            let show = false;
            // No filter, show all
            if (! this.filterStr) {
                return true;
            }
            // Loop through text object property values
            Object.values(text).forEach((value) => {
                // Show text if we have a match in any property value
                if (value.toLowerCase().search(this.filterStr.toLowerCase()) != -1) {
                    show = true;
                }
            });

            return show;
        },
        htmlToPlainText(htmlStr, maxChars) {
            let el = document.createElement('div');
            el.innerHTML = htmlStr;

            if (maxChars && el.innerText.length > maxChars) {
                return el.innerText.slice(0, maxChars) + ' (...)';
            }

            return el.innerText;
        }
    }
}
</script>

<style scoped>

</style>

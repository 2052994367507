<template>

    <Invitations />

    <div class="row mt-4 mx-1 mx-sm-0">

        <div v-if="maintenanceMode" class="col-12">
            <div class="alert alert-danger alert-maintenance-mode">
                <div class="mt-1">
                    <strong>{{ $i18n('LABEL_MAINTENANCE_BREAK') }}</strong>
                </div>
                <Text id="TEXT_MAINTENANCE_BREAK_START" :replaces="{'[MAINTENANCE_BREAK_START]': $formatDateTime(maintenanceMode.start)}" class="mb-3" />
                <Text v-if="maintenanceMode.end" id="TEXT_MAINTENANCE_BREAK_END" :replaces="{'[MAINTENANCE_BREAK_END]': $formatDateTime(maintenanceMode.end)}" />
            </div>
        </div>

        <div class="col-xl-8 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconQuestionCircleFill /> {{ $i18n('LABEL_GETTING_STARTED') }}
                </div>
                <div class="card-body">
                    <div class="ratio ratio-16x9">
                        <iframe src="https://www.youtube.com/embed/PLwaD3Ke_qA?rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconStarFill /> {{ $i18n('LABEL_NEW_BOX_TITLE') }}
                </div>
                <div class="card-body">
                    <NewNotes />
                </div>
            </div>
        </div>
        
        <div class="col-lg-4 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconGlobe2 /> {{ $i18n('LABEL_MAPS_AND_CONTENT') }}
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="maps" class="text-dark">{{ $i18n('LABEL_MAPS') }}</router-link>
                        </li>
                        <li>
                            <router-link to="places" class="text-dark">{{ $i18n('LABEL_PLACES') }}</router-link>
                        </li>
                        <li>
                            <router-link to="routes" class="text-dark">{{ $i18n('LABEL_ROUTES') }}</router-link>
                        </li>
                        <li>
                            <router-link to="categories" class="text-dark">{{ $i18n('LABEL_CATEGORIES') }}</router-link>
                        </li>
                        <li>
                            <router-link to="sites" class="text-dark">{{ $i18n('LABEL_LINKED_SITES') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-lg-4 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconBuilding /> {{ $i18n('LABEL_WORKSPACES') }}
                </div>
                <div class="card-body d-flex flex-column">
                    <ul class="list-unstyled">
                        <li v-for="workspace in workspaces" :key="workspace.id+'-'+workspace.name" class="mb-2">
                            <WorkspaceIcon :image="workspace.image" />
                            <a @click="goToWorkspaceSettings($event, workspace.id)" href="/workspace/workspace-info" class="text-dark">
                                {{ getWorkspaceName(workspace.name) }}
                            </a>
                        </li>
                    </ul>
                    <button @click="openCreateNewWorkspace()" type="button" class="btn btn-primary mt-auto me-auto">{{ $i18n('LABEL_CREATE_WORKSPACE') }}</button>
                </div>
            </div>
        </div>

        <div class="col-lg-4 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconPersonFill /> {{ $i18n('LABEL_MY_ACCOUNT') }}
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="/my-account/account-info" class="text-dark">{{ $i18n('LABEL_ACCOUNT_INFO') }}</router-link>
                        </li>
                        <li>
                            <router-link to="/my-account/change-password" class="text-dark">{{ $i18n('LABEL_CHANGE_PASSWORD') }}</router-link>
                        </li>
                        <li>
                            <router-link to="/logout" class="text-dark">{{ $i18n('LABEL_LOG_OUT') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- div class="col-lg-4 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconQuestionCircle /> {{ $i18n('LABEL_HELP_AND_SUPPORT') }}
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li><a href="#" class="text-dark">{{ $i18n('LABEL_HELP') }}</a></li>
                        <li><a href="#" class="text-dark">{{ $i18n('LABEL_FAQ') }}</a></li>
                        <li><a href="#" class="text-dark">{{ $i18n('LABEL_SUPPORT') }}</a></li>
                    </ul>
                </div>
            </div>
        </div -->

        <div v-if="$isAdmin()" class="col-lg-4 mb-4">
            <div class="card h-100">
                <div class="card-header">
                    <BIconGearFill /> {{ $i18n('LABEL_ADMIN') }}
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="/admin/texts" class="text-dark">{{ $i18n('LABEL_TEXTS') }}</router-link>
                            </li>
                        <li>
                            <router-link to="/admin/users" class="text-dark">{{ $i18n('LABEL_USERS') }}</router-link>
                        </li>
                        <li>
                            <router-link to="/admin/maps" class="text-dark">{{ $i18n('LABEL_MAPS') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import store from '../store/store.js';
import { BIconBuilding, BIconGearFill, BIconGlobe2, BIconPersonFill, BIconQuestionCircleFill, BIconStarFill, } from 'bootstrap-icons-vue';
import Invitations from '../components/Invitations.vue';
import NewNotes from '../components/NewNotes.vue';
import Text from '../components/Text.vue';
import WorkspaceIcon from '../components/WorkspaceIcon.vue';

export default {
    name: 'Dashboard',
    components: {
        BIconBuilding, 
        BIconGearFill,
        BIconGlobe2, 
        BIconPersonFill,
        BIconQuestionCircleFill,
        BIconStarFill,
        Invitations,
        NewNotes,
        Text,
        WorkspaceIcon
    },
    computed: {
        user() {
            return store.state.user;
        },
        workspaces() {
            return store.state.user.workspaces;
        },
        maintenanceMode() {
            return store.state.maintenanceMode;
        }
    },
    methods: {
        /**
         * Adds "(workspace)" to workspace name if it is the same as user name.
         * @param   {string}    workspaceName
         */
        getWorkspaceName(workspaceName) {
            if (workspaceName == store.state.user.firstName + ' ' + store.state.user.lastName) {
                return workspaceName + ' (' + this.$i18n('LABEL_WORKSPACE').toLowerCase() + ')';
            }
            return workspaceName;
        },
        goToWorkspaceSettings(e, workspaceId) {
            e.preventDefault();
            this.$emitter.emit('changeWorkspace', workspaceId);
            this.$router.push('/workspace/workspace-info');
        },
        /**
         * Opens modal for creating new workspace.
         * 
         * @param   {object}    member
         */
        openCreateNewWorkspace() {
            store.commit('setModal', {
                component: 'WorkspaceCreateNew'
            });
        }
    }
}
</script>

<style scoped>
.card-header svg,
li svg {
    position: relative;
    top: -2px;
}
</style>

<template>

    <p v-if="mode=='marker'">Click on the map to move the marker to a new location.</p>

    <p v-if="mode=='route'">Click on the map to start drawing a route or click existing route to edit.</p>

    <p v-if="mode=='bounds'">Click on the map to start drawing bounds.</p>

    <div class="mb-3">
        <InputSuggestions 
            @select="locationSelect"
            sourceUrl="https://nominatim.openstreetmap.org/search?format=json&q={{term}}" 
            searchProp="display_name"
            displayProp="display_name"
            placeholder="Type to search location"
        />
    </div>

    <div class="position-relative">
        <button 
            v-if="showDeleteRouteBtn" 
            @click="deleteRoute()"
            type="button" 
            class="btn btn-light btn-sm delete-route-btn">
            {{ $i18n('LABEL_DELETE_ROUTE') }}
        </button>
        <button 
            v-if="showDeleteBoundsBtn" 
            @click="deleteBounds()"
            type="button" 
            class="btn btn-light btn-sm delete-route-btn">
            {{ $i18n('LABEL_DELETE_BOUNDS') }}
        </button>
        <div id="adminMap" class="mb-3"></div>
    </div>

</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-editable';
import store from '../store/store.js';
import InputSuggestions from '../components/InputSuggestions.vue';

export default {
    name: 'ItemEditMap',
    components: {
        InputSuggestions
    },
    props: {
        /**
         * Map mode type.
         * @values marker, route, bounds
         */
        mode: {
            type: String,
            default: 'marker',
            validator: function(value) {
                // Valid values for mode
                return ['marker', 'route', 'bounds'].indexOf(value) !== -1;
            }
        },
        latitude: {
            type: Number,
            default: store.state.defaultLatitude
        },
        longitude: {
            type: Number,
            default: store.state.defaultLongitude
        },
        waypoints: {
            type: Array,
            default: function() {
                return [];
            }
        },
        bounds: {
            type: Array,
            default: null
        },
        initZoom: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            map: null,
            zoom: 12,
            marker: {
                lat: this.latitude,
                lng: this.longitude,
            },
            editableLayerId: null,
            polylineLayers: [],
            rect: null,
            newBounds: null,
            search: false
        };
    },
    computed: {
        showDeleteRouteBtn() {
            return this.mode == 'route' && this.editableLayerId !== null;
        },
        showDeleteBoundsBtn() {
            return this.mode == 'bounds' && this.newBounds;
        }
    },
    watch: {
        latitude() {
            this.setMarker({
                lat: this.latitude, 
                lng: this.longitude
            });
        },
        longitude() {
            this.setMarker({
                lat: this.latitude, 
                lng: this.longitude
            });
        }
    },
    created() {
        this.$emitter.on('initMap', () => {
            this.initMap();
        });

        /*this.$emitter.on('adminMapUpdateLocation', () => {
            
        });*/

        this.$emitter.on('adminMapInvalidateSize', () => {
            // Refresh leaflet map after timeout
            window.setTimeout(() => {
                this.map.invalidateSize();
            }, 500);
        });
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            delete L.Icon.Default.prototype._getIconUrl;

            L.Icon.Default.mergeOptions({
                iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                iconUrl: require('leaflet/dist/images/marker-icon.png'),
                shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            });

            if (this.initZoom) {
                this.zoom = this.initZoom;
            }

            this.map = L.map('adminMap', {
                center: [this.latitude, this.longitude],
                zoom: this.zoom,
                zoomControl: this.mode !== 'bounds',
                editable: true
            });

            // Add tile layer for OpenStreetMap tiles
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map);

            // Move zoom control to top right position
            if (this.mode !== 'bounds') {
                this.map.zoomControl.setPosition('topright');
            }

            // Show the scale bar in the bottom left corner
            L.control.scale({imperial: false}).addTo(this.map);

            if (this.mode == 'marker') {
                this.initMarker();
            } else if (this.mode == 'route') {
                this.initRoute();
            } else if (this.mode == 'bounds') {
                this.initBounds();
            }

            window.setTimeout(() => {
                this.map.invalidateSize();
            }, 500);
        },
        initMarker() {
            // Add init marker if defined
            if (this.marker) 
            {
                this.setMarker({
                    lat: this.marker.lat, 
                    lng: this.marker.lng
                });
            }

            // Add marker on click
            this.map.on('click', (e) => {
                let latLng = this.map.mouseEventToLatLng(e.originalEvent);
                this.setMarker(latLng);
            });
        },
        initRoute() {
            // Map click handler; start drawing a new polyline on click
            this.map.on('click', (e) => {
                // Start drawing a new polyline only if no drawing or editing is currenlty active
                if (! this.map.editTools.drawing() && this.editableLayerId === null) {
                    let latLng = this.map.mouseEventToLatLng(e.originalEvent);
                    this.polylineLayers.push(this.map.editTools.startPolyline(latLng));
                } else if (! this.map.editTools.drawing() && this.editableLayerId !== null) {
                    // End editing when clicking outside the polyline
                    this.$emitter.emit('setRouteWaypoints', this.getRouteWaypoints());
                    this.getPolylineByLeafletId(this.editableLayerId).disableEdit();
                    this.editableLayerId = null;
                }
            });

            // Commit drawing on doubleclick
            this.map.on('dblclick', () => {
                if (this.map.editTools.drawing()) {
                    this.map.editTools.commitDrawing();
                }
            });

            // Set route waypoints and polyline click handler on drawing commit
            this.map.on('editable:drawing:commit', () => {
                // Emit event to ItemEdit to set route waypoints
                this.$emitter.emit('setRouteWaypoints', this.getRouteWaypoints());
                // Disable edit on this polyline layer
                this.polylineLayers[this.polylineLayers.length-1].disableEdit();
                // Polyline click event handler
                this.polylineLayers[this.polylineLayers.length-1].on('click', this.polylineClick);
                // Set editable id to null
                this.editableLayerId = null;
            });

            this.map.on('editable:vertex:new editable:vertex:deleted editable:vertex:dragend', () => {
                // Emit event to ItemEdit to set route waypoints
                this.$emitter.emit('setRouteWaypoints', this.getRouteWaypoints());
            });

            // Create polylines from existing waypoints
            if (this.waypoints && this.waypoints.length) {
                for (let i in this.waypoints) {
                    this.polylineLayers[i] = L.polyline(this.waypoints[i]).addTo(this.map);
                    this.polylineLayers[i].on('click', this.polylineClick);
                }
                // Set map center to the first point of the first segment of the route
                this.map.setView(this.waypoints[0][0]);
            } else {
                // New route, set map center from store
                this.map.setView({lat: store.state.defaultLatitude, lng: store.state.defaultLongitude});
            }
        },
        initBounds() {
            if (this.bounds) {
                this.rect = L.rectangle(this.bounds).addTo(this.map)
                this.rect.enableEdit();
                // Zoom to show bounds
                this.map.fitBounds(this.bounds);
                this.newBounds = this.bounds;
            } else {
                this.rect = this.map.editTools.startRectangle();
            }

            this.map.on('editable:vertex:dragend', () => {
                // Emit event to set new map bounds
                this.newBounds = this.rect._bounds;
                this.$emitter.emit('setBounds', this.newBounds);
            });
        },
        /**
         * @param   {object}    latLng
         */
        setMarker(latLng) {
            if (this.map.hasLayer(this.marker)) {
                // Marker exists already, move it
                this.marker.setLatLng(latLng);
            } else {
                // No marker yet, create marker
                this.marker = L.marker(latLng);
                this.marker.addTo(this.map);
            }
            // Emit new marker location
            this.$emitter.emit('setPlaceLocation', latLng);

            window.localStorage.setItem('lat', latLng.lat);
            window.localStorage.setItem('lng', latLng.lng);
            store.commit('setDefaultLatitude', latLng.lat);
            store.commit('setDefaultLongitude', latLng.lng);
        },
        /**
         * Gets Leaflet id from the polyline layer object.
         * @param   {number}    leafletId
         * @returns {object}
         */
        getPolylineByLeafletId(leafletId) {
            return this.polylineLayers.find(item => item._leaflet_id == leafletId);
        },
        polylineClick(e) {
            // Prevent firing map click
            L.DomEvent.stopPropagation(e);
            // Disable editing on all polyline layers
            this.disableEditingOnAll();
            // Enable edit on this polyline layer
            this.getPolylineByLeafletId(e.target._leaflet_id).enableEdit();
            // Set editable id to this layer id
            this.editableLayerId = e.target._leaflet_id;
        },
        /**
         * Disables editing on all polyline layers.
         */
        disableEditingOnAll() {
            for (let i in this.polylineLayers) {
                this.polylineLayers[i].disableEdit();
            }
        },
        /**
         * Deletes a route polyline from polylineLayers, editable and map.
         */
        deleteRoute() {
            let polylineLayer = this.getPolylineByLeafletId(this.editableLayerId);
            polylineLayer.disableEdit();
            delete this.map.editTools.featuresLayer._layers[this.editableLayerId];
            this.map.removeLayer(polylineLayer);
            this.deletePolylineLayer(this.editableLayerId);
            // Emit event to ItemEdit to set route waypoints
            this.$emitter.emit('setRouteWaypoints', this.getRouteWaypoints());
            this.editableLayerId = null;
        },
        deleteBounds() {
            this.rect.disableEdit();
            delete this.map.editTools.featuresLayer._layers[this.rect._leaflet_id];
            this.map.removeLayer(this.rect);
            this.newBounds = null;
            this.$emitter.emit('setBounds', this.newBounds);
            this.rect = this.map.editTools.startRectangle();
        },
        getRouteWaypoints() {
            if (this.polylineLayers.length) {
                let waypoints = [];

                for (let i in this.polylineLayers) {
                    let polylineLatLngs = this.polylineLayers[i].getLatLngs();
                    let segment = [];

                    for (let j in polylineLatLngs) {
                        segment.push({
                            lat: polylineLatLngs[j].lat,
                            lng: polylineLatLngs[j].lng
                        });
                    }
                    waypoints.push(segment);
                }

                // Set first waypoint to localStorage
                if (this.polylineLayers[0].getLatLngs()[0].lat && this.polylineLayers[0].getLatLngs()[0].lng) {
                    window.localStorage.setItem('lat', this.polylineLayers[0].getLatLngs()[0].lat);
                    window.localStorage.setItem('lng', this.polylineLayers[0].getLatLngs()[0].lng);
                    store.commit('setDefaultLatitude', this.polylineLayers[0].getLatLngs()[0].lat);
                    store.commit('setDefaultLongitude', this.polylineLayers[0].getLatLngs()[0].lng);
                }

                return waypoints;
            }
            return null;
        },
        deletePolylineLayer(leafletId) {
            for (let i in this.polylineLayers) {
                if (this.polylineLayers[i]._leaflet_id == leafletId) {
                    this.polylineLayers.splice(i, 1);
                    break;
                }
            }
        },
        locationSelect(data) {
            this.map.setView([data.lat, data.lon]);
        }
    }
};
</script>

<style scoped>
.delete-route-btn {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 999;
}
#adminMap {
    position: relative;
    width: 100%;
    height: 350px;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}
</style>
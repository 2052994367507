<template>

    <div v-if="! $route.params.id" class="table-responsive">

        <table class="table table-striped table-hover table-sm align-top">
            <thead class="bg-dark text-white">
                <tr>
                    <th>{{ $i18n('LABEL_WORKSPACE') }}</th>
                    <th>{{ $i18n('LABEL_PRODUCT') }}</th>
                    <th>{{ $i18n('LABEL_ORDER_DATE') }}</th>
                    <th>{{ $i18n('LABEL_DOMAINS') }}</th>
                    <th>{{ $i18n('LABEL_DISCOUNT_CODE') }}</th>
                    <th class="text-end shrink"> </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                v-for="subscription in basicSubscriptions" 
                :key="subscription.id">
                    <td>
                        {{ subscription.workspace.name }}<br />
                        <small>{{ subscription.user.email }}</small>
                    </td>
                    <td class="subscription-products">
                       {{ $i18n('LABEL_PRODUCT_' + subscription.productId) }} {{ getPrice(subscription) }}
                       <ul class="list-unstyled">
                           <li 
                           v-for="additionalSubscription in getAdditionalSubscriptions(subscription.id)" 
                           :key="additionalSubscription.id">
                                + {{ $i18n('LABEL_PRODUCT_' + additionalSubscription.productId) }} 
                                {{ getPrice(additionalSubscription) }} 
                                <span class="text-muted">({{ $formatDate(additionalSubscription.created) }})</span>
                           </li>
                       </ul>
                    </td>
                    <td>
                        {{ $formatDate(subscription.created) }}
                    </td>
                    <td>
                        {{ subscription.domains.replace(new RegExp(',', 'g'), ', ') }}
                    </td>
                    <td>
                        {{ getDiscountCode(subscription.discountCode) }}
                    </td>
                    <td>
                        
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <router-view />

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'AdminSubscriptions',
    components: {
    },
    data() {
        return {
            subscriptions: [],
            basicSubscriptions: []
        }
    },
    created() {
        this.getSubscriptions();
    },
    mounted() {
        
    },
    methods: {
        getSubscriptions() {
            store.commit('setLoading', true);
            axios({
                method: 'get',
                url: '/api/admin-subscriptions'
            })
            .then((response) => {
                //console.log(response);
                if (response.data.subscriptions) {
                    this.subscriptions = response.data.subscriptions;
                    this.basicSubscriptions = this.subscriptions.filter(item => item.parentId == null);
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * @param   {number}    parentId
         * @returns {array|null}
         */
        getAdditionalSubscriptions(parentId) {
            if (this.subscriptions) {
                return this.subscriptions.filter(item => item.parentId == parentId);
            }
            return null;
        },
        /**
         * Gets subscription price based on it's base price and amount of domains.
         * @param   {object}    subscription
         * @returns {string}
         */
        getPrice(subscription) {
            if (subscription.price) {
                return subscription.price + ' &euro;';
            } else if (subscription.pricePerMonth) {
                return subscription.pricePerMonth + ' €/' + this.$i18n('LABEL_PER_MONTH');
            } else if (subscription.pricePerYear) {
                return subscription.pricePerYear + ' €/' + this.$i18n('LABEL_PER_YEAR');
            }
            return null;
        },
        /**
         * Returns subscription discount code.
         * @param   {string}    discountCode
         * @returns {string}
         */
        getDiscountCode(discountCode) {
            if (! discountCode || discountCode === '') {
                return null;
            }
            return JSON.parse(discountCode).code;
        }
    }
}
</script>

<style scoped>
.subscription-products {
    white-space: nowrap;
}
</style>

<template>
    
    <span v-if="!this.image" class="bg-secondary workspace-icon-default" :class="'workspace-icon-'+size">
        <BIconBuilding />
    </span>
    
    <span v-if="this.image=='new'" class="bg-secondary workspace-icon-default" :class="'workspace-icon-'+size">
        <BIconPlus />
    </span>

    <span v-if="this.image && this.image!='new'" class="workspace-icon" :class="'workspace-icon-'+size" :style="backgroundImageStyle"></span>

</template>

<script>
import { BIconBuilding, BIconPlus } from 'bootstrap-icons-vue';

export default {
    name: 'Texts',
    components: {
        BIconBuilding,
        BIconPlus
    },
    props: {
        image:  String,
        size: {
            type: Number,
            default: 24
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        backgroundImageStyle() {
            return 'background-image:url(' + this.image + ');';
        }
    },
    created() {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>
.workspace-icon-default {
    color: #fff;
    vertical-align: bottom;
    padding: 3px 4px;
    border-radius: 50%;
    margin-right: .25rem;
}
.workspace-icon-default svg {
    position: relative;
    top: -2px;
}
.workspace-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    margin-right: .25rem;
    vertical-align: bottom;
    overflow: hidden;
}
.workspace-icon-32 {
    width: 32px;
    height: 32px;
}
.workspace-icon-32 svg {
    width: 21px;
    height: 21px;
}
</style>

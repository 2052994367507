<template>

    <p v-if="activationSuccess"> You can now <router-link to="/" >log in</router-link>.</p>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'RegistrationActivation',
    components: {

    },
    data() {
        return {
            activationSuccess: false
        }
    },
    mounted() {
        store.commit('setLoading', true);
        store.commit('setLoadingStatus', 'Activating account');
        this.postRegistrationActivation(this.$route.params.token);
    },
    methods: {
        postRegistrationActivation(token) {           
            let formData = new FormData();
            formData.append('token', token);

            axios({
                method: 'post',
                url: '/api/registration-activation',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') 
                {
                    if (response.data.messages) 
                    {
                        store.commit('setMessages', response.data.messages);
                    }
                    this.activationSuccess = true;
                } 
                else if (response.data.errors) 
                {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>

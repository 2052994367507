<template>

    <div v-if="subscriptions.length">
        <table  
            v-for="subscription in tableSubscriptions"
            :key="subscription.id"
            class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <th scope="col">
                        {{ $i18n('LABEL_PRODUCT') }}
                    </th>
                    <th scope="col" class="text-end">
                        {{ $i18n('LABEL_ORDER_DATE') }}
                    </th>
                    <th v-if="mode=='ended'" scope="col" class="text-end text-nowrap">
                        {{ $i18n('LABEL_ORDER_ENDED_DATE') }}
                    </th>
                    <th scope="col" class="text-end">
                        {{ $i18n('LABEL_PRICE') }}
                    </th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_END_SUBSCRIPTION') }}</span>
                    </th>
                        <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_SHOW_DETAILS') }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-if="(mode=='active' && subscription.ended==null) || (mode=='ended' && subscription.ended!=null)"
                    :class="{'align-middle':showSubscriptionId!=subscription.id}">
                    <td class="expand">
                        {{ getProductName(subscription) }}
                        <span v-if="getBillingAddressName(subscription.billingAddressId)" class="text-muted">
                            ({{ getBillingAddressName(subscription.billingAddressId) }})
                        </span>

                        <div v-if="showSubscriptionId==subscription.id">
                            <div v-if="getBillingAddress(subscription.billingAddressId)" class="small my-3">
                                <strong>{{ $i18n('LABEL_BILLING_ADDRESS') }}</strong>
                                <BillingInfo :billingAddress="getBillingAddress(subscription.billingAddressId)" />
                            </div>

                            <div class="small my-3">
                                <strong>{{ $i18n('LABEL_DOMAINS') }}</strong>
                                <ul v-if="getDomains(subscription).length" class="list-unstyled mb-0">
                                    <li 
                                        v-for="domain in getDomains(subscription)"
                                        :key="domain"
                                    >
                                        {{ domain }}
                                    </li>
                                </ul>
                                <div v-if="!getDomains(subscription).length" class="form-text">
                                    {{ $i18n('LABEL_NO_DOMAINS') }}
                                </div>
                                <div class="form-text">
                                    {{ $i18n('TEXT_DOMAINS_CONTACT_CUSTOMER_SERVICE') }}
                                </div>
                            </div>

                            <div v-if="subscription.history" class="small my-3">
                                <strong>{{ $i18n('LABEL_SUBSCRIPTION_HISTORY') }}</strong>
                                <ul class="list-unstyled">
                                    <li 
                                        v-for="(historyObj, index) in subscription.history"
                                        :key="'history-'+index"
                                    >
                                        {{ getHistoryName(historyObj.name) }}: {{ historyObj.value }}
                                    </li>
                                </ul>
                            </div>

                            <button @click="showSubscriptionId=null" type="button" class="btn btn-secondary btn-sm">
                                {{ $i18n('LABEL_CLOSE') }}
                            </button>
                        </div>

                    </td>
                    <td class="text-end text-nowrap shrink">
                        {{ $formatDate(subscription.created) }}
                    </td>
                    <td v-if="mode=='ended'" class="text-end text-nowrap shrink">
                        {{ $formatDate(subscription.ended) }}
                    </td>
                    <td class="text-end text-nowrap shrink">
                        {{ getPrice(subscription) }}
                    </td>
                    <td class="text-end shrink">
                        <button 
                            v-if="mode=='active'"
                            @click="endSubscriptionConfirm(subscription.id)" 
                            type="button"
                            class="btn btn-danger btn-sm"
                            :disabled="subscription.userId!=$user().id"
                        >
                            {{ $i18n('LABEL_END_SUBSCRIPTION') }}
                        </button>
                        <button 
                            v-if="mode=='ended'"
                            @click="continueSubscriptionConfirm(subscription.id)" 
                            type="button"
                            class="btn btn-primary btn-sm"
                        >
                            {{ $i18n('LABEL_CONTINUE_SUBSCRIPTION') }}
                        </button>
                    </td>
                    <td class="text-end shrink">
                        <button @click="toggleShowSubscription(subscription.id)" type="button" class="btn btn-primary btn-sm">
                            <span v-if="showSubscriptionId!=subscription.id">{{ $i18n('LABEL_SHOW_DETAILS') }}</span>
                            <span v-if="showSubscriptionId==subscription.id">{{ $i18n('LABEL_HIDE_DETAILS') }}</span>
                        </button>
                    </td>
                </tr>
                <tr 
                    v-for="additionalSubscription in getAdditionalSubscriptions(subscription.id)" 
                    v-show="(mode=='active' && additionalSubscription.ended==null) || (mode=='ended' && additionalSubscription.ended!=null)"
                    :key="additionalSubscription.id"
                    :class="{'align-middle':showSubscriptionId!=additionalSubscription.id}">
                    <td>
                        <div class="additional-subscription ms-3">
                            {{ getProductName(additionalSubscription) }}
                            <div v-if="mode=='ended' && !activateSubscriptionEnabled(additionalSubscription.parentId)" class="small text-muted ms-3">
                                {{ $i18n('LABEL_CANNOT_ACTIVATE_ADDITIONAL_SUBSCRIPTION') }}
                            </div>
                            <div v-if="additionalSubscription.history && showSubscriptionId==additionalSubscription.id" class="small mt-3 ms-3">
                                <strong>{{ $i18n('LABEL_SUBSCRIPTION_HISTORY') }}</strong>
                                <ul class="list-unstyled mb-2">
                                    <li 
                                        v-for="(historyObj, index) in additionalSubscription.history"
                                        :key="'history-'+index"
                                    >
                                        {{ getHistoryName(historyObj.name) }}: {{ historyObj.value }}
                                    </li>
                                </ul>
                                <button @click="showSubscriptionId=null" type="button" class="btn btn-secondary btn-sm">
                                    {{ $i18n('LABEL_CLOSE') }}
                                </button>
                            </div>
                        </div>
                    </td>
                    <td class="text-end text-nowrap shrink">
                        {{ $formatDate(additionalSubscription.created) }}
                    </td>
                    <td v-if="mode=='ended'" class="text-end text-nowrap shrink">
                        {{ $formatDate(additionalSubscription.ended) }}
                    </td>
                    <td class="text-end text-nowrap shrink">
                        {{ getPrice(additionalSubscription) }}
                    </td>
                    <td class="text-end shrink">
                        <button 
                            v-if="mode=='active'"
                            @click="endSubscriptionConfirm(additionalSubscription.id)" 
                            type="button"
                            class="btn btn-danger btn-sm"
                            :disabled="additionalSubscription.userId!=$user().id"
                        >
                            {{ $i18n('LABEL_END_SUBSCRIPTION') }}
                        </button>
                        <button 
                            v-if="mode=='ended'"
                            @click="continueSubscriptionConfirm(additionalSubscription.id)" 
                            :disabled="!activateSubscriptionEnabled(additionalSubscription.parentId)"
                            type="button"
                            class="btn btn-primary btn-sm"
                        >
                            {{ $i18n('LABEL_CONTINUE_SUBSCRIPTION') }}
                        </button>
                    </td>
                    <td class="text-end shrink">
                        <button 
                            @click="toggleShowSubscription(additionalSubscription.id)" 
                            :disabled="!additionalSubscription.history"
                            type="button" 
                            class="btn btn-primary btn-sm"
                        >
                            <span v-if="showSubscriptionId!=additionalSubscription.id">{{ $i18n('LABEL_SHOW_DETAILS') }}</span>
                            <span v-if="showSubscriptionId==additionalSubscription.id">{{ $i18n('LABEL_HIDE_DETAILS') }}</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
import store from '../store/store.js';
import BillingInfo from '../components/BillingInfo.vue';

export default {
    name: 'SubscriptionsTable',
    components: {
        BillingInfo
    },
    props: {
        mode: {
            type: String,
            default: 'active',
            validator: function(value) {
                // Valid values for mode
                return ['active', 'ended'].indexOf(value) !== -1;
            }
        },
        subscriptions: {
            type: Array
        }
    },
    data() {
        return {
            showSubscriptionId: null
        }
    },
    computed: {
        tableSubscriptions() {
            if (this.mode == 'active') {
                return this.activeBasicSubscriptions;
            } else if (this.mode == 'ended') {
                return this.basicSubscriptions;
            }
            return null;
        },
        basicSubscriptions() {
            if (this.subscriptions) {
                return this.subscriptions.filter(item => !item.parentId)
            }
            return null;
        },
        activeBasicSubscriptions() {
            if (this.subscriptions) {
                return this.subscriptions.filter(item => !item.parentId && item.ended == null)
            }
            return null;
        },
        endedSubscriptions() {
            if (this.subscriptions) {
                return this.subscriptions.filter(item => item.ended != null)
            }
            return null;
        }
    },
    methods: {
        /**
         * @param   {object}    subscription
         * @returns {array}
         */
        getDomains(subscription) {
            return subscription.domains.split(',');
        },
        /**
         * @param   {number}    parentId
         * @returns {array|null}
         */
        getAdditionalSubscriptions(parentId) {
            if (this.subscriptions) {
                return this.subscriptions.filter(item => item.parentId == parentId);
            }
            return null;
        },
        /**
         * Gets product localized name by product id.
         * @param   {object}    subscription
         * @returns {string}
         */
        getProductName(subscription) {
            // Old basic subscriptions will be shown as KarttaMap Custom
            if (subscription.productId == 1 && subscription.created <= '2022-09-22 12:00:00') {
                return 'KarttaMap Custom';
            }
            return this.$i18n('LABEL_PRODUCT_' + subscription.productId);
        },
        /**
         * Gets subscription price based on it's base price and amount of domains.
         * @param   {object}    subscription
         * @returns {string}
         */
        getPrice(subscription) {
            if (subscription.price) {
                return subscription.price + ' &euro;';
            } else if (subscription.pricePerMonth) {
                return subscription.pricePerMonth + ' €/' + this.$i18n('LABEL_PER_MONTH');
            } else if (subscription.pricePerYear) {
                return subscription.pricePerYear + ' €/' + this.$i18n('LABEL_PER_YEAR');
            }
            return null;
        },
        /**
         * @param   {number}    id
         * @returns {object|null}
         */
        getBillingAddress(id) {
            if (store.state.billingAddresses) {
                return store.state.billingAddresses.find(item => item.id == id);
            }
            return null;
        },
        getBillingAddressName(id) {
            let billingAddress = this.getBillingAddress(id);
            if (billingAddress) {
                if (billingAddress.firstName && billingAddress.lastName) {
                    return billingAddress.firstName + ' ' + billingAddress.lastName;
                } else if (billingAddress.company) {
                    return billingAddress.company;
                }
            }
            return null;
        },
        getHistoryName(name) {
            return this.$i18n('LABEL_HISTORY_' + name.toUpperCase());
        },
        activateSubscriptionEnabled(parentId) {
            if (this.subscriptions) {
                return Boolean(this.subscriptions.find(item => item.id == parentId && item.ended == null));
            }
            return false;
        },
        toggleShowSubscription(subscriptionId) {
            if (this.showSubscriptionId == subscriptionId) {
                this.showSubscriptionId = null;
            } else {
                this.showSubscriptionId = subscriptionId
            }
        },
        endSubscriptionConfirm(subscriptionId) {
            store.commit('setModal', {
                component: 'SubscriptionEndConfirm',
                componentProps: {
                    id: subscriptionId
                },
                size: 'modal-lg'
            });
        },
        continueSubscriptionConfirm(subscriptionId) {
            store.commit('setModal', {
                component: 'SubscriptionContinueConfirm',
                componentProps: {
                    id: subscriptionId
                },
                size: 'modal-lg'
            });
        }
    }
}
</script>

<style scoped>
.additional-subscription::before {
    content: '+';
    margin-right: .5rem;
}
</style>
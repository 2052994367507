<template>
    <div class="position-relative bg-white p-3 my-3 shadow-sm">
        
        <OverlaySpinner v-if="! customCategories || $loading()" />

        <h1>{{ $i18n('LABEL_CATEGORIES') }}</h1>

        <Alerts />
        
        <p>{{ $i18n('TEXT_CATEGORIES') }}</p>

        <button type="button" @click="openLocationsDBCategoriesList()" class="btn btn-primary">
            {{ $i18n('LABEL_SHOW_DEFAULT_CATEGORIES') }}
        </button>

        <h2 class="mt-5">{{ $i18n('LABEL_CUSTOM_CATEGORIES') }}</h2>

        <div v-if="customCategories && customCategories.length >= config.freeCategoriesLimit && ! $hasBasicSubscription()" v-html="$i18n('HTML_CATEGORIES_LIMIT_REACHED')" class="alert alert-danger"></div>
        <button v-else type="button" @click="openAddNewCategory()" class="btn btn-primary">
            {{ $i18n('LABEL_ADD_NEW_CUSTOM_CATEGORY') }}
        </button>

        <table v-if="customCategories" class="table table-hover align-middle my-4">
            <thead class="bg-dark text-white">
                <tr>
                    <th scope="col">{{ $i18n('LABEL_NAME') }}</th>
                    <th scope="col">{{ $i18n('LABEL_ICON') }}</th>
                    <th scope="col">{{ $i18n('LABEL_LANGUAGES') }}</th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_DELETE') }}</span>
                    </th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="category in customCategoriesCurrentLan"
                    :key="category.id">
                    <td>
                        {{ category.name }}
                        <div v-if="getCategoryEditing(category.id)" class="d-flex align-items-center small text-muted">
                            <BIconLockFill /> {{ getCategoryEditing(category.id).userName }} {{ $i18n('LABEL_IS_EDITING') }} 
                        </div>
                    </td>
                    <td>
                        <img 
                            v-if="category.icon"
                            :src="category.icon"
                            class="list-category-img"
                            :alt="category.name" />
                    </td>
                    <td>
                        <span v-for="catLan in categoryLanguages(category.id)" :key="catLan" class="me-2">
                            {{ catLan.toUpperCase() }}
                        </span>
                    </td>
                    <td class="text-end shrink">
                        <DeleteConfirmButton v-if="!getCategoryEditing(category.id)" deleteEventName ="deleteCategory" :deleteEventPayload="category.id" />
                    </td>
                    <td class="text-end shrink">
                        <button v-if="!getCategoryEditing(category.id)" @click="openEditCategory(category.id, category.lan)" class="btn btn-primary btn-sm">
                            <BIconPencilFill />
                            <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconLockFill, BIconPencilFill } from 'bootstrap-icons-vue';
import Alerts from '../components/Alerts.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'CustomCategories',
    components: {
        Alerts,
        BIconLockFill,
        BIconPencilFill, 
        DeleteConfirmButton,
        OverlaySpinner
    },
    data() {
        return {
            confirmCategoryDelete: null
        }
    },
    computed: {
        config() {
            return store.state.config;
        },
        customCategories() {
            return store.state.customCategories;
        },
        customCategoriesCurrentLan() {
            return store.state.customCategories.filter(item => item.lan == this.lan);
        },
        lan() {
            return store.state.lan;
        },
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        categoryEditings() {
            if (store.state.editings) {
                return store.state.editings.filter(item => item.categoryId != null);
            }
            return null;
        }
    },
    watch: {
        activeWorkspaceId() {
            this.$emitter.emit('getCustomCategories');
        }
    },
    created() {
        this.$emitter.on('ready', () => {
            this.$emitter.emit('getCustomCategories');
        });
        this.$emitter.on('deleteCategory', (id) => {
            this.deleteCategory(id);
        });
    },
    mounted() {
        //this.$emitter.emit('getCustomCategories');
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('ready');
        this.$emitter.all.delete('deleteCategory');
    },
    methods: {
        getCategoryEditing(categoryId) {
            if (this.categoryEditings) {
                let editing = this.categoryEditings.find(item => item.categoryId == categoryId);
                if (editing) {
                    return editing;
                }
            }
            return null;
        },
        categoryLanguages(catId) {
            let catLans = [];
            this.customCategories.forEach((item) => {
                // Get other languages than current lan for this category
                if (item.id == catId && item.lan != this.lan) {
                    catLans.push(item.lan);
                }
            });
            catLans.sort();
            // Put current lan first
            catLans.unshift(this.lan);
            return catLans;
        },
        openLocationsDBCategoriesList() {
            store.commit('setModal', {
                component: 'LocationsDbCategoriesListModal',
                size: 'modal-lg',
                fullHeight: false
            });
        },
        openAddNewCategory() {
            store.commit('setModal', {
                component: 'CustomCategoryEdit',
                size: 'modal-lg',
                fullHeight: false
            });
        },
        openEditCategory(categoryId, categoryLan) {
            store.commit('setModal', {
                component: 'CustomCategoryEdit',
                componentProps: {
                    initCustomCategory: this.customCategories.find((category) => category.id == categoryId && category.lan == categoryLan)
                },
                size: 'modal-lg',
                fullHeight: false
            });
        },
        /**
         * Deletes a category and all it's translations from the DB (soft delete).
         */
        deleteCategory(categoryId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting category');
            store.commit('clearErrors');
            store.commit('clearMessages');

            axios({
                method: 'delete',
                url: '/api/category/' + categoryId + '/' + this.activeWorkspaceId,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.$emitter.emit('getCustomCategories');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <form 
        id="loginForm"
        action="api/login" 
        method="post" 
        class="needs-validation mb-3" 
        :class="{'was-validated': loginFormWasValidated}"
        @submit="postLogin($event)" 
        novalidate>
        <div class="mb-3">
            <label for="email" class="form-label visually-hidden">{{ $i18n('LABEL_EMAIL') }}</label>
            <input v-model="email" type="email" id="email" name="email" class="form-control" autocomplete="email" :placeholder="$i18n('LABEL_EMAIL')" required />
            <div class="invalid-feedback">
                Type a valid email.
            </div>
        </div>
        <div class="mb-3">
            <label for="password" class="form-label visually-hidden">{{ $i18n('LABEL_PASSWORD') }}</label>
            <input v-model="password" type="password" id="password" name="password" class="form-control" autocomplete="current-password" :placeholder="$i18n('LABEL_PASSWORD')" required />
            <div class="invalid-feedback">
                {{ $i18n('LABEL_FIELD_REQUIRED') }}
            </div>
        </div>

        <div class="d-grid gap-2 text-center">
            <button type="submit" class="btn btn-primary">{{ $i18n('LABEL_LOG_IN') }}</button>
        </div>

    </form> <!-- /#loginForm -->

    <ul class="list-unstyled mb-0">
        <li>{{ $i18n('LABEL_NO_USER_ACCOUNT') }} <router-link to="sign-up">{{ $i18n('LABEL_SIGN_UP_HERE') }}</router-link></li>
        <li><a href="#requestResetPassword" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="requestResetPassword">{{ $i18n('LABEL_FORGOT_YOUR_PASSWORD') }}</a></li>
    </ul>

    <div id="requestResetPassword" class="collapse">

        <form 
            id="requestResetPasswordForm"
            action="api/reset-password-send-link" 
            method="post" 
            class="needs-validation mb-3" 
            :class="{'was-validated': requestResetPasswordFormWasValidated}"
            @submit="postRequestResetPassword($event)" 
            novalidate>
            <div class="mb-3">
                <label for="resetPasswordEmail" class="form-label visually-hidden">Email</label>
                <input v-model="resetPasswordEmail" type="email" id="resetPasswordEmail" name="resetPasswordEmail" class="form-control" placeholder="Your user account email" required />
                <div class="invalid-feedback">
                    Type a valid email.
                </div>
            </div>

            <div class="d-grid gap-2 text-center">
                <button type="submit" class="btn btn-primary">Send reset link</button>
            </div>

        </form> <!-- /#requestResetPasswordForm -->

    </div>
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';

export default {
    name: 'LoginForm',
    data() {
        return {
            email: null,
            password: null,
            loginFormWasValidated: false,
            requestResetPasswordFormWasValidated: false,
            resetPasswordEmail: null
        }
    },
    methods: {
        /**
         * @param   {object}    e   Native event object. 
         */
        postLogin(e) {
            e.preventDefault();
            e.stopPropagation();
            this.loginFormWasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Logging in');
            store.commit('clearErrors');
            store.commit('clearMessages');

            let formData = new FormData(document.getElementById('loginForm'));

            axios({
                method: 'post',
                url: '/api/login',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setMessages', response.data.messages);
                    }
                    store.commit('setUser', response.data.user);
                    this.$router.push('dashboard');
                    this.$emitter.emit('initLoad');
                } else if (response.data.errors) {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        /**
         * @param   {object}    e   Native event object.
         */
        postRequestResetPassword(e) {
            e.preventDefault();
            e.stopPropagation();
            this.requestResetPasswordFormWasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Sending password reset link');

            let formData = new FormData(e.target);

            axios({
                method: 'post',
                url: '/api/reset-password-send-link',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setModalMessages', response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
    }
}

</script>
<style scoped>

</style>
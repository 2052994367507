<template>

    <div id="mapEditOptionsGeneral">

        <div class="row my-4">

            <div class="col-xl-6">
                
                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.settingsPanelClosed" 
                        id="settingsPanelClosed" 
                        class="form-check-input" 
                        value="true" />
                    <label for="settingsPanelClosed" class="form-check-label">
                        {{ $i18n('LABEL_SETTINGS_PANEL_CLOSED') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.showTypeFilters" 
                        id="showTypeFilters" 
                        class="form-check-input" 
                        value="true" />
                    <label for="showTypeFilters" class="form-check-label">
                        {{ $i18n('LABEL_SHOW_TYPE_FILTERS') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.showCategoryFilters" 
                        id="showCategoryFilters" 
                        class="form-check-input" 
                        value="true" />
                    <label for="showCategoryFilters" class="form-check-label">
                        {{ $i18n('LABEL_SHOW_CATEGORY_FILTERS') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.showGeosearch" 
                        id="showGeosearch" 
                        class="form-check-input" 
                        value="true" />
                    <label for="showGeosearch" class="form-check-label">
                        {{ $i18n('LABEL_SHOW_SEARCH_FIELD') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.showMyLocationButton" 
                        id="showMyLocationButton" 
                        class="form-check-input" 
                        value="true" />
                    <label for="showMyLocationButton" class="form-check-label">
                        {{ $i18n('LABEL_SHOW_MY_LOCATION_BUTTON') }}
                    </label>
                </div>
                
            </div>
            <div class="col-xl-6">

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.showFullscreenToggle" 
                        @change="showFullscreenToggleChange()" 
                        id="showFullscreenToggle" 
                        class="form-check-input" 
                        value="true" />
                    <label for="showFullscreenToggle" class="form-check-label">
                        {{ $i18n('LABEL_SHOW_FULLSCREEN_TOGGLE') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.fullscreenOnFocus" 
                        @change="fullscreenOnFocusChange()" 
                        id="fullscreenOnFocus" 
                        class="form-check-input" 
                        value="true" />
                    <label for="fullscreenOnFocus" class="form-check-label">
                        {{ $i18n('LABEL_MAKE_MAP_FULLSCREEN_ON_FOCUS') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.showShareButtons" 
                        id="showShareButtons" 
                        class="form-check-input" 
                        value="true" />
                    <label for="showShareButtons" class="form-check-label">
                        {{ $i18n('LABEL_SHOW_SHARE_BUTTONS') }}
                    </label>
                </div>

                <div class="form-check">
                    <input 
                        type="checkbox" 
                        v-model="mapData.config.anyLan" 
                        id="anyLan" 
                        class="form-check-input" 
                        value="true" />
                    <label for="anyLan" class="form-check-label">
                        {{ $i18n('LABEL_ANY_LAN') }}
                    </label>
                </div>

            </div>

        </div> <!-- /.row -->

    </div> <!-- /#mapEditOptionsGeneral -->

</template>

<script>

export default {
    name: 'MapEditOptionsGeneral',
    components: {
        
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        mapData() {
            return this.modelValue;
        }
    },
    methods: {
        /**
         * Sets fullscreenOnFocus false if showFullscreenToggle is false.
         */
        showFullscreenToggleChange() {
            if (! this.mapData.config.showFullscreenToggle) {
                this.mapData.config.fullscreenOnFocus = false;
            }
        },
        /**
         * Sets showFullscreenToggle true if fullscreenOnFocus is true.
         */
        fullscreenOnFocusChange() {
            if (this.mapData.config.fullscreenOnFocus) {
                this.mapData.config.showFullscreenToggle = true;
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div class="input-group">
        <span class="input-group-text"><BIconSearch /></span>
        <input 
            v-model="inputValue"
            @keyup="inputHandler($event)"
            :type="inputType" 
            :placeholder="placeholder" 
            class="form-control"
            :class="{loading: loading}"
        />
    </div>
    <div class="position-relative">
        <ul 
            v-if="suggestions.length"
            :class="suggestionsUlClass"
            class="suggestions-list">
            <li 
                v-for="(suggestion, index) in suggestions" 
                @click="selectSuggestion(index)"
                :key="'suggestion-'+index"
                :class="[suggestionsLiClass, {active: (activeSuggestion == index)}]"
                class="suggestions-list-item">
                {{ suggestion }}
            </li>
        </ul>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconSearch } from 'bootstrap-icons-vue';

export default {
    name: 'InputSuggestions',
    components: {
        BIconSearch
    },
    props: {
        displayProp: {
            type: String,
            default: 'title'
        },
        inputType: {
            type: String,
            default: 'text'
        },
        minChars: {
            type: Number,
            default: 3
        },
        placeholder: String,
        searchProp: {
            type: String,
            default: 'title'
        },
        source: Array,
        sourceUrl: String,
        suggestionsLiClass: {
            type: String,
            default: 'list-group-item'
        },
        suggestionsUlClass: {
            type: String,
            default: 'list-group'
        }
    },
    data() {
        return {
            loading: false,
            inputValue: '',
            sourceData: null,
            suggestions: [],
            activeSuggestion: 0
        }
    },
    computed: {
        
    },
    emits: {
        select: (item) => {
            if (item) {
                return true;
            } else {
                console.warn('Invalid select event payload!');
                return false;
            }
        }
    },
    methods: {
        inputHandler(e) {
            if (e.keyCode == 38) {
                // Arrow up
                this.setActiveSuggestion(-1);
            } else if (e.keyCode == 40) {
                // Arrow down
                this.setActiveSuggestion(1);
            } else if (e.keyCode == 13) {
                // Enter
                if (this.activeSuggestion) {
                    this.selectSuggestion(this.activeSuggestion);
                }
            } else {
                this.suggest();
            }
        },
        /**
         * Sets active suggestion in the list when moving with arrow up/down keys.
         * 
         * @param   {Number}    direction   1 for down, -1 for up.
         */
        setActiveSuggestion(direction) {
            // No suggestions, set null
            if (! this.suggestions.length) {
                this.activeSuggestion = null;
                return;
            }
            // Going down from the start
            if (this.activeSuggestion == null && direction == 1) {
                this.activeSuggestion = 0;
                return;
            }
            // Going up from the start
            if (this.activeSuggestion == null && direction == -1) {
                this.activeSuggestion = this.suggestions.length - 1;
                return;
            }
            // We're somewhere in the middle
            this.activeSuggestion += direction;
            // Reached the first one already, set null
            if (this.activeSuggestion < 0) {
                this.activeSuggestion = null;
                return;
            }
            // Reached the last one already, set null
            if (this.activeSuggestion == this.suggestions.length) {
                this.activeSuggestion = null;
                return;
            }
        },
        suggest() {
            // Clear old suggestions first
            this.suggestions = [];
            this.activeSuggestion = null;

            // Check that input is at least minChars long
            if (this.inputValue.length < this.minChars) {
                return;
            }

            if (this.sourceUrl) {
                this.loading = true;
                // Source URL defined, get source from there
                let url = this.sourceUrl.replace('{{term}}', this.inputValue);

                axios({
                    method: 'get',
                    url: url
                })
                .then((response) => {
                    this.sourceData = response.data;
                    this.searchSuggestions(this.sourceData);
                })
                .catch((error) => {
                    store.commit('addError', 'Loading data failed');
                    console.error(error);
                })
                .then(() => {
                    this.loading = false;
                });
            } else {
                this.searchSuggestions(this.source);
            }
        },
        searchSuggestions(source) {
                // Clear old suggestions first
                // Yes, this is needed here also because sourceUrl requests are fired async
                this.suggestions = [];
                this.activeSuggestion = null;
                // Loop through source
                source.forEach((item) => {
                    //console.log(item);
                    if (item[this.searchProp].toLowerCase().search(this.inputValue.toLowerCase()) != -1) {
                        this.suggestions.push(item[this.displayProp]);
                    }
                });
        },
        selectSuggestion(index) {
            let item = this.sourceData[index];
            //this.inputValue = item[this.displayProp];
            this.inputValue = null;
            this.suggestions = [];
            this.activeSuggestion = null;
            this.$emit('select', item);
        }
    }
}
</script>

<style scoped>
.loading {
    background-image: url('../assets/images/loader-16x16px.gif');
    background-position: right 12px center;
    background-repeat: no-repeat;
}
.suggestions-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}
.suggestions-list-item {
    background-color: var(--bs-light);
    font-size: .85rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}
.suggestions-list-item:hover {
    background-color: var(--bs-primary);
}

</style>

<template>

    <div class="row">
        <div class="col-xl">

            <h3 class="h4">{{ $i18n('LABEL_SOURCES_USED_ON_MAP') }}</h3>

            <!-- p class="form-text">
                {{ $i18n('TEXT_SOURCES') }}
            </p -->

            <ul 
                class="list-group mb-3">
                <!-- Locations DB sources -->
                <li
                    class="list-group-item bg-light d-flex align-items-center"
                    v-for="(sourceId, index) in sourceIds"
                    :key="sourceId">
                    <div class="flex-grow-1">
                        <strong>{{ getSourceName(sourceId) }}</strong><br />
                        <div v-if="! locationsDbSources" class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading</span>
                        </div>
                        <small v-if="getLocationsDbSource(sourceId)" class="text-muted">
                            ({{ getLocationsDbSource(sourceId).placesCount }} {{ $i18n('LABEL_PLACES_COUNT') }},
                            {{ getLocationsDbSource(sourceId).routesCount }} {{ $i18n('LABEL_ROUTES_COUNT') }})
                        </small>
                    </div>
                    <button 
                        v-if="sourceIsTesting(sourceId)" 
                        @click="endTestSource(sourceId)"
                        type="button" 
                        class="btn btn-danger">
                        {{ $i18n('LABEL_END_TESTING') }}
                    </button>
                    <button 
                        v-if="sourceIsInUse(sourceId) && index > 0" 
                        @click="removeSource(sourceId)"
                        type="button" 
                        class="btn btn-danger">
                        {{ $i18n('LABEL_REMOVE') }}
                    </button>
                </li>
                <!-- External sources -->
                <li
                    class="list-group-item bg-light d-flex align-items-center"
                    v-for="(extSourceId) in externalSourceNames"
                    :key="extSourceId">
                    <div class="flex-grow-1">
                        <strong>{{ getSourceName(extSourceId) }}</strong>
                    </div>
                    <button 
                        v-if="sourceIsTesting(extSourceId)" 
                        @click="endTestSource(extSourceId)"
                        type="button" 
                        class="btn btn-danger">
                        {{ $i18n('LABEL_END_TESTING') }}
                    </button>
                    <button 
                        v-if="sourceIsInUse(extSourceId)" 
                        @click="removeSource(extSourceId)"
                        type="button" 
                        class="btn btn-danger">
                        {{ $i18n('LABEL_REMOVE') }}
                    </button>
                </li>
            </ul>

        </div>

        <div v-if="! sourceProducts || sourceProducts.length > 0" class="col-xl position-relative">
            <OverlaySpinner v-if="! products" />
            
            <h3 class="h4">{{ $i18n('LABEL_PURCHASE_SOURCES') }}</h3>
            
            <ul v-if="userSourceProductsData" class="list-group">
                <li 
                    v-for="product in sourceProducts"
                    :key="'map-layer-' + product.id"
                    class="list-group-item bg-light d-flex align-items-center pt-2">
                    <div class="flex-grow-1">
                        <h4 class="h5 m-0">{{ getProductName(product.id) }}</h4>
                        <p class="lh-sm mb-2">
                            <small v-if="getProductDescription(product.id)" class="text-muted">
                                {{ getProductDescription(product.id) }}
                                <span v-if="getLocationsDbSource(product.data.id)">
                                    <br />
                                    ({{ getLocationsDbSource(product.data.id).placesCount }} {{ $i18n('LABEL_PLACES_COUNT') }},
                                    {{ getLocationsDbSource(product.data.id).routesCount }} {{ $i18n('LABEL_ROUTES_COUNT') }})
                                </span>
                            </small>
                        </p>
                        <div v-if="! locationsDbSources" class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading</span>
                        </div>
                        <!-- Show prices only if user has not purchased the product already -->
                        <div v-if="! userHasProduct(product.id)">
                            <p v-if="product.price" class="m-0">
                                <small><strong>{{ product.price.toFixed(2) }} &euro;</strong></small>
                            </p>
                            <p v-if="product.pricePerMonth || product.pricePerYear" class="m-0">
                                <small><strong v-if="product.pricePerMonth">{{ product.pricePerMonth.toFixed(2) }} &euro;</strong>/{{ $i18n('LABEL_PER_MONTH') }} (+ {{ $i18n('LABEL_VAT') }})</small>
                                <br v-if="product.pricePerMonth && product.pricePerYear" />
                                <small><strong v-if="product.pricePerYear">{{ product.pricePerYear.toFixed(2) }} &euro;</strong>/{{ $i18n('LABEL_PER_YEAR') }} (+ {{ $i18n('LABEL_VAT') }})</small>
                            </p>
                        </div>
                    </div>
                    <button 
                        v-if="userHasProduct(product.id)" 
                        @click="useSource(product.data)"
                        type="button" 
                        class="btn btn-primary ms-3"
                        :disabled="sourceIsInUse(product.data.id)">
                        {{ $i18n('LABEL_USE') }}
                    </button>
                    <div v-if="! userHasProduct(product.id)" class="d-grid gap-2 ms-3">
                        <button 
                            v-if="! sourceIsTesting(product.data.id)"
                            @click="testSource(product.data.id)" 
                            type="button" 
                            class="btn btn-primary">
                            {{ $i18n('LABEL_TEST') }}
                        </button>
                        <button 
                            v-if="sourceIsTesting(product.data.id)" 
                            @click="endTestSource(product.data.id)"
                            type="button" 
                            class="btn btn-danger text-nowrap">
                            {{ $i18n('LABEL_END_TESTING') }}
                        </button>
                        <router-link :to="getProductLink(product)" class="btn btn-primary">{{ $i18n('LABEL_BUY') }}</router-link>
                    </div>
                </li>
            </ul>

        </div>
    </div> <!-- /.row -->

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'MapEditSources',
    components: {
        OverlaySpinner
    },
    props: {
        sourceIds: Array,
        externalSourceNames: Array,
        testSources: Array
    },
    data() {
        return {
           locationsDbSources: null
        }
    },
    computed: {
        products() {
            return store.state.products;
        },
        sourceProducts() {
            if (store.state.products) {
                return store.state.products.filter((item) => item.categoryId == 3);
            }
            return null;
        },
        userSourceProducts() {
            if (store.state.products && store.state.subscriptions) {
                return store.state.products.filter((item) => {
                    return item.categoryId == 3 && this.userHasProduct(item.id);
                });
            }
            return [];
        },
        userSourceProductsData() {
            let dataArr = [];
            if (this.userSourceProducts) {
                this.userSourceProducts.forEach(item => {
                    dataArr.push(item.data);
                });
            }
            return dataArr;
        },
        /**
         * Returns external source names in an array.
         * @returns {array}
         */
        /*externalSourceNames() {
            let extSourceNames = [];
            if (this.externalSources) {
                for (let group in this.externalSources) {
                    for (let i in this.externalSources[group].sources) {
                        let sourceId = this.externalSources[group].sources[i].id;
                        extSourceNames.push(sourceId);
                    }
                }
                console.log(extSourceNames);
                console.log(this.externalSources);
            }
            return extSourceNames;
        }*/
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        }
    },
    created() {
        this.getLocationsDbSources();
    },
    mounted() {
        //console.log(this.$user().sourceId);
        //console.log(this.activeWorkspace);
    },
    methods: {
        isUserSource(sourceId) {
            return sourceId == this.$user().sourceId;
        },
        getSourceName(sourceId) {
            if (sourceId == this.activeWorkspace.sourceId) {
                return this.activeWorkspace.name;
            }
            
            let sourceProduct = this.sourceProducts.find((item) => item.data.id == sourceId);
            
            if (sourceProduct) {
                //return sourceProduct.name;
                return this.$i18n('LABEL_PRODUCT_' + sourceProduct.id);
            }

            return 'Source ' + sourceId;
        },
        /**
         * @param   {number}    id
         * @returns {string}
         */
        getProductName(id) {
            return this.$i18n('LABEL_PRODUCT_' + id);
        },
        /**
         * @param   {number}    id
         * @returns {string}
         */
        getProductDescription(id) {
            if (this.$i18nExists('TEXT_PRODUCT_' + id)) {
                return this.$i18n('TEXT_PRODUCT_' + id);
            }
            return null;
        },
        /**
         * @param   {object}    product
         * @returns {string}
         */
        getProductLink(product) {
            return '/shop/' + product.id + '/' + product.name;
        },
        userHasProduct(productId) {
            if (store.state.subscriptions) {
                return store.state.subscriptions.find(item => item.productId == productId && item.ended == null);
            }
            return false;
        },
        sourceIsTesting(sourceId) {
            return this.testSources.find((id) => {
                return id == sourceId;
            });
        },
        sourceIsInUse(sourceId) {
            // Skip user's own source
            if (sourceId == this.$user().sourceId) {
                return false;
            }
            // Not in use if testing
            if (this.sourceIsTesting(sourceId)) {
                return false;
            }
            if (isNaN(sourceId)) {
                // Id not a number, it's an external source
                return this.externalSourceNames.find((item) => {
                    return item == sourceId;
                });
            } else {
                // Id is a number, it's a Locations DB source
                return this.sourceIds.find((item) => {
                    return item == sourceId;
                });
            }
        },
        testSource(sourceId) {
            this.$emitter.emit('testSource', sourceId);
        },
        endTestSource(sourceId) {
            this.$emitter.emit('endTestSource', sourceId);
        },
        /*useSource(sourceId) {
            this.$emitter.emit('useSource', sourceId);
        },*/
        useSource(sourceData) {
            this.$emitter.emit('useSource', sourceData);
        },
        removeSource(sourceId) {
            this.$emitter.emit('removeSource', sourceId);
        },
        /**
         * Loads sources from Locations DB and sets them to locationsDbSources.
         */
        getLocationsDbSources() {
            axios({
                method: 'get',
                url: process.env.VUE_APP_LOCATIONS_PATH + 'api/sources'
            })
            .then((response) => {
                this.locationsDbSources = response.data;
            })
            .catch((error) => {
                store.commit('addModalError', 'Loading Locations DB sources failed');
                console.error(error);
            });
        },
        getLocationsDbSource(sourceId) {
            if (! this.locationsDbSources) {
                return null;
            }
            return this.locationsDbSources.find(item => item.id == sourceId);
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <dl v-if="lan=='fi'" class="new-notes">
        <dt>22.02.2022</dt>
        <dd>Kohteiden ja reittien popupissa on nyt aina Lue lisää -painike riippumatta siitä, onko kohteella tai reitillä artikkelitekstiä. Artikkelinäkymä päivitetty esim. kategorioilla ja pikkukartalla.</dd>
        <dt>06.12.2021</dt>
        <dd>Lisätty työtilat, joiden avulla voi kutsua muita käyttäjiä muokkaamaan omaa sisältöä.</dd>
        <dt>11.10.2021</dt>
        <dd>Lisätty yli 1300 ikonin kirjasto omien kategoriakuvien luomiseen.</dd>
        <dt>06.10.2021</dt>
        <dd>Lisätty karttatasoihin merikartat, syvyyskäyrät ja syvyyspisteet.</dd>
        <dt>24.09.2021</dt>
        <dd>Lisätty kartan asetuksiin optio kategoriasuodattimien näyttämiselle.</dd>
        <dd>Korjattu bugeja lähteiden kokeilussa.</dd>
        <!-- dt>17.09.2021</dt>
        <dd>Lisätty kohteiden ja reittien kieliversionti.</dd>
        <dt>03.09.2021</dt>
        <dd>Lisätty kartan ja kategorioiden kieliversionti.</dd>
        <dt>16.08.2021</dt>
        <dd>Lisätty lähteisiin Retkipaikan artikkelit.</dd -->
    </dl>

    <dl v-if="lan!='fi'" class="new-notes">
        <dt>22.02.2022</dt>
        <dd>Place and route popups now always have Read more button regardless if the place or route has an article text. The article view is updated for example with categories and a small map.</dd>
        <dt>06.12.2021</dt>
        <dd>Added workspaces which enables inviting other users to edit your content.</dd>
        <dt>11.10.2021</dt>
        <dd>Added an icon library with over 1300 icons for creating own category icons.</dd>
        <dt>06.10.2021</dt>
        <dd>Added nautical chart, depth countours and depth sounding points to overlay layers.</dd>
        <dt>24.09.2021</dt>
        <dd>Added option to show/hide category filters in map settings.</dd>
        <dd>Fixed bugs in testing sources.</dd>
        <!-- dt>17.09.2021</dt>
        <dd>Added places and routes translations.</dd>
        <dt>03.09.2021</dt>
        <dd>Added map language option and categories translations.</dd>
        <dt>16.08.2021</dt>
        <dd>Added Retkipaikka articles to sources.</dd -->
    </dl>

</template>

<script>
import store from '../store/store.js';

export default {
    name: 'NewNotes',
    components: {
        
    },
    props: {
        
    },
    computed: {
        lan() {
            return store.state.lan;
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
.new-notes dt {
    font-size: .75rem;
}
.new-notes dd {
    line-height: 1;
}
</style>

<template>
    <div class="login-container d-flex flex-column justify-content-center align-items-center" :class="{'registration-container': $route.name == 'Registration'}">
        
        <img src="../assets/images/matkailukartta.svg" class="logo mb-1" alt="Matkailukartta" />

        <div class="login position-relative bg-white p-3 rounded">

            <OverlaySpinner 
                v-if="$loading()"
                :showStatusText="true" 
                :statusText="$loadingStatus()"
            />

            <Alerts />

            <!-- LOGIN FORM -->
            <div v-if="$route.name == 'Login'">
                <LoginForm />
            </div>

            <!-- REGISTRATION FORM -->
            <div v-if="$route.name == 'Registration'">
                <RegistrationForm />
            </div>

            <!-- RESET PASSWORD FORM -->
            <div v-if="$route.name == 'ResetPassword'">
                <ResetPasswordForm />
            </div>

            <!-- REGISTRATION ACTIVATION -->
            <div v-if="$route.name == 'RegistrationActivation'">
                <RegistrationActivation />
            </div>

        </div>

    </div>

</template>

<script>
import store from '../store/store.js';
import Alerts from '../components/Alerts.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';
import LoginForm from '../components/LoginForm.vue';
import RegistrationForm from '../components/RegistrationForm.vue';
import ResetPasswordForm from '../components/ResetPasswordForm.vue';
import RegistrationActivation from '../components/RegistrationActivation.vue';

export default {
    name: 'Login',
    components: {
        Alerts,
        OverlaySpinner,
        LoginForm,
        RegistrationForm,
        ResetPasswordForm,
        RegistrationActivation
    },
    data() {
        return {
            
        }
    },
    created() {
        // Redirect to dashboard if already logged in
        if (store.state.user) {
            this.$router.push('dashboard');
        }
    },
    mounted() {
        store.commit('setLoading', false);
    },
    methods: {
        
    }
}
</script>

<style scoped>
.login-container {
    background-image: url('../assets/images/matkailukartta-bg.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    background-attachment: fixed;
    min-height: 100vh !important;
}
.login-container,
.registration-container {
    padding-top: 60px;
    padding-bottom: 15vh;
}
.logo {
    max-width: 304px;
    margin-right: 10px;
}
.login {
    width: 320px;
    max-width: 320px;
    min-height: 128px;
    overflow: hidden;
    margin: 0 auto;
}
@media (max-height: 480px) {
    .login-container,
    .registration-container {
        padding-bottom: 0;
    }
}
</style>

<template>
    <div class="position-relative bg-white p-3 my-3 shadow-sm">

        <OverlaySpinner 
            v-if="$loading()"
            :showStatusText="true" 
            :statusText="$loadingStatus()"
        />

        <h1>Admin</h1>

        <h2>{{ getRouteLabel($route) }}</h2>

        <Alerts />

        <router-view />

    </div>
</template>

<script>
import store from '../store/store.js';
import Alerts from '../components/Alerts.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'Admin',
    components: {
        Alerts,
        OverlaySpinner
    },
    mounted() {
        store.commit('setLoading', false);
    },
    methods: {
        getRouteLabel(route) {
            if (route.meta && route.meta.label) {
                return this.$i18n(route.meta.label);
            }
            return route.name;
        }
    }
}
</script>

<style scoped>

</style>
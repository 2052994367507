<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_DELETE_WORKSPACE_CONFIRM') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <Text id="TEXT_DELETE_WORKSPACE_CONFIRM" :replaces="{'[WORKSPACE_NAME]': activeWorkspace.name}"/>

    </div> <!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="deleteWorkspace()"  class="btn btn-danger">{{ $i18n('LABEL_CONFIRM_DELETE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import Text from '../components/Text.vue';

export default {
    name: 'WorkspaceDeleteConfirm',
    components: {
        Text
    },
    computed: {
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        cancel() {
            store.commit('setModal', null);
        },
        /**
         * 
         */
        deleteWorkspace() {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting workspace');

            axios({
                method: 'delete',
                url: '/api/workspace/' + this.activeWorkspace.id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    // Remove active workspace id from localStorage
                    localStorage.removeItem('kmdb_activeWorkspaceId');
                    // Set default workspace active by resetting user to store 
                    store.commit('setUser', store.state.user);
                    // Redirect to dashboard
                    this.$router.push('/dashboard');
                    // Reload workspaces
                    this.$emitter.emit('getWorkspaces');
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);  
            });
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title">{{ $i18n('LABEL_EDIT_MEMBER') }} <em>{{ member.firstName + ' ' + member.lastName }}</em></h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div id="modalBody" class="modal-body">
            
        <form 
            v-if="member.id != $user().id"
            id="editMemberForm"
            action="api/workspace-user" 
            method="post" 
            @submit="editMember($event)" 
            enctype="multipart/form-data" 
            autocomplete="off"
            novalidate>

            <div class="row">

                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="userLevel" class="form-label">{{ $i18n('LABEL_USER_LEVEL') }}</label>
                        <select @change="setUserLevel($event)" id="userLevel" name="userLevel" class="form-select" required>
                            <option v-for="level in workspaceUserLevels" :key="level.name" :value="level.level" :selected="level.level==member.level">
                                {{ level.name }}
                            </option>
                        </select>
                    </div>
                </div>

            </div> <!-- /.row -->
            
            <div v-if="showTransferOwnershipBtn">
                <button v-if="! showTransferOwnershipConfirm" type="button" @click="toggleShowTransferOwnershipConfirm()" class="btn btn-primary">
                    {{ $i18n('LABEL_TRANSFER_WORKSPACE_OWNERSHIP') }}
                </button>

                <div v-if="showTransferOwnershipConfirm">
                    <Text :id="'TEXT_TRANSFER_WORKSPACE_OWNERSHIP'" class="my-3" />

                    <DeleteConfirmButton 
                        :showIcon="false"
                        :label="$i18n('LABEL_TRANSFER_WORKSPACE_OWNERSHIP')"
                        :confirmDeleteLabel="$i18n('LABEL_CONFIRM_OWNERSHIP_TRANSFER')"
                        btnAddClasses=""
                        deleteEventName="transferOwnership" 
                        :deleteEventPayload="member.id"  
                    />
                </div>
            </div>

            <input type="hidden" name="userId" :value="member.id" />
            <input type="hidden" name="workspaceId" :value="activeWorkspace.id" />
            <input type="hidden" name="userLevelName" :value="userLevelName" />

            <button type="submit" id="saveMemberBtn" class="visually-hidden">
                {{ $i18n('LABEL_SAVE') }}
            </button>

        </form>

    </div> <!-- /.modal-body -->
    
    <div class="modal-footer">
        <DeleteConfirmButton 
            v-if="member.id!=$user().id || member.id==activeWorkspace.userId"
            :showIcon="false"
            :label="$i18n('LABEL_REMOVE_MEMBER')"
            btnAddClasses=""
            deleteEventName="deleteMember" 
            :deleteEventPayload="member.id" 
            :disabled="deleteMemberBtnDisabled(member)" 
        />

        <DeleteConfirmButton 
            v-if="member.id==$user().id && member.id!=activeWorkspace.userId"
            :showIcon="false"
            :label="$i18n('LABEL_REMOVE_YOURSELF_FROM_WORKSPACE')"
            btnAddClasses=""
            :confirmDeleteLabel="$i18n('LABEL_CONFIRM_DELETE')"
            deleteEventName="deleteSelf" 
            :deleteEventPayload="member.id"  
        />

        <button type="button" @click="cancel()" class="btn btn-secondary ms-auto me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="submit()" :disabled="!userLevelName" class="btn btn-primary">{{ $i18n('LABEL_SAVE') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import Text from '../components/Text.vue';

export default {
    name: 'WorkspaceEditMember',
    components: {
        DeleteConfirmButton,
        Text
    },
    props: {
        initMember: Object,
        workspaceUserLevels: Array
    },
    data() {
        return {
            member: this.initMember,
            userLevel: this.initMember.level,
            userLevelName: null,
            deleteSelf: false,
            showTransferOwnershipConfirm: false
        }
    },
    computed: {
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        showTransferOwnershipBtn() {
            let ownedWorkspaces = store.state.user.workspaces.filter(workspace => workspace.userId == store.state.user.id);
            return this.activeWorkspace.userId == store.state.user.id && this.initMember.level == 1000 && this.userLevel == 1000 && ownedWorkspaces.length > 1;
        }
    },
    created() {
        // Event listener for deleteMember
        this.$emitter.on('deleteMember', (id) => {
            this.deleteMember(id);
        });

        // Event listener for transferOwnership
        this.$emitter.on('transferOwnership', (id) => {
            this.transferOwnership(id);
        });

        // Event listener for deleteSelf
        this.$emitter.on('deleteSelf', (id) => {
            // Set deleteSelf to change workspace, reload workspaces etc. after deleting
            this.deleteSelf = true;
            this.deleteMember(id);
        });
    },
    mounted() {
        this.userLevelName = null;
        this.deleteSelf = false;
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('deleteMember');
        this.$emitter.all.delete('transferOwnership');
        this.$emitter.all.delete('deleteSelf');
    },
    methods: {
        cancel() {
            store.commit('setModal', null);
        },
        submit() {
            //document.getElementById('saveMemberBtn').requestSubmit();
            document.getElementById('saveMemberBtn').click();
        },
        setUserLevel(e) {
            let sel = e.target;
            this.userLevel = sel.options[sel.selectedIndex].value;
            this.userLevelName = sel.options[sel.selectedIndex].text;
        },
        deleteMemberBtnDisabled(member) {
            let disabled = false;

            if (member.id == this.activeWorkspace.userId) {
                // Can't delete owner
                disabled = true;
            } else if (this.activeWorkspace.userLevel < 1000 && member.id != store.state.user.id) {
                // Only workspace admins can delete members other than themselves
                disabled = true;
            }
            return disabled;
        },
        toggleShowTransferOwnershipConfirm() {
            this.showTransferOwnershipConfirm = !this.showTransferOwnershipConfirm;
        },
        /**
         * @param   {Object}    e   Native MouseEvent object. 
         */
        editMember(e) {
            e.preventDefault();
            e.stopPropagation();

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Saving');

            let formData = new FormData(document.getElementById('editMemberForm'));
            this.postWorkspaceUser(formData);
        },
        /**
         * Removes a member from the workspace.
         * @param   {number}    id
         */
        deleteMember(id) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Removing member');

            let formData = new FormData();
            formData.append('userId', id);
            formData.append('workspaceId', this.activeWorkspace.id);
            formData.append('remove', true);
            this.postWorkspaceUser(formData);
        },
        /**
         * Transfers workspace ownership to another member.
         * @param   {number}    id
         */
        transferOwnership(id) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Transferring workspace ownership');

            let formData = new FormData();
            formData.append('userId', id);
            formData.append('workspaceId', this.activeWorkspace.id);
            formData.append('transferOwnership', true);
            this.postWorkspaceUser(formData);
        },
        /**
         * Post workspace user data to API.
         */
        postWorkspaceUser(formData) {
            axios({
                method: 'post',
                url: '/api/workspace-user',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    if (formData.get('transferOwnership')) {
                        console.log('transferring ownership');
                        console.log(response.data);
                        // Remove active workspace id from localStorage
                        localStorage.removeItem('kmdb_activeWorkspaceId');
                        // Reload workspaces and workspace users
                        this.$emitter.emit('getWorkspaces');
                        this.$emitter.emit('getWorkspaceUsers');
                        // Set default workspace active by resetting user to store 
                        store.commit('setUser', store.state.user);
                    }
                    else if (this.deleteSelf) {
                        // Removed self from workspace
                        store.commit('addModalMessage', this.$i18n('TEXT_USER_REMOVED_SELF_FROM_WORKSPACE'));
                        // Remove active workspace id from localStorage
                        localStorage.removeItem('kmdb_activeWorkspaceId');
                        // Reload workspaces
                        this.$emitter.emit('getWorkspaces');
                        // Set default workspace active by resetting user to store 
                        store.commit('setUser', store.state.user);
                        // Redirect to dashboard
                        this.$router.push('/dashboard');
                        // Reload all workspace related stuff
                        this.$emitter.emit('getWorkspaces');
                        this.$emitter.emit('getSubscriptions');
                        this.$emitter.emit('getBillingAddresses');
                        this.$emitter.emit('getCustomCategories');
                    } else {
                        this.$emitter.emit('getWorkspaceUsers');
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                store.commit('setModal', null);
            });
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <div v-if="! $loading() && ! maps && ! isAdminView" class="alert alert-info">
        {{ $i18n('TEXT_NO_MAPS_YET') }}
    </div>
    
    <div v-if="maps && maps.length >= config.freeMapsLimit && ! $hasBasicSubscription()" v-html="$i18n('HTML_MAPS_LIMIT_REACHED')" class="alert alert-danger"></div>
    <form 
        v-else-if="! isAdminView"
        action="api/map" 
        method="post" 
        class="row needs-validation" 
        :class="{'was-validated': wasValidated}"
        @submit="postCreateMap($event)" 
        autocomplete="off"
        novalidate>

        <div class="col-lg-6 my-3">
            <label for="newMapName" class="form-label">{{ $i18n('LABEL_CREATE_NEW_MAP') }}</label>
            <div class="input-group">
                <input v-model="newMapName" type="text" id="newMapName" name="newMapName" class="form-control" :placeholder="$i18n('LABEL_NEW_MAP_NAME')" required />
                <button type="submit" class="btn btn-primary" :disabled="createMapBtnDisabled">{{ $i18n('LABEL_CREATE') }}</button>
            </div>
        </div>

    </form>

    <div v-if="isAdminView" class="mb-3">
        <label for="mapsFilter" class="form-label visually-hidden">Filter</label>
        <input v-model="filterStr" type="text" id="mapsFilter" class="form-control" :placeholder="$i18n('LABEL_FILTER_LIST_BY_TYPING')" />
    </div>

    <table v-if="maps && maps.length" class="table table-hover align-middle my-4">
        <thead class="bg-dark text-white">
            <tr>
                <th scope="col">
                    {{ $i18n('LABEL_MAP_NAME') }}
                </th>
                <th v-if="isAdminView" scope="col">
                    {{ $i18n('LABEL_USER') }}
                </th>
                <th v-if="isAdminView" scope="col">
                    {{ $i18n('LABEL_EMAIL') }}
                </th>
                <th scope="col">
                    <span class="visually-hidden">{{ $i18n('LABEL_DELETE') }}</span>
                </th>
                <th scope="col">
                    <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr 
                v-for="map in filteredMaps"
                :key="map.id"
                :class="{'edit-lock':getMapEditing(map.id)}"
            >
                <td class="expand">
                    {{ map.name }}
                    <div v-if="getMapEditing(map.id)" class="d-flex align-items-center small text-muted">
                        <BIconLockFill /> {{ getMapEditing(map.id).userName }} {{ $i18n('LABEL_IS_EDITING') }} 
                        <!-- button type="button" class="btn btn-primary btn-sm ms-2">
                            {{ $i18n('LABEL_TAKE_OVER') }}
                        </button -->
                    </div>
                </td>
                <td v-if="isAdminView" class="expand text-nowrap">
                    {{ map.user.firstName + ' ' + map.user.lastName }}
                </td>
                <td v-if="isAdminView" class="expand text-nowrap">
                    {{ map.user.email }}
                </td>
                <td class="text-end shrink">
                    <DeleteConfirmButton v-if="!getMapEditing(map.id)" deleteEventName ="deleteMap" :deleteEventPayload="map.mapKey" />
                </td>
                <td class="text-end shrink">
                    <router-link v-if="!getMapEditing(map.id)" :to="'/maps/edit/'+map.mapKey" class="btn btn-primary btn-sm">
                        <BIconPencilFill />
                        <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                    </router-link>
                </td>
            </tr>
        </tbody>
    </table>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconLockFill, BIconPencilFill } from 'bootstrap-icons-vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';

export default {
    name: 'MapsList',
    components: {
        BIconLockFill,
        BIconPencilFill,
        DeleteConfirmButton
    },
    data() {
        return {
            newMapName: '',
            wasValidated: false,
            maps: null,
            confirmMapDelete: null,
            filterStr: null
        }
    },
    computed: {
        config() {
            return store.state.config;
        },
        createMapBtnDisabled() {
            return ! this.newMapName;
        },
        isAdminView() {
            return this.$route.path.search('/admin/maps') !== -1;
        },
        filteredMaps() {
            if (this.isAdminView && this.filterStr) {
                return this.maps.filter((item) => {
                    let userName = item.user.firstName + ' ' + item.user.lastName;
                    if (item.name.search(this.filterStr) !== -1) {
                        return true;
                    } else if (userName.search(this.filterStr) !== -1) {
                        return true;
                    } else if (item.user.email.search(this.filterStr) !== -1) {
                        return true;
                    }
                });
            }
            return this.maps;
        },
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        mapEditings() {
            if (store.state.editings) {
                return store.state.editings.filter(item => item.mapId != null);
            }
            return null;
        }
    },
    watch: {
        activeWorkspaceId() {
            this.getMaps();
        }
    },
    created() {
        this.$emitter.on('deleteMap', (id) => {
            this.deleteMap(id);
        });
    },
    mounted() {
        store.commit('setLoading', true);
        this.getMaps();
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('deleteMap');
    },
    methods: {
        /**
         * Gets all active workspace's maps from the db and sets them to this.maps.
         * Gets all maps if in admin mode.
         */
        getMaps() {
            let params = {
                workspaceId: this.activeWorkspaceId
            };

            if (this.isAdminView) {
                params.admin = true;
            }

            axios({
                method: 'get',
                url: '/api/map',
                params: params
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setMessages', response.data.messages);
                    }
                    if (response.data.maps.length) {
                        this.maps = response.data.maps;
                    } else {
                        this.maps = null;
                    }
                } 
                else if (response.data.errors) 
                {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        getMapEditing(mapId) {
            if (this.mapEditings) {
                let editing = this.mapEditings.find(item => item.mapId == mapId);
                if (editing) {
                    return editing;
                }
            }
            return null;
        },
        /**
         * Validates and posts create new map.
         */
        postCreateMap(e) {
            e.preventDefault();
            e.stopPropagation();
            this.wasValidated = true;

            if (! e.target.checkValidity()) {
                return;
            } 

            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Creating new map');
            store.commit('clearErrors');
            store.commit('clearMessages');
            
            let formData = new FormData();

            formData.append('name', this.newMapName);
            formData.append('workspaceId', store.state.user.activeWorkspaceId);

            axios({
                method: 'post',
                url: '/api/map',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        store.commit('setMessages', response.data.messages);
                    }
                    this.$router.push('/maps/edit/' + response.data.map.mapKey);
                } else if (response.data.errors) {
                    store.commit('setErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                this.wasValidated = false;
            });
        },
        /**
         * Deletes a map from the DB permanently.
         */
        deleteMap(mapKey) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting map');

            axios({
                method: 'delete',
                url: '/api/map/' + mapKey,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.confirmMapDelete = null;
                    this.getMaps();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>
.edit-lock > td div > svg {
    position: relative;
    top: -2px;
}
</style>
<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_END_SUBSCRIPTION_CONFIRM') }}</h5>
        <button type="button" @click="cancel()" class="btn-close" :aria-label="$i18n('LABEL_CLOSE')"></button>
    </div>

    <div class="modal-body">

        <Text id="TEXT_END_SUBSCRIPTION_CONFIRM" />

    </div> <!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button" @click="cancel()" class="btn btn-secondary me-2">{{ $i18n('LABEL_CANCEL') }}</button>
        <button type="button" @click="endSubscription()"  class="btn btn-danger">{{ $i18n('LABEL_END_SUBSCRIPTION') }}</button>
    </div>

</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import Text from '../components/Text.vue';

export default {
    name: 'SubscriptionEndConfirm',
    components: {
        Text
    },
    props: {
        id: {
            type: Number
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        cancel() {
            store.commit('setModal', null);
        },
        /**
         * 
         */
        endSubscription() {
            store.commit('setLoading', true);

            let formData = new FormData();
            formData.append('id', this.id);

            axios({
                method: 'post',
                url: '/api/subscription-end',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData
            })
            .then((response) => {
                //console.log(response);
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
                store.commit('setModal', null);
                this.$emitter.emit('getSubscriptions');
            });
        }
    }
}
</script>

<style scoped>

</style>

<template>

    <!-- PRODUCT CARD -->
    <div v-if="this.card" class="card h-100 shadow-sm">
        <img v-if="product.image" :src="productImage" class="card-img-top" :alt="productName" />
        <div class="card-body d-flex flex-column">
            <h5 class="card-title">{{ productName }}</h5>
            <p v-if="productDescription" class="card-text lh-sm mb-2">
                <small>{{ productDescription }}</small>
            </p>
            <p v-if="product.price" class="mt-auto mb-2">
                <strong>{{ calculatePrice(product.price) }} &euro;</strong>
            </p>
            <p v-if="product.pricePerMonth || product.pricePerYear" class="mt-auto mb-2">
                <strong v-if="product.pricePerMonth">{{ calculatePrice(product.pricePerMonth) }} &euro;</strong>/{{ $i18n('LABEL_PER_MONTH') }}
                <br v-if="product.pricePerMonth && product.pricePerYear" />
                <strong v-if="product.pricePerYear">{{ calculatePrice(product.pricePerYear) }} &euro;</strong>/{{ $i18n('LABEL_PER_YEAR') }}
            </p>
            
            <div v-if="!hasProduct(product.id)">
                <!-- Domain count for basic subscription only -->
                <NumericStepper v-if="product.id==1" :modelValue="domainCount" :changeEventName="'setDomainCount'" />
                
                <select 
                    v-if="product.pricePerMonth && product.pricePerYear && canBePurchased && isWorkspaceAdmin" 
                    v-model="subscription"
                    class="form-select form-select-sm mb-2">
                    <label for="subscription" class="visually-hidden">{{ $i18n('LABEL_SELECT_SUBSCRIPTION') }}</label>
                    <option :value="null">{{ $i18n('LABEL_SELECT_SUBSCRIPTION') }}</option>
                    <option value="month">{{ calculatePrice(product.pricePerMonth) }} &euro;/{{ $i18n('LABEL_PER_MONTH') }} (+ {{ $i18n('LABEL_VAT') }})</option>
                    <option value="year">{{ calculatePrice(product.pricePerYear) }} &euro;/{{ $i18n('LABEL_PER_YEAR') }} (+ {{ $i18n('LABEL_VAT') }})</option>
                </select>
                <select 
                    v-if="product.basicSubscriptionRequired && canBePurchased && isWorkspaceAdmin && basicSubscriptions.length>1" 
                    v-model="parentSubscriptionId"
                    class="form-select form-select-sm mb-2">
                    <label for="subscription" class="visually-hidden">{{ $i18n('LABEL_SELECT_BASIC_SUBSCRIPTION') }}</label>
                    <option :value="null">{{ $i18n('LABEL_SELECT_BASIC_SUBSCRIPTION') }}</option>
                    <option 
                        v-for="basicSubscription in basicSubscriptions"
                        :key="'basic-subscription-'+basicSubscription.id"
                        :value="basicSubscription.id"
                    >
                        {{ $i18n('LABEL_PRODUCT_1') + ' (' + getBillingAddressName(basicSubscription.billingAddressId) + ')'}}
                    </option>
                </select>
                <p v-if="! canBePurchased" class="lh-sm text-muted">
                    <small>{{ $i18n('TEXT_PRODUCT_REQUIRES_BASIC_SUBSCRIPTION') }}</small>
                </p>
            </div>
            <p v-if="hasProduct(product.id)" class="lh-sm text-muted">
                <small>{{ $i18n('TEXT_PRODUCT_ALREADY_PURCHASED') }}</small>
            </p>

            <router-link 
                :to="productLink" 
                class="btn btn-primary mb-1">
                <BIconInfoCircle /> {{ $i18n('LABEL_MORE_INFO') }}
            </router-link>
            <button 
                @click="addToCart()" 
                :disabled="addToCartDisabled"
                class="btn btn-primary">
                <BIconCartPlus /> {{ $i18n('LABEL_ADD_TO_CART') }}
            </button>
        </div>
    </div>

    <!-- PRODUCT FULL INFO -->
    <div v-if="! this.card">

        <h2>{{ productName }}</h2>

        <p v-if="productDescription" class="lead">
            {{ productDescription }}
        </p>

        <Text :id="productTextId" />

        <img v-if="productImage" :src="productImage" class="product-img mb-3" :alt="productName" />

        <p v-if="product.price" class="mt-auto mb-2">
            <strong>{{ calculatePrice(product.price) }} &euro;</strong>
        </p>
        <p v-if="product.pricePerMonth || product.pricePerYear" class="mt-auto mb-2">
            <strong v-if="product.pricePerMonth">{{ calculatePrice(product.pricePerMonth) }} &euro;</strong>/{{ $i18n('LABEL_PER_MONTH') }} <small>(+ {{ $i18n('LABEL_VAT') }})</small>
            <br v-if="product.pricePerMonth && product.pricePerYear" />
            <strong v-if="product.pricePerYear">{{ calculatePrice(product.pricePerYear) }} &euro;</strong>/{{ $i18n('LABEL_PER_YEAR') }} <small>(+ {{ $i18n('LABEL_VAT') }})</small>
        </p>
        
        <div v-if="!hasProduct(product.id)">
            <!-- Domain count for basic subscription only -->
            <div class="product-info-domain-count">
                <NumericStepper v-if="product.id==1" :modelValue="domainCount" :changeEventName="'setDomainCount'" />
            </div>

            <div v-if="canBePurchased" class="my-3">
                <label for="subscription" class="visually-hidden">{{ $i18n('LABEL_SELECT_SUBSCRIPTION') }}</label>
                <select 
                    v-if="product.pricePerMonth && product.pricePerYear && isWorkspaceAdmin" 
                    v-model="subscription"
                    id="subscription"
                    class="form-select form-select form-select-subscription mb-2">
                    <option :value="null">{{ $i18n('LABEL_SELECT_SUBSCRIPTION') }}</option>
                    <option value="month">{{ calculatePrice(product.pricePerMonth) }} &euro;/{{ $i18n('LABEL_PER_MONTH') }} (+ {{ $i18n('LABEL_VAT') }})</option>
                    <option value="year">{{ calculatePrice(product.pricePerYear) }} &euro;/{{ $i18n('LABEL_PER_YEAR') }} (+ {{ $i18n('LABEL_VAT') }})</option>
                </select>
            </div>
            <div v-if="product.basicSubscriptionRequired && canBePurchased && isWorkspaceAdmin && basicSubscriptions.length>1" class="my-3">
                <select 
                    v-model="parentSubscriptionId"
                    class="form-select form-select-subscription mb-2">
                    <label for="subscription" class="visually-hidden">{{ $i18n('LABEL_SELECT_BASIC_SUBSCRIPTION') }}</label>
                    <option :value="null">{{ $i18n('LABEL_SELECT_BASIC_SUBSCRIPTION') }}</option>
                    <option 
                        v-for="basicSubscription in basicSubscriptions"
                        :key="'basic-subscription-'+basicSubscription.id"
                        :value="basicSubscription.id"
                    >
                        {{ $i18n('LABEL_PRODUCT_1') + ' (' + getBillingAddressName(basicSubscription.billingAddressId) + ')'}}
                    </option>
                </select>
            </div>
            <div v-if="! canBePurchased" class="alert alert-warning">
                {{ $i18n('TEXT_PRODUCT_REQUIRES_BASIC_SUBSCRIPTION') }}
            </div>
        </div>
        
        <p v-if="hasProduct(product.id)" class="lh-sm text-muted">
            <small>{{ $i18n('TEXT_PRODUCT_ALREADY_PURCHASED') }}</small>
        </p>

        <button 
            @click="addToCart()" 
            :disabled="addToCartDisabled"
            class="btn btn-primary">
            <BIconCartPlus /> {{ $i18n('LABEL_ADD_TO_CART') }}
        </button>

    </div>

</template>

<script>
import store from '../store/store.js';
import { BIconCartPlus, BIconInfoCircle } from 'bootstrap-icons-vue';
import NumericStepper from '../components/NumericStepper.vue';
import Text from '../components/Text.vue';

export default {
    name: 'ShopProductInfo',
    components: {
        BIconCartPlus, 
        BIconInfoCircle,
        NumericStepper,
        Text
    },
    props: {
        product: {
            type: Object
        },
        card: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            domainCount: 1,
            subscription: null,
            parentSubscriptionId: null
        }
    },
    computed: {
        productImage() {
            return require('../assets/images/' + this.product.image);
        },
        productLink() {
            return '/shop/' + this.product.id + '/' + this.product.name;
        },
        productName() {
            return this.$i18n('LABEL_PRODUCT_' + this.product.id);
        },
        productDescription() {
            if (this.$i18nExists('TEXT_PRODUCT_' + this.product.id)) {
                return this.$i18n('TEXT_PRODUCT_' + this.product.id);
            }
            return null;
        },
        productTextId() {
            return 'HTML_PRODUCT_' + this.product.id;
        },
        subscriptionPrice() {
            if (this.subscription == 'month') {
                return this.calculatePrice(this.product.pricePerMonth);
            } else if (this.subscription == 'year') {
                return this.calculatePrice(this.product.pricePerYear);
            } else if (this.product.price) {
                return this.calculatePrice(this.product.price);
            }
            return null;
        },
        subscriptions() {
            return store.state.subscriptions;
        },
        basicSubscriptions() {
            if (store.state.subscriptions) {
                return store.state.subscriptions.filter(item => item.productId == 1 && item.ended == null);
            }
            return null;
        },
        canBePurchased() {
            // This product needs basic subscription and user doesn't have it
            if (this.product.basicSubscriptionRequired && ! this.$hasBasicSubscription()) {
                return false;
            }
            // Product doesn't require basic subscription
            return true;
        },
        addToCartDisabled() {
            // Disable add to cart if user doesn't have admin rights to workspace
            if (! this.isWorkspaceAdmin) {
                return true;
            }
            // Disable add to cart if this product needs basic subscription and user doesn't have it
            if (! this.canBePurchased) {
                return true;
            }
            // Disable add to cart if product has selectable subscription and it's not selected
            if ((this.product.pricePerMonth || this.product.pricePerYear) && ! this.subscription) {
                return true;
            }
            // Disable add to cart if product needs basic subscription and it's not selected
            if (this.product.basicSubscriptionRequired && ! this.parentSubscriptionId) {
                return true;
            }
            // Product has no subscription options and no basic subscription required, enable button always
            return false;
        },
        activeWorkspace() {
            let activeWorkspace = store.state.user.workspaces.find(workspace => workspace.id == store.state.user.activeWorkspaceId);
            if (activeWorkspace) {
                return activeWorkspace;
            }
            return null;
        },
        isWorkspaceAdmin() {
            // Check if user is the owner of this workspace
            if (this.activeWorkspace.userId == store.state.user.id) {
                return true;
            }
            // Check if user has admin rights for this workspace
            if (this.activeWorkspace.userLevel >= 1000) {
                return true;
            }
            return false;
        }
    },
    created() {
        // Listener for setDomainCount event emitted from NumericStepper 
        this.$emitter.on('setDomainCount', (change) => {
            this.domainCount += change;
        });
    },
    mounted() {
        // Set parent subscription id if this product nees basic subscription and user has only one
        if (this.product.basicSubscriptionRequired && this.basicSubscriptions.length==1) {
            this.parentSubscriptionId = this.basicSubscriptions[0].id;
        }
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('setDomainCount');
    },
    methods: {
        calculatePrice(price) {
            return this.$calculatePrice(price, this.domainCount);
        },
        getBillingAddress(id) {
            if (store.state.billingAddresses) {
                return store.state.billingAddresses.find(item => item.id == id);
            }
            return null;
        },
        getBillingAddressName(id) {
            let billingAddress = this.getBillingAddress(id);
            if (billingAddress) {
                if (billingAddress.firstName && billingAddress.lastName) {
                    return billingAddress.firstName + ' ' + billingAddress.lastName;
                } else if (billingAddress.company) {
                    return billingAddress.company;
                }
            }
            return null;
        },
        hasProduct(productId) {
            if (store.state.subscriptions) {
                return store.state.subscriptions.find(item => item.productId == productId && item.ended == null);
            }
            return false;
        },
        /**
         * Adds this product to shopping cart.
         */
        addToCart() {
            // Check if the product is already in the cart
            if (store.state.cart.find(item => item.productId == this.product.id)) {
                // Already in cart, display message and abort
                store.commit('addModalMessage', this.$i18n('TEXT_PRODUCT_ALREADY_IN_CART'));
                return;
            }

            let productName = this.productName;

            if (this.domainCount > 1) {
                productName += ' (+ ' + (this.domainCount-1) + ' ' + this.$i18n('LABEL_ADDITONAL_DOMAINS') + ')';
            }

            // Add to cart
            store.commit('addToCart', {
                productId: this.product.id,
                productName: productName,
                parentId: this.parentSubscriptionId,
                subscription: this.subscription,
                price: this.subscriptionPrice,
                domainCount: this.domainCount
            });
            // Show toast message
            this.$addToastMessage(this.$i18n('TEXT_PRODUCT_ADDED_TO_CART'));
            // Reset product card
            this.domainCount = 1;
            this.subscription = null;
            this.parentSubscriptionId = null;
            // Open cart
            this.$emitter.emit('openCart');
        }
    }
}
</script>

<style scoped>
.form-select-subscription,
.product-info-domain-count {
    max-width: 320px;
}
@media (min-width: 576px) {
    .product-img {
        max-width: 320px;
    }
}
</style>
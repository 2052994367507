<template>

    <div class="modal-header bg-light">
        <h5 class="modal-title" id="modalTitle">{{ $i18n('LABEL_BILLING_INFO') }}</h5>
    </div>

    <div class="modal-body">

        <BillingInfoForm :modal="true" />

    </div> <!-- /.modal-body -->

</template>

<script>
import BillingInfoForm from '../components/BillingInfoForm.vue';

export default {
    name: 'BillingInfoFormModal',
    components: {
        BillingInfoForm
    },
    props: {
        id: Number,
        userId: Number
    },
    data() {
        return {
            
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>

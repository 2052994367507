<template>

    <div class="row">

        <div class="col-lg-6">
            <div class="mb-3">
                <label for="name" class="form-label">{{ $i18n('LABEL_MAP_NAME') }}</label>
                <input v-model="mapData.name" type="text" id="name" class="form-control" required />
                <div class="invalid-feedback">
                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="row mb-3">
                <div class="col-lg-8">
                    <label class="form-label">{{ $i18n('LABEL_LOCATION') }}</label>
                    <div class="input-group">
                        <input v-model="mapData.config.initLocation.lat" type="text" id="lat" name="lat" class="form-control" required disabled />
                        <input v-model="mapData.config.initLocation.lng" type="text" id="lng" name="lng" class="form-control" required disabled />
                    </div>
                </div>

                <div class="col-lg-4">
                    <label for="initZoom" class="form-label">{{ $i18n('LABEL_ZOOM') }}</label>
                    <input v-model="mapData.config.initZoom" type="number" id="initZoom" name="initZoom" min="1" max="20" class="form-control" required disabled />
                    <div class="invalid-feedback">
                        {{ $i18n('LABEL_FIELD_REQUIRED') }} (value from 1 to 20)
                    </div>
                </div>
            </div> <!-- /.row -->
            
            <div class="mb-2">
                <button @click="useCurrentLocationAndZoom()" type="button" class="btn btn-primary" :disabled="useCurrentBtnDisabled">
                    {{ $i18n('LABEL_USE_CURRENT_LOCATION_AND_ZOOM') }}
                </button>
            </div>

            <!-- div class="mb-2">
                <button @click="openMapEditBounds()" type="button" class="btn btn-primary">
                    {{ $i18n('LABEL_SET_MAP_BOUNDS') }}
                </button>
            </div -->
        </div>

    </div> <!-- /.row -->
    

    <div class="row">

        <!-- div class="col-lg-6">
            <div class="mb-3">
                <label class="form-label" for="status">{{ $i18n('LABEL_PUBLISH') }}</label>
                <div v-if="$hasBasicSubscription()">
                    <button @click="openMapEmbedCode()" type="button" class="btn btn-primary">
                        {{ $i18n('LABEL_PUBLISH_MAP') }}
                    </button>
                </div>
                <div v-if="! $hasBasicSubscription()" class="form-text">
                    {{ $i18n('TEXT_MAP_PUBLISH_NO_SUBSCIPTION') }}
                </div>
            </div>
        </div -->

        <div class="col-lg-6">
            <div class="mb-3">
                <label class="form-label">{{ $i18n('LABEL_PUBLISH') }}</label>
                <div>
                    <button @click="openMapEmbedCode()" type="button" class="btn btn-primary">
                        {{ $i18n('LABEL_PUBLISH_MAP') }}
                    </button>
                </div>
            </div>
        </div>

    </div> <!-- /.row -->

</template>

<script>

export default {
    name: 'MapEditBasicInfo',
    components: {
        
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        },
        useCurrentBtnDisabled: {
            type: Boolean
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        mapData() {
            return this.modelValue;
        }
    },
    methods: {
        useCurrentLocationAndZoom() {
            this.$emitter.emit('useCurrentLocationAndZoom');
        },
        openMapEmbedCode() {
            this.$emitter.emit('openMapEmbedCode');
        },
        openMapEditBounds() {
            this.$emitter.emit('openMapEditBounds');
        }
    }
}
</script>

<style scoped>

</style>

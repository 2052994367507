import { createWebHistory, createRouter } from "vue-router";
import Admin from "@/views/Admin.vue";
import AdminSubscriptions from "@/components/AdminSubscriptions.vue";
import AdminTexts from "@/components/AdminTexts.vue";
import AdminUsers from "@/components/AdminUsers.vue";
import CustomCategories from "@/views/CustomCategories.vue";
import Dashboard from "@/views/Dashboard.vue";
import Help from "@/views/Help.vue";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import Maps from "@/views/Maps.vue";
import MyAccount from "@/views/MyAccount.vue";
import MapEdit from "@/views/MapEdit.vue";
import MapsList from "@/views/MapsList.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Places from "@/views/Places.vue";
import Routes from "@/views/Routes.vue";
import Sites from "@/views/Sites.vue";
import Shop from "@/views/Shop.vue";
import ShopCart from "@/components/ShopCart.vue";
import ShopCheckout from "@/components/ShopCheckout.vue";
import ShopProduct from "@/components/ShopProduct.vue";
import Workspace from "@/views/Workspace.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: Login
    },
    {
        path: '/sign-up',
        name: 'Registration',
        component: Login
    },
    {
        path: '/activate-account/:token',
        name: 'RegistrationActivation',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/maps',
        name: 'Maps',
        component: Maps,
        children: [
            {
                path: '/maps/maps-list',
                alias: '/maps',
                name: 'Maps',
                component: MapsList,
                meta: { 
                    title: 'LABEL_MAPS' 
                }
            },
            {
                path: '/maps/edit/:mapKey',
                name: 'Edit map',
                component: MapEdit,
                meta: { 
                    title: 'LABEL_EDIT_MAP' 
                }
            }
        ]
    },
    {
        path: '/places',
        name: 'Places',
        component: Places,
        meta: { 
            title: 'LABEL_PLACES' 
        }
    },
    {
        path: '/routes',
        name: 'Routes',
        component: Routes,
        meta: { 
            title: 'LABEL_ROUTES' 
        }
    },
    {
        path: '/categories',
        name: 'Custom categories',
        component: CustomCategories,
        meta: { 
            title: 'LABEL_CUSTOM_CATEGORIES' 
        }
    },
    {
        path: '/sites',
        name: 'Sites',
        component: Sites,
        meta: { 
            title: 'LABEL_LINKED_SITES' 
        }
    },
    {
        path: '/shop',
        name: 'Shop',
        component: Shop,
        meta: { 
            title: 'LABEL_SHOP' 
        },
        children: [
            {
                path: '/shop/cart',
                name: 'ShopCart',
                component: ShopCart
            },
            {
                path: '/shop/:productId/:productSlug',
                name: 'ShopProduct',
                component: ShopProduct
            },
            {
                path: '/shop/checkout',
                name: 'ShopCheckout',
                component: ShopCheckout
            }
        ]
    },
    {
        path: '/my-account/',
        name: 'My account main',
        redirect: '/my-account/account-info',
        component: MyAccount,
        meta: { 
            title: 'LABEL_MY_ACCOUNT' 
        }
    },
    {
        path: '/my-account/:tab/:id?',
        name: 'My account',
        component: MyAccount,
        meta: { 
            title: 'LABEL_MY_ACCOUNT' 
        }
    },
    {
        path: '/workspace/',
        name: 'Workspace main',
        redirect: '/my-account/account-info',
        component: Workspace,
        meta: { 
            title: 'LABEL_WORKSPACE_SETTINGS' 
        }
    },
    {
        path: '/workspace/:tab/:id?',
        name: 'Workspace',
        component: Workspace,
        meta: { 
            title: 'LABEL_WORKSPACE_SETTINGS' 
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: { 
            title: 'LABEL_ADMIN',
            level: 1000 
        },
        children: [
            {
                path: '/admin/texts',
                name: 'Texts',
                component: AdminTexts,
                meta: { 
                    title: 'LABEL_ADMIN',
                    label: 'LABEL_TEXTS',
                    level: 1000 
                }
            },
            {
                path: '/admin/users',
                name: 'Users',
                component: AdminUsers,
                meta: { 
                    title: 'LABEL_ADMIN',
                    label: 'LABEL_USERS',
                    level: 1000 
                }
            },
            {
                path: '/admin/subscriptions',
                name: 'Subscriptions',
                component: AdminSubscriptions,
                meta: { 
                    title: 'LABEL_ADMIN',
                    label: 'LABEL_SUBSCRIPTIONS',
                    level: 1000 
                }
            },
            {
                path: '/admin/maps',
                name: 'All maps',
                component: MapsList,
                meta: { 
                    title: 'LABEL_ADMIN',
                    label: 'LABEL_MAPS',
                    level: 1000 
                }
            }
        ]
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: { 
            title: 'LABEL_HELP_AND_SUPPORT' 
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/:catchAll(.*)', 
        name: 'Page not found',
        component: PageNotFound 
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
<template>

    <div v-if="errors" class="errors">
        <div 
            v-for="(errorMsg, index) in errors" 
            :key="'error'+index" 
            class="alert alert-danger">
            {{ errorMsg }}
        </div>
    </div>

    <div v-if="messages" class="messages">
        <div 
            v-for="(msg, index) in messages" 
            :key="'message'+index" 
            class="alert alert-info">
            {{ msg }}
        </div>
    </div>

</template>

<script>
import store from '../store/store.js';

export default {
    name: 'Alerts',
    computed: {
        errors() {
            return store.state.errors;
        },
        messages() {
            return store.state.messages;
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>

<template>

    <ShopProductInfo v-if="this.product" :product="this.product" />

</template>

<script>
import store from '../store/store.js';
import ShopProductInfo from '../components/ShopProductInfo.vue';

export default {
    name: 'ShopProduct',
    components: {
        ShopProductInfo
    },
    data() {
        return {
            product: null
        }
    },
    computed: {
        products() {
            return store.state.products;
        }
    },
    watch: {
        products() {
            this.product = this.products.find((item) => item.id == this.$route.params.productId);
        }
    },
    created() {
        if (this.products) {
            this.product = this.products.find((item) => item.id == this.$route.params.productId);
        }
    }
}
</script>

<style scoped>

</style>
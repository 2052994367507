<template>

    <div v-if="toasts.length" class="toast-container">

        <div 
            v-for="(toast) in toasts" 
            :key="'toast-' + toast.id" 
            :data-id="toast.id" 
            class="toast" 
            role="alert" aria-live="assertive" aria-atomic="true">
            <div v-if="toast.title" class="toast-header">
                <strong class="me-auto">{{ toast.title }}</strong>
                <small v-if="toast.time" class="text-muted">{{ toast.time }}</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" :aria-label="$i18n('LABEL_CLOSE')"></button>
            </div>
            <div class="toast-body d-flex align-items-center" :class="toastClass(toast.type)">
                <component v-if="toast.type" :is="toastIcon(toast.type)" class="me-1" />
                {{ toast.message }}
                <button v-if="!toast.title" type="button" class="btn-close ms-auto" data-bs-dismiss="toast" :aria-label="$i18n('LABEL_CLOSE')"></button>
            </div>
        </div>

    </div>

</template>

<script>
import store from '../store/store.js';
import { BIconBug, BIconExclamationTriangle, BIconInfoCircle } from 'bootstrap-icons-vue';

export default {
    name: 'Toasts',
    components: {
        BIconBug, 
        BIconExclamationTriangle, 
        BIconInfoCircle
    },
    data() {
        return {
            
        }
    },
    computed: {
        toasts() {
            return store.state.toasts;
        }
    },
    mounted() {
        // Just for testing
        /*store.commit('addToast', {
            title: 'Toast title',
            message: 'This is a test toast'
        });*/
        //this.$addToastMessage(['First message', 'Second message', 'Third message']);
        //this.$addToastAlert('This is some kind of a warning.');
        //this.$addToastError('Oh no, an error happened!');
    },
    updated() {
        // Get toast elements
        let toastElList =[].slice.call(document.querySelectorAll('.toast'));

        // Map them as Bootstrap Toast objects in toastList array
        let toastList = toastElList.map((toastEl) => {
            // Add hidden event hanlders
            toastEl.addEventListener('hidden.bs.toast', function() {
                store.commit('deleteToast', this.dataset.id);
            });

            let toastOptions = {
                animation: true,
                autohide: true,
                delay: 5000
            };

            return new this.$bootstrap.Toast(toastEl, toastOptions);
        });
        
        // Show all toasts
        for (let i in toastList) {
            toastList[i].show();
        }
    },
    methods: {
        toastClass(type) {
            if (type) {
                return 'alert-' + type;
            }
            return null;
        },
        toastIcon(type) {
            if (type == 'info') {
                return 'BIconInfoCircle';
            } else if (type == 'warning') {
                return 'BIconExclamationTriangle';
            } else if (type == 'danger') {
                return 'BIconBug';
            }
            return null;
        }
    }
}
</script>

<style scoped>
.toast-container {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: .25rem;
    z-index: 999999;
}
.toast-container > :not(:last-child) {
    margin-bottom: .5rem;
}
.toast {
    overflow: hidden;
}
</style>

<template>
    <div class="position-relative bg-white p-3 my-3 shadow-sm">
        
        <OverlaySpinner 
            v-if="$loading()"
            :showStatusText="true" 
            :statusText="$loadingStatus()"
        />

        <h1>{{ $i18n('LABEL_PLACES') }}</h1>

        <Alerts />

        <div v-if="!places.length && !$loading" class="alert alert-info">
            You don't have any places yet. Start creating a new place by clicking the button below.
        </div>

        <div v-if="places && places.length >= config.freePlacesLimit && ! $hasBasicSubscription()" v-html="$i18n('HTML_PLACES_LIMIT_REACHED')" class="alert alert-danger"></div>
        <button v-else type="button" @click="openAddNewPlace()" class="btn btn-primary mb-3">
            {{ $i18n('LABEL_ADD_NEW_PLACE') }}
        </button>

        <table v-if="places.length" class="table table-hover align-middle my-4">
            <thead class="bg-dark text-white">
                <tr>
                    <th scope="col">{{ $i18n('LABEL_TITLE') }}</th>
                    <th scope="col">{{ $i18n('LABEL_CATEGORIES') }}</th>
                    <th scope="col">{{ $i18n('LABEL_LANGUAGE') }}</th>
                    <th scope="col">{{ $i18n('LABEL_TRANSLATIONS') }}</th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_DELETE') }}</span>
                    </th>
                    <th scope="col">
                        <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="place in places"
                    :key="place.id">
                    <td>
                        {{ place.title }}
                        <div v-if="getPlaceEditing(place.id)" class="d-flex align-items-center small text-muted">
                            <BIconLockFill /> {{ getPlaceEditing(place.id).userName }} {{ $i18n('LABEL_IS_EDITING') }} 
                        </div>
                    </td>
                    <td>
                        <img 
                            v-for="category in place.categories" 
                            :key="'place-category-' + category.id" 
                            :src="category.icon"
                            class="list-category-img"
                            :class="{'d-none': ! category.icon}"
                            :alt="category.name" />
                    </td>
                    <td>
                        {{ place.lan.toUpperCase() }}
                    </td>
                    <td>
                        {{  place.availableTranslations.map(lan => lan.toUpperCase()).join(', ') }}
                    </td>
                    <td class="text-end shrink">
                        <DeleteConfirmButton v-if="!getPlaceEditing(place.id)" deleteEventName ="deletePlace" :deleteEventPayload="place.id" />
                    </td>
                    <td class="text-end shrink">
                        <button v-if="!getPlaceEditing(place.id)" @click="openEditPlace(place.id)" class="btn btn-primary btn-sm">
                            <BIconPencilFill />
                            <span class="visually-hidden">{{ $i18n('LABEL_EDIT') }}</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import axios from 'axios';
import store from '../store/store.js';
import { BIconLockFill, BIconPencilFill } from 'bootstrap-icons-vue';
import Alerts from '../components/Alerts.vue';
import DeleteConfirmButton from '../components/DeleteConfirmButton.vue';
import OverlaySpinner from '../components/OverlaySpinner.vue';

export default {
    name: 'Places',
    components: {
        Alerts,
        BIconLockFill,
        BIconPencilFill, 
        DeleteConfirmButton,
        OverlaySpinner
    },
    data() {
        return {
            places: [],
            confirmPlaceDelete: null
        }
    },
    computed: {
        config() {
            return store.state.config;
        },
        activeWorkspaceId() {
            return store.state.user.activeWorkspaceId;
        },
        placeEditings() {
            if (store.state.editings) {
                return store.state.editings.filter(item => item.placeId != null);
            }
            return null;
        }
    },
    watch: {
        activeWorkspaceId() {
            this.getPlaces();
        }
    },
    created() {
        this.$emitter.on('getItems', () => {
            this.getPlaces();
        });

        this.$emitter.on('deletePlace', (id) => {
            this.deletePlace(id);
        });

        this.getPlaces();
    },
    mounted() {
        store.commit('clearErrors');
        store.commit('clearMessages');
    },
    unmounted() {
        // Mitt off method is not working (known bug in 2.1.0), remove manually
        this.$emitter.all.delete('getItems');
        this.$emitter.all.delete('deletePlace');
    },
    methods: {
        getPlaceEditing(placeId) {
            if (this.placeEditings) {
                let editing = this.placeEditings.find(item => item.placeId == placeId);
                if (editing) {
                    return editing;
                }
            }
            return null;
        },
        /**
         * Gets all user's places from Locations DB and sets them to this.places.
         */
        getPlaces() {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Loading places');
            
            axios.get('/api/places', {
                params: {
                    workspaceId: this.activeWorkspaceId
                }
            })
            .then((response) => {
                this.places = [];
                if (response.data.places.length) {
                    this.places = response.data.places;
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.log(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        },
        openAddNewPlace() {
            store.commit('setModal', {
                component: 'ItemEdit',
                componentProps: {
                    itemType: 'place'
                },
                size: 'modal-lg',
                fullHeight: true
            });
        },
        openEditPlace(placeId) {
            store.commit('setModal', {
                component: 'ItemEdit',
                componentProps: {
                    itemType: 'place',
                    initPlace: this.places.find((place) => place.id == placeId)
                },
                size: 'modal-lg',
                fullHeight: true
            });
        },
        /**
         * Deletes a place from the DB permanently.
         */
        deletePlace(placeId) {
            store.commit('setLoading', true);
            store.commit('setLoadingStatus', 'Deleting place');

            axios({
                method: 'delete',
                url: '/api/place/' + placeId,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                if (response.data.status == 'SUCCESS') {
                    if (response.data.messages) {
                        this.$addToastMessage(response.data.messages);
                    }
                    this.getPlaces();
                } else if (response.data.errors) {
                    store.commit('setModalErrors', response.data.errors);
                }
            })
            .catch((error) => {
                store.commit('addModalError', this.$i18n('TEXT_ERROR_GENERAL'));
                console.error(error);
            })
            .then(() => {
                store.commit('setLoading', false);
            });
        }
    }
}
</script>

<style scoped>

</style>
<template>

    <div class="row">

        <div class="col-lg-6">

            <!-- Color picker for routes -->
            <div v-if="item.hasOwnProperty('waypoints')" class="mb-3">
                <div class="d-flex">
                    {{ $i18n('LABEL_DEFAULT_COLOR') }}
                    <div class="form-switch form-check ms-2">
                        <input @change="setRouteColor($event)" type="checkbox" class="form-check-input" id="customColor" :checked="item.color" />
                    </div>
                    <label for="customColor" class="form-check-label">{{ $i18n('LABEL_CUSTOM_COLOR') }}</label>
                </div>

                <label for="color" class="form-label visually-hidden">{{ $i18n('LABEL_COLOR') }}</label>
                <input v-if="item.color" type="color" id="color" name="color" class="form-control" v-model="item.color" />
            </div>

            <!-- Slug -->
            <div v-if="item.slug" class="mb-3">
                <label for="slug" class="form-label">{{ $i18n('LABEL_SLUG') }}</label>
                <input type="text" id="slug" name="slug" class="form-control" v-model="item.slug" placeholder="awesome-article" disabled />
                <div class="form-text">
                    {{ $i18n('TEXT_SLUG') }}
                </div>
            </div>

            <!-- Language -->
            <div class="mb-3">
                <label for="lan" class="form-label">{{ $i18n('LABEL_LANGUAGE') }}</label>
                <select v-model="item.lan" id="lan" name="lan" class="form-select" required>
                    <option 
                        v-for="(lanItem, lanCode) in languages"
                        :key="lanCode"
                        :selected="lanCode == item.lan"
                        :value="lanCode">
                            {{ lanItem.name }} ({{ lanItem.nativeName }})
                    </option>
                </select>
                <div class="invalid-feedback">
                    {{ $i18n('LABEL_FIELD_REQUIRED') }}
                </div>
            </div>
        
        </div>

        <div class="col-lg-6">

            <div class="mb-3">
                <div class="form-check form-switch">
                    <input v-model="item.status" type="checkbox" id="status" name="status" class="form-check-input" :value="1" />
                    <label for="status" class="form-check-label">{{ $i18n('LABEL_PUBLISHED') }}</label>
                </div>
            </div>

            <div class="mb-3">
                <div class="form-check form-switch">
                    <input v-model="item.public" type="checkbox" id="public" name="public" class="form-check-input" :value="1" />
                    <label for="public" class="form-check-label">{{ $i18n('LABEL_ALLOW_PUBLIC_USE') }}</label>
                </div>
            </div>

        </div>
    
    </div> <!-- /.row -->

</template>

<script>
import languages from '../assets/data/languages.json';

export default {
    name: 'ItemEditBasicInfo',
    components: {
        
    },
    props: {
        modelValue: {
            type: Object
        }
    },
    data() {
        return {
            item: this.modelValue,
            languages: languages
        }
    },
    methods: {
        setRouteColor(e) {
            if (e.target.checked) {
                this.item.color = '#0099ff';
            } else {
                this.item.color = null;
            }
        }
    }
}
</script>

<style scoped>

</style>
